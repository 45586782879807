@charset "UTF-8";
/*==========================
* Responsive Design
===========================*/
/*===================
* Global Dimensions
====================*/
/*===================
* Menus
====================*/
/*===================
* Colors
====================*/
@import '~ngx-toastr/toastr.css';
.button-radius, .button-hollow-small, .button.form, .button, .ng-select.ng-select-single .ng-select-container, input, .adyen-input,
textarea, section.popup-ui div.close-icon, .add-btn div, #container.overviews section#content .overview-text-container .overview-button, #container.cico footer.overviews section#content .overview-text-container .overview-button, section#content .top-btn-container .button, .alert {
  border-radius: 2px;
}

@keyframes fadeIn {
  from {
    transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transition-property: all;
  }
  to {
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/** flex mixins **/
/** gradient mixins **/
/** helper **/
.disable-styling-input, .button.form, .ng-select.ng-select-single .ng-select-container, input, .adyen-input,
textarea, div.searchbox input[type=search], div.searchbox [type=search].adyen-input, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.no-events, .select-frame i, .disabled_state, nav.element-navigation .navigation-container .nav-btn a.disabled, #container.overviews section#content .overview-text-container .overview-button.disabled, #container.cico footer.overviews section#content .overview-text-container .overview-button.disabled, section#navigation app-menu nav ul li a.disabled {
  pointer-events: none;
}

.no-shadow, .button.form, .ng-select.ng-select-single .ng-select-container:hover, section#content .top-btn-container .button {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.shadow, .form-box, #content .container-left .map-preview .box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.06);
}

.ellipsis, #news-widget div.news-container .swiper-slide .text, div.menu a > div, div.menu a small, div.tiles a.tile, ul.icon-list, section#content .top-btn-container .button, section#sidebar .side-button, app-widget-group-business span:first-of-type span, app-widget-advert span:first-of-type span, app-widget-group-business span, app-widget-advert span, app-goldkey-parent .magazine .name, section#navigation app-widget-push a, section#navigation app-menu nav ul li a, section#header .icon {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clearfix:after, div.button-grid:after, .uploader .bottom:after, #content .container-left .guest-list:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  font-size: 0;
  height: 0;
}

.scrollbar, .modal-box {
  scrollbar-width: thin;
}
.scrollbar::-webkit-scrollbar-track, .modal-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f2f2f2;
}
.scrollbar::-webkit-scrollbar-thumb, .modal-box::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
}
.scrollbar::-webkit-scrollbar, .modal-box::-webkit-scrollbar {
  display: block;
  width: 16px;
  margin: 0 48px;
}

.animation-in, .page-info, .iframe_container {
  -webkit-animation: fade 0.3s ease-in;
  -moz-animation: fade 0.3s ease-in;
  -o-animation: fade 0.3s ease-in;
  animation: fade 0.3s ease-in;
}

.animation-out {
  -webkit-animation: fade 0.3s ease-out;
  -moz-animation: fade 0.3s ease-out;
  -o-animation: fade 0.3s ease-out;
  animation: fade 0.3s ease-out;
}

.font-navigation, section#navigation {
  font-size: 15px;
}

.font-overview-big, #container.overviews section#content .overview-text-container em, #container.cico footer.overviews section#content .overview-text-container em {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -1px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .font-overview-big, #container.overviews section#content .overview-text-container em, #container.cico footer.overviews section#content .overview-text-container em {
    font-size: 22px;
  }
}

.font-button, .button, nav.element-navigation .navigation-container .nav-btn a, #container.overviews section#content .overview-text-container .overview-button, #container.cico footer.overviews section#content .overview-text-container .overview-button, section#content .top-btn-container .button {
  font-size: 17px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  color: white;
}
@media only screen and (max-width: 600px) {
  .font-button, .button, nav.element-navigation .navigation-container .nav-btn a, #container.overviews section#content .overview-text-container .overview-button, #container.cico footer.overviews section#content .overview-text-container .overview-button, section#content .top-btn-container .button {
    font-size: 14px !important;
  }
}

.font-headerfooter, section#footer .icon-button, section#header .icon {
  font-size: 13px;
  font-weight: 600;
}

.font-widget-text, .icon-menu div.menu a div:first-of-type, section#sidebar .title, #weatherwidget div.weatherblock p, body#bright section#sidebar app-widget-suggestions div.menu a div:first-of-type {
  font-size: 14px;
}

.font-widgetbutton, .button-hollow-small {
  font-size: 13px !important;
  font-weight: 700;
}

.font-widget-temperature, #weatherwidget div.weatherblock h2 {
  font-size: 27px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .font-widget-temperature, #weatherwidget div.weatherblock h2 {
    font-size: 25px;
  }
}

.font-widget-temperature-current {
  font-size: 40px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .font-widget-temperature-current {
    font-size: 25px;
  }
}

.font-subtext {
  font-size: 11px;
}

.font-news-container, #news-widget div.news-container .swiper-slide {
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .font-news-container, #news-widget div.news-container .swiper-slide {
    font-size: 14px;
  }
}

.font-page-headline, section#content .page-headline h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.66em;
}
@media only screen and (max-width: 600px) {
  .font-page-headline, section#content .page-headline h1 {
    font-size: 26px;
  }
}

.font-sub-headline, .form-box label:not(.redesign), #content .container-left .map-preview .box label:not(.redesign), div.menu .head, section#content #map .gm-style-iw h1, section#content h4, section#content .mylike-widget-container h3, .mylike-widget-container section#content h3 {
  font-size: 16px;
  font-weight: 700;
}

.font-article-name, section.popup-ui div.page-headline h2, section#content h3, section#content .page-headline h2 {
  font-size: 22px;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .font-article-name, section.popup-ui div.page-headline h2, section#content h3, section#content .page-headline h2 {
    font-size: 18px;
  }
}

.font-article-price {
  font-size: 32px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .font-article-price {
    font-size: 24px;
  }
}

.font-wizard-modal-button {
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .font-wizard-modal-button {
    font-size: 18px;
  }
}

.font-list, div.menu a > div {
  font-weight: normal;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .font-list, div.menu a > div {
    font-size: 14px;
  }
}

.font-text, section.popup-ui div.content a, section.popup-ui div.content p, section.popup-ui div.content .popup-text, #content .container-right .basket_mini .b-mini-total p, section#content a:not(.tile, .redesign), section#content p, section#content table, body,
.font-input,
.button.form,
.ng-select.ng-select-single .ng-select-container,
input,
.adyen-input,
textarea {
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .font-text, section.popup-ui div.content a, section.popup-ui div.content p, section.popup-ui div.content .popup-text, #content .container-right .basket_mini .b-mini-total p, section#content a:not(.tile, .redesign), section#content p, section#content table, body,
.font-input,
.button.form,
.ng-select.ng-select-single .ng-select-container,
input,
.adyen-input,
textarea {
    font-size: 14px;
  }
}
@media only screen and (min-width: 3000px) {
  .font-text, section.popup-ui div.content a, section.popup-ui div.content p, section.popup-ui div.content .popup-text, #content .container-right .basket_mini .b-mini-total p, section#content a:not(.tile, .redesign), section#content p, section#content table, body,
.font-input,
.button.form,
.ng-select.ng-select-single .ng-select-container,
input,
.adyen-input,
textarea {
    font-size: 17px;
  }
}

.font-error, .form-box label:not(.redesign).error, #content .container-left .map-preview .box label:not(.redesign).error {
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
}
@media only screen and (max-width: 600px) {
  .font-error, .form-box label:not(.redesign).error, #content .container-left .map-preview .box label:not(.redesign).error {
    font-size: 14px;
  }
}

.font-tiles, div.tiles a.tile {
  font-size: 17px;
  font-weight: 400 !important;
  text-decoration: none !important;
  color: white;
}

.font-text-big, section.popup-ui div.content em, #content .container-right .basket_mini .b-mini-total h5, #content em a, #content em {
  font-size: 19px;
}
@media only screen and (max-width: 600px) {
  .font-text-big, section.popup-ui div.content em, #content .container-right .basket_mini .b-mini-total h5, #content em a, #content em {
    font-size: 17px;
  }
}

.font-text-big-bold {
  font-size: 19px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .font-text-big-bold {
    font-size: 17px;
  }
}

.font-copyright, #content .container-right app-widget-advert, #mobile_partner, span.copyright {
  font-size: 11px;
}

/*** Redesign ***/
.normal-font-size, .instructions {
  font-size: 13px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .normal-font-size, .instructions {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1321px) {
  .normal-font-size, .instructions {
    font-size: 18px !important;
  }
}
@media only screen and (min-height: 1800px) {
  .normal-font-size, .instructions {
    font-size: 20px !important;
  }
}

.smaller-font-size {
  font-size: 12px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .smaller-font-size {
    font-size: 13px !important;
  }
}
@media only screen and (min-width: 1321px) {
  .smaller-font-size {
    font-size: 16px !important;
  }
}
@media only screen and (min-height: 1800px) {
  .smaller-font-size {
    font-size: 18px !important;
  }
}

.input-font-size {
  font-size: 14px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .input-font-size {
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 1321px) {
  .input-font-size {
    font-size: 18px !important;
  }
}
@media only screen and (min-height: 1800px) {
  .input-font-size {
    font-size: 20px !important;
  }
}

@keyframes fadeIn {
  from {
    transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transition-property: all;
  }
  to {
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/*===================
* Helper Classes
====================*/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hidden, div.menu a details {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.block {
  display: block;
}

.disabled_state, nav.element-navigation .navigation-container .nav-btn a.disabled, #container.overviews section#content .overview-text-container .overview-button.disabled, #container.cico footer.overviews section#content .overview-text-container .overview-button.disabled, section#navigation app-menu nav ul li a.disabled {
  opacity: 0.55;
  cursor: not-allowed !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

span.mark {
  display: inline-block;
  background: yellow;
}

span.copyright {
  margin: 1% 0 0 1%;
  text-align: right;
  display: block;
}

.hidden-field {
  visibility: hidden;
  position: absolute;
  right: 999999999px;
  opacity: 0;
  border: none;
}

.m-32 {
  margin: 32px;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-32 {
  margin-top: 32px;
}

.m-l-8 {
  margin-left: 8px;
}

.p-l-8 {
  padding-left: 8px;
}

.m-t-16- {
  margin-top: -16px !important;
}

/*===================
* Responsive Helper
====================*/
.hidden-mobile, #content .container-right app-widget-advert, section#navigation app-widget-address, section#navigation app-widget-group, section#navigation app-widget-logo, section#navigation app-menu nav li.menu-label {
  display: block;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .hidden-mobile, #content .container-right app-widget-advert, section#navigation app-widget-address, section#navigation app-widget-group, section#navigation app-widget-logo, section#navigation app-menu nav li.menu-label {
    display: none !important;
  }
}

.hidden-tablet, #content .container-right app-widget-advert {
  display: block;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  .hidden-tablet, #content .container-right app-widget-advert {
    display: none !important;
  }
}

.hidden-desktop, section#navigation app-widget-social, section#navigation app-widget-advert {
  display: block;
}
@media only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  .hidden-desktop, section#navigation app-widget-social, section#navigation app-widget-advert {
    display: none !important;
  }
}

a.ext-link::after {
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  margin-left: 6px;
  max-height: 20px;
  display: inline-block;
  content: "\f35d";
}
@media only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  a.ext-link::after {
    display: none;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  a.ext-link:hover::after {
    display: inline-block !important;
  }
}

/*===================
* Graphics and Icons
====================*/
::-webkit-scrollbar {
  display: none;
  width: 0;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

/*===================
* Global
====================*/
* {
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-weight: 400;
  text-align: left;
  color: #444;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
}
body.scroll_lock {
  overflow: hidden;
}

a {
  text-decoration: none;
  cursor: pointer;
}

select,
.close-icon,
.close-icon i,
[class*=button],
ul.icon-nav li,
div.menu a,
div.emoji.clickable,
.swiper-slide,
span.icon,
.stars i,
#activate,
.add-btn,
.magazine,
.option-input {
  cursor: pointer;
}

table.table-fullwidth {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
}

/*===================
* Layout
====================*/
.loader-area {
  display: block;
  position: relative;
  height: 100%;
  left: calc(50% - 60px);
}

#container, #container.cico footer {
  width: 100%;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container section#navigation {
    padding-bottom: 64px;
  }
  #container section#navigation:not(.open) {
    top: 100vh;
    display: none;
  }
  #container section#navigation.open {
    top: 64px;
    display: block;
    -webkit-animation: open_menu 500ms;
    -moz-animation: open_menu 500ms;
    -o-animation: open_menu 500ms;
    animation: open_menu 500ms;
  }
  #container.task section#footer, #container.cico footer.task section#footer {
    display: none !important;
  }
  #container.task section#content, #container.cico footer.task section#content {
    min-height: calc(100vh - 64px);
  }
  #container.task #activate.in, #container.cico footer.task #activate.in {
    display: none;
  }
  #container.task:not(.cico) section#content, #container.cico footer.task:not(.cico) section#content {
    padding: 64px 16px 16px 16px;
  }
  #container.task.frame section#content, #container.cico footer.task.frame section#content {
    padding: 64px 0 16px 0;
  }
}
#container.overviews, #container.cico footer.overviews {
  position: relative;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container.overviews section#navigation, #container.cico footer.overviews section#navigation {
    top: calc(300px + 64px);
    display: block;
    padding-bottom: 0;
    margin-bottom: 5px;
  }
}
#container.overviews section#c-background, #container.cico footer.overviews section#c-background {
  display: none;
}
#container.overviews section#footer, #container.cico footer.overviews section#footer {
  display: none !important;
}
#container.overviews section#content, #container.cico footer.overviews section#content {
  padding: 0;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container.overviews section#content, #container.cico footer.overviews section#content {
    height: 300px;
    min-height: 300px;
    padding: 64px 0 0 0;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container.overviews section#background div, #container.cico footer.overviews section#background div {
    top: 64px;
    height: 300px;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px) and (min-width: 600px ) and (max-width: 890px), only screen and (min-width: 600px ) and (max-width: 890px) and (max-width: 600px), only screen and (max-width: 600px) and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) and (max-width: 600px) {
  #container.overviews section#background div video, #container.cico footer.overviews section#background div video, #container.overviews section#background div img, #container.cico footer.overviews section#background div img {
    min-height: 300px;
    height: 300px;
    margin-left: 0;
  }
}
#container.frame:not(.cico) section#content, #container.cico footer.frame:not(.cico) section#content {
  padding: 0;
}
@media only screen and (max-width: 600px) {
  #container.frame:not(.cico) section#content, #container.cico footer.frame:not(.cico) section#content {
    padding: 64px 0 64px 0;
  }
}
#container.frame:not(.cico) section#logo.upright, #container.cico footer.frame:not(.cico) section#logo.upright {
  height: 52px;
}
#container.frame:not(.cico) section#logo.upright img, #container.cico footer.frame:not(.cico) section#logo.upright img {
  max-height: 52px;
}
#container.wizard section#content, #container.cico footer.wizard section#content {
  margin: 0;
  padding: 0;
}
#container.wizard section#header, #container.cico footer.wizard section#header {
  display: block;
}
@keyframes open_menu {
  from {
    top: 100vh;
  }
  to {
    top: 64px;
  }
}

section#header {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#header {
    display: block;
    position: fixed;
    z-index: 5000;
    top: 0;
    width: 100%;
    height: 64px;
    -moz-box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.55);
    box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.55);
  }
}

section#footer {
  display: none;
}

section#navigation {
  width: 260px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  min-height: 100vh;
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#navigation {
    min-height: auto;
    width: 100%;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

section#content {
  position: relative;
  margin: 0 260px;
  height: 100%;
  padding: 110px 5% 160px 5%;
  z-index: 3;
}
@media only screen and (min-width: 1321px) {
  section#content {
    padding: 0 5% 160px 5%;
  }
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  section#content {
    margin: 0 0 0 260px;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px) {
  section#content {
    margin: 0;
    padding: 64px 5%;
  }
}
@media only screen and (max-width: 600px) {
  section#content {
    margin: 0;
    padding: 64px 16px;
  }
}

section#sidebar {
  width: 260px;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 6;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (max-width: 600px), only screen and (min-width: 890px) and (max-width: 1060px), only screen and (min-width: 600px ) and (max-width: 890px) {
  section#sidebar.removed {
    width: 0;
  }
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  section#sidebar {
    z-index: 5003;
  }
}

section#search {
  z-index: 5005;
  position: fixed;
  top: 0;
  left: 50%;
  width: 500px;
  margin-left: -250px;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  section#search {
    margin-left: -120px;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#search {
    top: 64px;
    left: 0;
    width: 100vw;
    margin: 0;
  }
}

#background {
  z-index: -1;
}
#background div {
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: center center !important;
  transition: opacity 3s ease-in-out;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  #background div {
    width: calc(100% - 260px);
    left: 260px;
    text-align: center;
  }
}
#background div.active {
  opacity: 1;
}
#background div video, #background div img {
  min-height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  object-position: center center;
  width: 100vw;
  height: 100vh;
}
#background.landing div, #background.landing img, #background.landing video {
  top: 0 !important;
  left: 0 !important;
  -webkit-transform: none;
  transform: none;
  height: 100vh !important;
  width: 100vw !important;
}

/** Background **/
section#c-background {
  z-index: 2;
  background: white;
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
}

#curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  z-index: 5004;
  cursor: pointer;
}

#container section#header .icon:nth-child(1) {
  display: none;
}
#container section#header .icon:nth-child(2) {
  display: block;
}
#container.overviews section#content, #container.cico footer.overviews section#content {
  background: none;
}
#container.overviews section#header .icon:nth-child(1), #container.cico footer.overviews section#header .icon:nth-child(1) {
  display: block;
}
#container.overviews section#header .icon:nth-child(2), #container.cico footer.overviews section#header .icon:nth-child(2) {
  display: none;
}

/** Bright Theme **/
body#bright .bright_button, body#bright .side-button,
body#bright .social-button,
body#bright span.weathericon, body#bright section#sidebar .swiper-button-next::before,
body#bright section#sidebar .swiper-button-prev::before {
  background: #f2f2f2;
  color: #555;
}
body#bright .bright_button:hover, body#bright .side-button:hover,
body#bright .social-button:hover,
body#bright span.weathericon:hover, body#bright section#sidebar .swiper-button-next:hover::before,
body#bright section#sidebar .swiper-button-prev:hover::before {
  background: #dedede;
}
body#bright section#header {
  background: white;
}
body#bright section#header section#logo {
  background: white;
}
body#bright section#header a.icon, body#bright section#header span.icon {
  color: #444 !important;
}
body#bright section#header a.icon i, body#bright section#header span.icon i {
  color: #444 !important;
}
body#bright section#footer {
  background: white;
  border-top: none;
}
body#bright section#footer .icon-button {
  border-right: 1px solid rgba(124, 124, 124, 0.21);
}
body#bright section#footer .icon-button {
  color: #555 !important;
}
body#bright section#footer .icon-button i {
  color: #555 !important;
}
body#bright section#navigation {
  background: white;
}
body#bright section#navigation > * {
  border-color: #f9f9f9;
}
body#bright section#navigation.no_hover nav ul li:hover {
  border-color: #f9f9f9 !important;
}
body#bright section#navigation.no_hover nav ul li:hover a {
  background: white !important;
  color: #888 !important;
}
body#bright section#navigation nav ul li {
  border-color: #f9f9f9;
}
body#bright section#navigation nav ul li.loading:hover {
  border-color: #f9f9f9 !important;
}
body#bright section#navigation a {
  color: #888;
  cursor: pointer;
}
body#bright div.weatherblock {
  border-color: #f9f9f9;
}
body#bright .menu-label {
  color: #555;
}
body#bright .menu-label:hover {
  border-color: #f9f9f9 !important;
}
body#bright .menu-seperator {
  border-color: #d2d2d2 !important;
}
body#bright #activate {
  border-color: #d2d2d2;
  border-right-color: white;
  background: white;
}
body#bright section#sidebar {
  background: white;
  text-align: center;
  border-left: solid 1px #d2d2d2;
}
body#bright section#sidebar .scroll > * {
  border-color: #f9f9f9;
}
body#bright section#sidebar h2, body#bright section#sidebar h4, body#bright section#sidebar .mylike-widget-container h3, .mylike-widget-container body#bright section#sidebar h3, body#bright section#sidebar a, body#bright section#sidebar p, body#bright section#sidebar .title {
  color: #555;
}
body#bright section#sidebar .button {
  background: #3f3f3f;
}
body#bright section#sidebar ul.icon-nav li {
  border-color: #f9f9f9;
}
body#bright section#sidebar app-widget-advert {
  background: white;
}
body#bright app-widget-group-business span:not(.single), body#bright app-widget-advert span:not(.single) {
  background: #f2f2f2;
  color: #555;
}
body#bright app-widget-qr-code div {
  background: white;
}
body#bright app-landingpage {
  color: black;
}
body#bright app-landingpage .fog {
  background-color: rgba(255, 255, 255, 0.5);
}

/** Dark Theme **/
body#dark .dark_button, body#dark .side-button,
body#dark .social-button,
body#dark span.weathericon {
  background: rgba(255, 255, 255, 0.07);
  color: white;
}
body#dark .dark_button:hover, body#dark .side-button:hover,
body#dark .social-button:hover,
body#dark span.weathericon:hover {
  background: rgba(255, 255, 255, 0.14);
}
body#dark .swiper_dark_button, body#dark section#sidebar .swiper-button-next::before,
body#dark section#sidebar .swiper-button-prev::before {
  background: transparent;
  color: white !important;
}
body#dark .swiper_dark_button:hover, body#dark section#sidebar .swiper-button-next:hover::before,
body#dark section#sidebar .swiper-button-prev:hover::before {
  background: rgba(255, 255, 255, 0.14);
}
body#dark section#header {
  background: #181818;
}
body#dark section#header section#logo {
  background: white;
}
body#dark section#header a.icon, body#dark section#header span.icon {
  color: #e5e5e5 !important;
}
body#dark section#header a.icon i, body#dark section#header span.icon i {
  color: #e5e5e5 !important;
}
body#dark section#footer {
  background: #181818;
  border-top: none;
}
body#dark section#footer .icon-button {
  border-right: 1px solid rgba(124, 124, 124, 0.21);
}
body#dark section#footer .icon-button {
  color: #e5e5e5 !important;
}
body#dark section#footer .icon-button i {
  color: #e5e5e5 !important;
}
body#dark section#navigation {
  background: #181818;
}
body#dark section#navigation > * {
  border-color: #333;
}
body#dark section#navigation.no_hover nav ul li:hover {
  border-color: #333 !important;
}
body#dark section#navigation.no_hover nav ul li:hover a {
  background: #181818 !important;
  color: #e5e5e5 !important;
}
body#dark section#navigation nav ul li {
  border-color: #333;
}
body#dark section#navigation nav ul li.loading:hover {
  border-color: #333 !important;
}
body#dark section#navigation a, body#dark section#navigation p {
  color: #e5e5e5;
}
@media only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  body#dark:not(.no-sidebar) section#c-background {
    right: 260px;
  }
}
body#dark div.weatherblock {
  border-color: #333;
}
body#dark .menu-label {
  color: #CCC;
}
body#dark .menu-label:hover {
  border-color: #333 !important;
}
body#dark .menu-seperator {
  border-color: #777 !important;
}
body#dark #activate {
  color: #e5e5e5;
  border-color: #333;
  border-right-color: #181818;
  background: #181818;
}
body#dark section#sidebar {
  background: rgba(0, 0, 0, 0.82);
  text-align: center;
  border-left: solid 1px #333;
}
body#dark section#sidebar .scroll > * {
  border-color: #333;
}
body#dark section#sidebar h1, body#dark section#sidebar h2, body#dark section#sidebar h4, body#dark section#sidebar .mylike-widget-container h3, .mylike-widget-container body#dark section#sidebar h3, body#dark section#sidebar a, body#dark section#sidebar p, body#dark section#sidebar .title {
  color: #e5e5e5;
}
body#dark section#sidebar .button {
  background: #3f3f3f;
}
body#dark section#sidebar app-widget-advert {
  color: #e5e5e5;
  background: #181818;
}
body#dark section#sidebar app-widget-suggestions ul.icon-nav li {
  border-color: #333;
  background: rgba(255, 255, 255, 0.07);
  color: white;
}
body#dark section#sidebar app-widget-suggestions ul.icon-nav li:hover {
  background: rgba(255, 255, 255, 0.14);
}
body#dark section#sidebar app-widget-suggestions .menu a {
  border-color: #333;
}
body#dark section#sidebar app-widget-suggestions .menu a:hover {
  background: rgba(255, 255, 255, 0.14);
}
body#dark app-widget-group-business span:not(.single), body#dark app-widget-advert span:not(.single) {
  background: rgba(255, 255, 255, 0.07);
  color: white;
}
body#dark section#content app-widget-advert span:not(.single) {
  background: #f2f2f2;
  color: #555;
}
body#dark app-widget-qr-code div {
  background: rgba(255, 255, 255, 0.07);
}
body#dark app-landingpage {
  color: white;
}
body#dark app-landingpage .fog {
  background-color: rgba(0, 0, 0, 0.5);
}

/*===================
* FX
====================*/
nav ul li:hover a,
nav ul li:hover,
#search-results ul li:hover,
#curtain,
.button,
.widget-button,
.icon-button,
.tile,
.main-bg,
.button-hollow-small,
ul.icon-nav li,
div.menu a:hover,
span.weathericon,
.magazine,
.swiper-button-next,
.swiper-button-prev,
.swiper-button-next::before,
.swiper-button-prev::before,
.overview-button,
.social-button,
.inactivity-detection,
.side-button {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.grayscale {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
}

/* Alert */
.alert {
  color: #ff0000;
  background-color: #fff0f0;
  position: relative;
  padding: 18px 3%;
  margin: 0 0 36px;
  border: 1px solid #f5c6cb;
}
.alert.alert-success {
  color: #4eaf57;
  background-color: #d7e8d4;
  border-color: #90b68b;
}
.alert.alert-info {
  color: #5086a1;
  background-color: #d9edf7;
  border-color: #7daec6;
}
.alert.alert-info a {
  color: #5086a1 !important;
}
.alert ul {
  margin: 0;
}

.text-success {
  color: #4AB58E;
}

.text-error {
  color: #F1416C;
}

.text-grey {
  color: #888;
}

/*===================
* Header
====================*/
section#header a img.flag-icon {
  margin: 12px auto 3px auto;
  height: 16px;
  border: 0.8px solid #d2d2d2;
  display: block;
}
section#header .icon {
  position: absolute;
  height: 100%;
  width: 120px;
  top: 0;
  margin: 0;
  padding: 0 3px;
  text-align: center;
  display: block;
  text-decoration: none;
  border-right: 1px solid rgba(124, 124, 124, 0.21);
}
@media only screen and (max-width: 600px) {
  section#header .icon {
    width: 96px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 370px) {
  section#header .icon {
    width: 76px;
    font-size: 13px;
  }
}
section#header .icon i {
  font-size: 16px;
  padding: 13px 0 4px;
  display: block;
  color: #eee;
  text-decoration: none;
}
section#header .icon:last-child {
  right: 0;
  border-right: none;
  border-left: 1px solid rgba(124, 124, 124, 0.21);
}
section#header section#logo {
  margin: auto;
  height: 52px;
  display: inline;
  padding: 8px 12px;
  top: 10%;
  position: absolute;
  left: 0;
  right: 0;
  -moz-box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.55);
  box-shadow: 0 3px 7px -2px rgba(0, 0, 0, 0.55);
  width: 200px;
  z-index: 1;
}
section#header section#logo img {
  outline: none;
  border: none;
}
@media only screen and (max-width: 600px) {
  section#header section#logo {
    width: 140px;
    padding: 7px 8px;
  }
  section#header section#logo a {
    width: 140px !important;
    margin: auto;
  }
}
@media only screen and (max-width: 370px) {
  section#header section#logo {
    width: 128px !important;
    padding: 7px 8px;
  }
  section#header section#logo a {
    width: 128px;
    margin: auto;
  }
}
section#header section#logo a {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}
section#header section#logo a img {
  max-height: 52px;
  max-width: 75%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  section#header section#logo a img {
    max-width: 90%;
  }
}

/*===================
* Navigation
====================*/
section#navigation > * {
  display: block;
  border-top: 1px solid;
  padding: 15px 0;
}
section#navigation > *:first-child, section#navigation > *:empty {
  border-top: none;
}
section#navigation app-menu {
  padding: 0;
}
section#navigation app-menu nav ul {
  padding: 0;
  margin: 0;
}
section#navigation app-menu nav li.menu-label {
  font-size: 14px;
  font-weight: 700;
}
section#navigation app-menu nav ul li {
  border-bottom: 1px solid;
  list-style: none;
}
section#navigation app-menu nav ul li.active .triangle {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
  border-width: 23px 21px 23px 21px;
  height: 0;
  width: 0;
  z-index: 111;
  position: absolute;
  right: -42px;
  margin-top: -12px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#navigation app-menu nav ul li.active .triangle {
    display: none;
  }
}
section#navigation app-menu nav ul li a {
  display: block;
  padding: 12px 3px 12px 18px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#navigation app-menu nav ul li a {
    padding: 18px 14px 18px 38px;
  }
}
section#navigation app-menu nav ul li a i {
  margin-right: 18px;
  width: 30px;
  font-size: 16px;
  text-align: center;
}
section#navigation app-menu nav ul li a img.flag-icon {
  border: 0.8px solid #d2d2d2;
  vertical-align: middle;
  height: 18px;
  margin-right: 20px;
  margin-left: 2px;
}
section#navigation app-menu nav ul li p {
  padding-left: 18px;
}
section#navigation app-menu nav ul li:last-child {
  border-bottom: none;
}
section#navigation app-menu nav ul li.loading:hover {
  background: none !important;
}
section#navigation app-menu nav ul li.loading a {
  background: none !important;
  color: transparent !important;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
section#navigation app-menu nav ul li.loading a:hover {
  background: none !important;
}
section#navigation app-widget-a2hs {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#navigation app-widget-a2hs {
    display: block;
  }
}
section#navigation app-widget-logo {
  position: relative;
  text-align: center;
  height: 136px;
  padding: 0;
}
section#navigation app-widget-logo a {
  display: block;
  width: 100%;
  height: 100%;
}
section#navigation app-widget-logo a img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 100px;
  max-width: 210px;
}
section#navigation app-widget-weather {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#navigation app-widget-weather {
    display: block;
  }
}
section#navigation app-widget-weather span.weathericon {
  margin-left: 25px;
  margin-right: -25px;
  float: left;
}
section#navigation app-widget-weather h2 {
  display: block;
  margin-top: 0;
}
section#navigation app-widget-weather p {
  display: inline-block;
  margin: 0;
}
section#navigation app-widget-group {
  position: relative;
  text-align: center;
}
section#navigation app-widget-group img {
  margin: auto;
  max-height: 100px;
  max-width: 230px;
}
section#navigation app-widget-address {
  padding: 12px 3px 12px 18px;
}
section#navigation app-widget-address a {
  font-weight: bold;
  cursor: pointer;
}
section#navigation app-widget-address p {
  margin: 0;
}
section#navigation app-widget-push {
  text-align: center;
}
section#navigation app-widget-push a {
  display: block;
}
section#navigation app-widget-social {
  text-align: center;
}
section#navigation app-widget-group-image, section#navigation app-widget-group-business {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#navigation app-widget-group-image, section#navigation app-widget-group-business {
    display: block;
  }
}
section#navigation app-widget-legal, section#navigation app-widget-push {
  text-align: center;
}
section#navigation app-widget-legal .pale, section#navigation app-widget-push .pale {
  opacity: 0.62;
  word-wrap: break-word;
}
section#navigation app-widget-legal a, section#navigation app-widget-push a {
  text-decoration: underline;
  margin: 0 8px;
  display: block;
}
section#navigation app-widget-legal span, section#navigation app-widget-push span {
  display: block;
  margin-top: 16px;
  opacity: 0.4;
}
section#navigation app-widget-legal span::before, section#navigation app-widget-push span::before {
  content: "v";
}

/*===================
* Sidebar & Navigation
====================*/
#weatherwidget div.weatherblock {
  padding: 16px 0 12px;
  width: 50%;
  border-right: 1px solid;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
}
@media only screen and (max-width: 370px) {
  #weatherwidget div.weatherblock {
    text-align: right;
  }
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #weatherwidget div.weatherblock {
    text-align: center;
  }
}
#weatherwidget div.weatherblock:last-child {
  width: calc(50% - 1px);
  border-right-width: 0;
}
#weatherwidget div.weatherblock p {
  margin-top: 0;
}
#weatherwidget div.weatherblock h2 {
  margin-top: 5px;
  margin-bottom: 0;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #weatherwidget div.weatherblock h2 {
    display: inline-block;
    margin-top: 0;
  }
}
#weatherwidget div.weatherblock span.weathericon {
  display: block;
  width: 46px;
  height: 46px;
  padding: 3px 5px;
  margin: auto;
  border-radius: 5px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #weatherwidget div.weatherblock span.weathericon {
    display: inline-block;
  }
}
#weatherwidget div.weatherblock img {
  display: block;
  width: 46px;
  height: 46px;
}

@media all and (display-mode: standalone) {
  app-widget-a2hs {
    display: none !important;
  }
}
app-widget-a2hs button.main-bg {
  margin: auto;
}

app-widget-group-image a {
  cursor: pointer;
}
app-widget-group-image a img {
  display: block;
  margin: 0 auto !important;
  max-width: 230px;
}

app-goldkey-parent .magazine {
  margin: 8px 12px;
  padding: 8px;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  background: #f2f2f2;
}
app-goldkey-parent .magazine:hover {
  background: #dedede;
}
app-goldkey-parent .magazine .name {
  padding-bottom: 6px;
}
app-goldkey-parent .magazine img {
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  max-height: 280px;
}
@media only screen and (min-width: 3000px) {
  app-goldkey-parent .magazine {
    width: calc(25% - 34px);
  }
  app-goldkey-parent .magazine:nth-of-type(4n+1) {
    margin-right: 0;
  }
  app-goldkey-parent .magazine:nth-of-type(4n-2) {
    margin-left: 0;
  }
  app-goldkey-parent .magazine img {
    max-height: 250px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-width: 890px) and (max-width: 1320px) {
  app-goldkey-parent .magazine {
    width: calc(33.3333333333% - 34px);
  }
  app-goldkey-parent .magazine:nth-of-type(3n+2) {
    margin-right: 0;
  }
  app-goldkey-parent .magazine:nth-of-type(3n-2) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  app-goldkey-parent .magazine img {
    max-height: 250px;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  app-goldkey-parent .magazine {
    width: calc(50% - 34px);
  }
  app-goldkey-parent .magazine:nth-of-type(2n+3) {
    margin-right: 0;
  }
  app-goldkey-parent .magazine:nth-of-type(2n-2) {
    margin-left: 0;
  }
}
app-goldkey-parent .logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

app-widget-group-business .slider, app-widget-advert .slider {
  width: 230px;
  margin: auto;
  text-align: center;
}
app-widget-group-business span, app-widget-advert span {
  padding-top: 4px;
  width: 230px;
  height: 27px;
  display: block;
  margin: 0 auto !important;
}
app-widget-group-business span:first-of-type, app-widget-advert span:first-of-type {
  margin: 0 auto;
}
app-widget-group-business span:first-of-type span, app-widget-advert span:first-of-type span {
  padding: 0;
  width: 168px;
}
app-widget-group-business .name, app-widget-advert .name {
  min-height: 1.5em;
  max-height: 2.8em;
  overflow: hidden;
  white-space: pre-wrap;
  padding-bottom: 1px;
  font-size: 15px;
}
app-widget-group-business .swiper-button-next, app-widget-group-business .swiper-button-prev, app-widget-advert .swiper-button-next, app-widget-advert .swiper-button-prev {
  height: 30px !important;
  font-size: 0.8em !important;
  z-index: 1;
  top: 0;
}
app-widget-group-business .swiper-button-next::before, app-widget-group-business .swiper-button-prev::before, app-widget-advert .swiper-button-next::before, app-widget-advert .swiper-button-prev::before {
  color: #555 !important;
  font-size: 2em !important;
  margin-top: -6px !important;
}
app-widget-group-business .inner, app-widget-advert .inner {
  margin: 0 auto !important;
  display: block;
  width: 230px;
  height: 180px;
  background-size: cover !important;
  background-position: center center !important;
  cursor: pointer;
}

app-widget-advert .inner {
  height: auto;
  padding-top: 5px;
}
app-widget-advert .swiper-slide {
  height: auto !important;
  max-height: 230px !important;
}
app-widget-advert .swiper-slide img {
  max-height: 175px;
}

app-wizard-header section {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}
app-wizard-header h4, app-wizard-header .mylike-widget-container h3, .mylike-widget-container app-wizard-header h3 {
  color: #888;
  float: right;
  margin-right: 32px;
  padding-top: 3px;
}
app-wizard-header ng-select {
  float: right;
  margin: 16px 16px 0 0;
}
app-wizard-header ng-select img {
  width: 25px;
  margin-top: 8px;
  border: 1px solid #d2d2d2;
}
app-wizard-header ng-select .ng-select-container {
  height: 46px !important;
}
app-wizard-header ng-select .ng-select-container .ng-input {
  display: none;
}
app-wizard-header ng-select .ng-dropdown-panel {
  width: 150px !important;
  right: 0;
  left: auto !important;
}
app-wizard-header ng-select .ng-dropdown-panel img {
  vertical-align: middle;
  margin-right: 8px;
  margin-top: 0;
}
app-wizard-header ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: none;
}

/** Mobile Group **/
#mobile_group_logo {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px), only screen and (min-width: 3000px) {
  #mobile_group_logo {
    margin-top: 20px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
  }
  #mobile_group_logo img {
    display: block;
    width: auto;
    max-width: 80%;
    margin: 0 auto !important;
  }
}

/** Mobile Partner **/
#mobile_partner {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #mobile_partner {
    margin-top: 20px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
  }
  #mobile_partner img {
    display: block;
    width: auto;
    max-width: 80%;
    margin: 0 auto !important;
  }
}

/*===================
* Sidebar
====================*/
#activate {
  display: none;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (max-width: 600px), only screen and (min-width: 890px) and (max-width: 1060px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #activate {
    display: block;
    top: 85px;
    right: 260px;
    position: fixed;
    border: 1px solid;
    border-radius: 20px 0 0 20px;
    z-index: 5001;
    width: 45px;
    height: 52px;
    text-align: center;
  }
  #activate i {
    line-height: 53px;
    margin-left: 3px;
    font-size: 27px;
  }
  #activate i.fa-caret-right {
    margin-left: 10px;
  }
  #activate i.in {
    display: none;
  }
  #activate i.out {
    display: block;
  }
  #activate.in {
    right: 0;
  }
  #activate.in i.in {
    display: block;
  }
  #activate.in i.out {
    display: none;
  }
}

section#sidebar .scroll {
  overflow: auto;
  max-height: 100vh;
}
section#sidebar .scroll > * {
  display: block;
  border-top: 1px solid;
  padding: 15px 2px;
}
section#sidebar .scroll > *:first-child, section#sidebar .scroll > *:empty {
  border-top: none;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#sidebar .scroll app-widget-a2hs.safari > * {
    padding: 0;
  }
}
section#sidebar i {
  display: block;
}
section#sidebar .title {
  background: none;
  padding: 8px 0;
}
section#sidebar app-widget-qr-code div {
  padding: 7px 0;
  margin: 0 14px;
  border-radius: 5px;
}
section#sidebar app-widget-qr-code qrcode img {
  padding: 3px;
  margin: 5px auto;
  background: white;
}
section#sidebar app-widget-advert {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 2;
}
section#sidebar app-widget-suggestions {
  padding: 15px 0 0 0 !important;
}
section#sidebar #group_logo {
  padding: 15px 2px;
  position: relative;
  text-align: center;
}
section#sidebar #group_logo img {
  margin: auto;
  max-height: 100px;
  max-width: 210px;
}
section#sidebar app-widget-weather .weatherblock h2 {
  display: block;
}
section#sidebar .side-button {
  display: block;
  padding: 15px 23px;
  margin: 0 14px;
  border-radius: 5px;
}
section#sidebar .side-button i {
  font-size: 26px;
  margin-bottom: 3px;
}

/*===================
* Footer
====================*/
section#footer {
  display: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#footer {
    display: block;
    -moz-box-shadow: 0 -2px 3px -2px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: 0 -2px 3px -2px rgba(0, 0, 0, 0.55);
    box-shadow: 0 -2px 3px -2px rgba(0, 0, 0, 0.55);
    position: fixed !important;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    height: 64px;
    border-top: 1px solid #242424;
  }
}
@media only screen and (max-height: 300px) {
  section#footer {
    display: none;
  }
}
section#footer .footer-nav {
  height: 100%;
  z-index: 5000;
}
section#footer .icon-button {
  position: relative;
  height: 100%;
  width: 33.33%;
  float: left;
  margin: 0;
  padding: 0 3px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
section#footer .icon-button:last-child {
  border-right: none;
}
section#footer .icon-button i {
  display: block;
  padding: 11px 0 8px;
  font-size: 16px;
}
section#footer .icon-button .open-menu,
section#footer .icon-button .close-menu {
  width: 100%;
}
section#footer #pullup_menu_btn .open-menu {
  display: block;
}
section#footer #pullup_menu_btn .close-menu {
  display: none;
}

/*===================
* Content
====================*/
section#content {
  padding-top: 85px;
  /* List */
  /** Seperator **/
  /** Top Button **/
  /** Half content **/
}
section#content .page-headline {
  color: #888;
  position: relative;
  line-height: 1.4em;
  margin-bottom: 32px;
  padding: 0px 10px 10px 1px;
  border-bottom: 1px solid #d2d2d2;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section#content .page-headline {
    padding: 10px 10px 2px 2px;
    margin-bottom: 16px;
  }
}
section#content .page-headline.inner {
  padding-top: 10px;
}
section#content .page-headline h1 {
  margin-bottom: 6px;
}
section#content .page-headline h1.blurry {
  color: transparent;
  text-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
}
section#content .page-headline h2 {
  color: #444;
  line-height: 1.4em;
}
section#content .page-headline h2 + small {
  display: block;
  margin-top: -12px;
  margin-bottom: 0.83em;
}
section#content h4, section#content .mylike-widget-container h3, .mylike-widget-container section#content h3 {
  color: #8a8a8a;
  margin: 50px 0 16px;
}
section#content h4 span.edit, section#content .mylike-widget-container h3 span.edit, .mylike-widget-container section#content h3 span.edit {
  cursor: pointer;
}
section#content h4 span.edit i, section#content .mylike-widget-container h3 span.edit i, .mylike-widget-container section#content h3 span.edit i {
  font-size: 20px;
  margin-left: 8px;
}
section#content p, section#content table {
  margin-bottom: 10px;
}
section#content p.error, section#content table.error {
  color: #F1416C;
}
section#content a:not(.tile, .redesign) {
  color: #555;
  font-weight: 600 !important;
  text-decoration: none;
  cursor: pointer;
}
section#content app-info-screen .message.alternative-reservations a {
  color: #288191;
  text-decoration: underline !important;
  font-weight: 300 !important;
}
section#content app-info-screen .message.alternative-reservations a:hover {
  text-decoration: none !important;
}
section#content p i {
  margin-right: 6px;
}
section#content img:not(.imgSlide) {
  max-width: 100%;
  height: auto;
}
section#content #map {
  width: 98%;
  margin: 30px auto;
  height: 300px;
}
@media only screen and (max-width: 370px), only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  section#content #map {
    width: 100%;
    margin: 20px 0;
  }
}
section#content #map.overview {
  height: calc(100vh - 230px);
  margin: 0 auto;
}
@media only screen and (max-width: 370px), only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  section#content #map.overview {
    height: calc(100vh - 185px - 64px);
  }
}
section#content #map .gm-style-iw img {
  max-width: 200px;
}
section#content ul:not(.dropdown-menu) {
  list-style-type: square;
  padding-left: 20px;
}
section#content ol {
  list-style-type: decimal;
}
section#content li {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
}
section#content li::before {
  color: red;
  /* or whatever color you prefer */
}
section#content ul ul, section#content ol ul {
  list-style-type: square;
  margin-left: 15px;
}
section#content ol ol, section#content ul ol {
  list-style-type: decimal;
  margin-left: 15px;
}
section#content .seperator {
  height: 1px;
  width: 100%;
  background: #f2f2f2;
  margin-top: 16px;
  margin-bottom: 30px;
}
section#content .top-btn-container {
  text-align: center;
}
section#content .top-btn-container .button {
  padding: 12px 50px 12px 50px;
  margin: 15px 10px;
  display: inline-block;
  max-width: 190px;
}
section#content .top-btn-container .button i {
  margin-right: 17px;
  font-size: 16px;
}
section#content .icon-nav li {
  border-color: #f2f2f2;
}
section#content .half > div {
  width: calc(50% - 8px);
  display: inline-block;
  vertical-align: top;
}
section#content .half > div:first-child {
  margin-right: 8px;
}
section#content .half > div:last-child {
  margin-left: 8px;
}
section#content .half > div:only-child {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  display: block;
}
section#content .half.seperated > div {
  width: calc(50% - 10px);
  border-right: 1px solid #e5e5e5;
}
section#content .half.seperated > div:last-child {
  border-right: none;
}
@media only screen and (max-width: 600px), only screen and (min-width: 890px) and (max-width: 1060px) {
  section#content .half.seperated.stacked > div {
    border-right: none;
  }
}
@media only screen and (max-width: 600px), only screen and (min-width: 890px) and (max-width: 1060px) {
  section#content .half.stacked > div {
    width: 100%;
    display: block;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  section#content .half.stacked > div:last-child {
    padding-top: 16px;
  }
}

/** Table **/
table {
  width: 100% !important;
  max-width: 100%;
  word-break: break-all;
  vertical-align: top;
  border-collapse: collapse;
}
table tr, table td {
  vertical-align: top;
}
table tr td {
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 4px 0 4px 4px;
}
table tr td:last-child {
  border-right: none;
}
table tr td:first-child {
  padding-left: 0;
}
table.timetable td {
  text-align: center;
  padding: 4px 0;
}
table.timetable td.tableday {
  font-weight: bold;
  padding-left: 4px;
  text-align: left !important;
}
table.timetable td .time {
  word-spacing: 12px;
}

/** Icon List **/
ul.icon-list {
  list-style: none !important;
  padding-left: 0 !important;
  margin-bottom: 0;
}
ul.icon-list li {
  line-height: 22px;
}
ul.icon-list li i {
  padding-right: 6px;
}

/** Tiles **/
div.tiles {
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 40px auto;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  div.tiles {
    margin: 20px auto;
    max-width: 340px;
  }
}
@media only screen and (max-width: 370px) {
  div.tiles {
    max-width: 100%;
  }
}
div.tiles a.tile {
  -webkit-animation: fadeIn 100ms linear;
  -moz-animation: fadeIn 100ms linear;
  -ms-animation: fadeIn 100ms linear;
  -o-animation: fadeIn 100ms linear;
  animation: fadeIn 100ms linear;
  width: 31.8%;
  margin-right: 2.3%;
  height: 136px;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: 1px solid;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.tiles a.tile i {
  height: 48px;
  line-height: 48px;
  padding: 25px 0 8px 0;
  font-size: 36px;
  display: block;
}
@media only screen and (max-width: 600px) {
  div.tiles a.tile {
    height: 86px;
    font-size: 13px;
  }
  div.tiles a.tile i {
    height: 36px;
    font-size: 27px;
    padding: 8px 0 7px 0;
  }
}
div.tiles a.tile:only-child {
  margin-right: 0;
}
div.tiles a.tile:nth-child(3n) {
  margin-right: 0;
}

/** Menus **/
div.menu {
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0 auto;
  width: 90%;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  div.menu {
    width: 95%;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  div.menu {
    width: 100%;
  }
}
div.menu.dropdown-menu {
  display: none;
}
div.menu .head {
  height: 34px;
  line-height: 34px;
  display: table-row;
  color: #888;
}
div.menu .head > div {
  padding: 0 10px;
  display: table-cell;
}
div.menu .head > div:last-of-type {
  width: 30px;
}
@media only screen and (max-width: 600px) {
  div.menu .head > div {
    padding: 0 8px;
  }
}
div.menu a {
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  display: table-row;
  border-top: 1px solid #f2f2f2;
  text-decoration: none !important;
}
div.menu a.addions {
  height: 34px;
  line-height: 34px;
}
div.menu a.more div:first-child {
  font-weight: bold;
}
div.menu a.divider {
  height: 0 !important;
  border-top: 1px solid #f2f2f2 !important;
  margin: 16px 0;
}
div.menu a:not([href]):not(.link) {
  cursor: default !important;
}
div.menu a:not([href]):not(.link):hover {
  background: none;
}
div.menu a > div {
  padding: 0 10px;
  display: table-cell;
}
div.menu a > div.two-lines {
  line-height: 28px;
  vertical-align: middle;
}
div.menu a > div.two-lines small {
  display: block;
  line-height: 20px;
}
div.menu a > div:last-of-type:not(:only-of-type) {
  text-overflow: inherit;
  width: calc(30px + 8px);
}
div.menu a > div:last-of-type:not(:only-of-type) > i {
  font-size: 20px;
  color: #888;
  line-height: 60px;
  width: 30px;
  text-align: center;
  display: block;
}
div.menu a > div:last-of-type:not(:only-of-type) > i.fa-shopping-basket {
  font-size: 17px;
}
div.menu a:hover, div.menu a:active {
  background: #f4f4f4;
}
div.menu a:last-of-type {
  border-bottom: 1px solid #f2f2f2;
}
div.menu.condensed a {
  line-height: calc(60px - 24px);
  height: calc(60px - 24px);
}
div.menu.condensed a div {
  vertical-align: middle;
}
div.menu.condensed a div:last-of-type i {
  line-height: calc(60px - 20px);
}

/** Flags **/
img.flag-icon {
  border: 0.8px solid #d2d2d2;
}

/** Article **/
.box-sizing-article, #content .container-left, #content .article-text {
  width: calc(100% - 300px);
  float: left;
  border-right: 1px solid #ddd;
  padding-right: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (min-width: 890px) and (max-width: 1320px) {
  .box-sizing-article, #content .container-left, #content .article-text {
    width: 100%;
    border: none;
    padding: 0;
  }
}

#content h3 {
  margin: 24px auto 2px;
  color: #444;
}
#content p.faded {
  color: #acacac;
  font-size: 17px;
}
#content em {
  margin: 40px 0 30px 0;
  color: #777;
  text-align: left;
  display: block;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #content em {
    margin: 20px 0 15px 0;
  }
}
#content h4, #content .mylike-widget-container h3, .mylike-widget-container #content h3 {
  margin: 27px 0 6px;
}
#content a.phone-link:before {
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  content: "\f098";
  width: 20px;
  display: inline-block;
}
#content .container > div:only-child {
  border-right: none;
  width: 100%;
  padding-right: 0;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px), only screen and (min-width: 890px) and (max-width: 1320px) {
  #content .container {
    padding: 0;
    margin: auto;
  }
}
#content .article-text {
  padding-bottom: 30px;
}
#content .container-left em {
  margin: 15px 0 30px 0;
}
#content .container-left form:not(.redesign) {
  padding-top: 16px;
}
@media only screen and (max-width: 600px) {
  #content .container-left form:not(.redesign) {
    padding-top: 4px;
  }
}
#content .container-left hr {
  border: none;
  height: 1px;
  color: #d2d2d2;
  background-color: #d2d2d2;
}
#content .container-left .searchbox input, #content .container-left .searchbox .adyen-input {
  border: 1px solid #e5e5e5;
}
#content .container-left > div iframe[allowfullscreen=allowfullscreen] {
  max-width: 100%;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #content .container-left > div iframe[allowfullscreen=allowfullscreen] {
    min-width: 100%;
    width: 100%;
    height: 360px;
    border: 0;
  }
}
#content .container-left app-attachments {
  margin-top: 16px;
  display: block;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #content .container-left app-attachments {
    margin-bottom: 64px;
  }
}
#content .container-left app-widget-social {
  display: block;
  margin: 22px 0;
}
#content .container-left google-map .map-container {
  width: 100% !important;
}
#content .container-left google-map .map-container .gm-style-mtc-bbw {
  display: none;
}
#content .container-left google-map:not(.overview) .map-container {
  height: 300px !important;
}
#content .container-left google-map.overview .map-container {
  height: calc(100vh - 230px) !important;
  margin: 0 auto;
}
@media only screen and (max-width: 370px), only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #content .container-left google-map.overview .map-container {
    height: calc(100vh - 205px - 64px) !important;
  }
}
#content .container-left google-map.overview .map-container .agm-info-window-content {
  padding-right: 12px;
  padding-bottom: 12px;
  max-width: 400px;
}
@media only screen and (max-width: 370px), only screen and (max-width: 600px) {
  #content .container-left google-map.overview .map-container .agm-info-window-content {
    max-width: 75vw;
  }
}
#content .container-left google-map.overview .map-container .agm-info-window-content h4, #content .container-left google-map.overview .map-container .agm-info-window-content .mylike-widget-container h3, .mylike-widget-container #content .container-left google-map.overview .map-container .agm-info-window-content h3 {
  font-size: 16px;
  margin: 6px 0;
}
#content .container-left google-map.overview .map-container .agm-info-window-content small {
  font-size: 12px;
}
#content .container-left google-map.overview .map-container .agm-info-window-content p {
  font-size: 14px !important;
  max-width: 200px;
}
#content .container-left google-map.overview .map-container .agm-info-window-content p:last-of-type {
  margin-bottom: 0;
}
#content .container-left google-map.overview .map-container .agm-info-window-content a {
  font-size: 14px;
}
#content .container-left google-map.overview .map-container .agm-info-window-content .group img {
  max-width: 50% !important;
  max-height: 105px !important;
  display: inline-block;
}
#content .container-left google-map.overview .map-container .agm-info-window-content .group div {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
}
#content .container-left google-map.overview .map-container .agm-info-window-content .group div p {
  margin-top: 0;
}
#content .container-left google-map.overview .map-container .agm-info-window-content .group div a {
  float: none;
  margin-top: 10px;
  display: block;
}
#content .container-left .map-preview {
  height: 300px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
#content .container-left .map-preview.overview {
  height: calc(100vh - 230px);
}
#content .container-left .map-preview img {
  width: 100%;
  height: 100%;
}
#content .container-left .map-preview .box {
  position: absolute !important;
  text-align: center !important;
  left: 10%;
  top: 10%;
  width: 80%;
}
#content .container-left.all em {
  margin: 30px 0 0 0;
}
#content .container-left.all .menu {
  margin-top: 10px;
}
#content .container-left .guest-list {
  margin-bottom: 30px;
}
#content .container-left .guest-list .guest {
  padding-top: 16px;
}
#content .container-left .guest-list .guest.last-guest {
  border-bottom: 1px solid #d2d2d2;
}
#content .container-right {
  width: 260px;
  text-align: left;
  float: right;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (min-width: 890px) and (max-width: 1320px) {
  #content .container-right {
    width: 100%;
  }
}
#content .container-right app-swiper, #content .container-right app-widget-advert, #content .container-right .opening_hours, #content .container-right .basket_mini, #content .container-right .article-address, #content .container-right .article-price {
  display: block;
  margin-bottom: 30px;
}
#content .container-right app-swiper:last-child, #content .container-right app-widget-advert:last-child, #content .container-right .opening_hours:last-child, #content .container-right .basket_mini:last-child, #content .container-right .article-address:last-child, #content .container-right .article-price:last-child {
  margin-bottom: 0;
}
#content .container-right app-swiper#wish {
  margin-top: 100px;
}
#content .container-right app-widget-advert .slider {
  width: 260px;
}
#content .container-right app-widget-advert span {
  width: 260px;
}
#content .container-right app-widget-advert span:first-of-type {
  margin: 0 auto;
}
#content .container-right app-widget-advert span:first-of-type span {
  width: 198px;
}
#content .container-right app-widget-advert .inner {
  width: 260px;
}
#content .container-right h4, #content .container-right .mylike-widget-container h3, .mylike-widget-container #content .container-right h3 {
  margin-top: 0;
}
#content .container-right img:not(.imgSlide) {
  text-align: center;
}
@media only screen and (max-width: 370px), only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #content .container-right img:not(.imgSlide) {
    width: 100%;
    margin: 0 0 15px 0;
  }
}
#content .container-right .article-price {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: right;
  background: #f4f4f4;
  padding: 16px;
}
#content .container-right .article-price h3, #content .container-right .article-price h4, #content .container-right .article-price .mylike-widget-container h3, .mylike-widget-container #content .container-right .article-price h3 {
  margin: 0 0 7px;
  line-height: 1em;
  font-size: 30px;
  color: #444;
}
#content .container-right .article-price h3.range, #content .container-right .article-price h4.range {
  font-size: 26px;
}
#content .container-right .article-price h4, #content .container-right .article-price .mylike-widget-container h3, .mylike-widget-container #content .container-right .article-price h3 {
  font-size: 22px;
}
#content .container-right .article-price p {
  margin: 0;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #content .container-right .article-address {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #content .container-right .article-address:first-of-type {
    padding-left: 0;
  }
  #content .container-right .article-address:first-child:nth-last-child(1),
#content .container-right .article-address:first-child:nth-last-child(2),
#content .container-right .article-address:first-child:nth-last-child(2) ~ .article-address {
    width: 100%;
  }
  #content .container-right .article-address:first-child:nth-last-child(3),
#content .container-right .article-address:first-child:nth-last-child(3) ~ .article-address {
    width: 50%;
  }
  #content .container-right .article-address:first-child:nth-last-child(4),
#content .container-right .article-address:first-child:nth-last-child(4) ~ .article-address {
    width: 33.3333%;
  }
}
#content .container-right .basket_mini {
  display: block;
  background: #f4f4f4;
  color: #4c4c4c;
  width: 100%;
  text-align: left;
  padding: 16px 0;
  text-decoration: none;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #content .container-right .basket_mini {
    display: none;
  }
}
#content .container-right .basket_mini h4, #content .container-right .basket_mini .mylike-widget-container h3, .mylike-widget-container #content .container-right .basket_mini h3 {
  color: #808080;
  margin: 0 0 0 14px !important;
}
#content .container-right .basket_mini ul.b-mini-list {
  padding: 12px 14px 16px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#content .container-right .basket_mini ul.b-mini-list li {
  line-height: 1.6em;
  list-style: none;
}
#content .container-right .basket_mini .b-mini-total {
  margin: 0 14px;
  border-top: 1px solid #ccc;
  padding: 9px 2px 0;
  text-align: right;
}
#content .container-right .basket_mini .b-mini-total h5 {
  color: #444;
  font-size: 19px;
  margin: 0 !important;
}
#content .container-right .basket_mini .b-mini-total p {
  font-size: 14px;
  margin: 0;
}
#content .container-right .opening_hours {
  padding-bottom: 15px;
}
@media only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  #content .container-right .opening_hours {
    padding-bottom: 0;
  }
}
#content .container-right .opening_hours h4, #content .container-right .opening_hours .mylike-widget-container h3, .mylike-widget-container #content .container-right .opening_hours h3 {
  margin-top: 0;
}
#content .container-right .opening_hours table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (min-width: 890px) and (max-width: 1320px) {
  #content .container-right .opening_hours table {
    width: 70%;
    margin: auto;
  }
}
#content .container-right .opening_hours table tr {
  vertical-align: top;
  border-bottom: 1px solid #f2f2f2;
}
#content .container-right .opening_hours table tr td {
  text-align: center;
}
#content .container-right .opening_hours table tr td span {
  width: 60px;
  display: inline-block;
  text-align: center;
}
#content .container-right app-widget-advert {
  margin-top: 27px;
  text-align: center;
  padding-bottom: 8px;
}
#content .container-right app-widget-advert .slider {
  font-size: 1.5em;
}
#content .container-right app-widget-advert .swiper-button-next:hover, #content .container-right app-widget-advert .swiper-button-next:active, #content .container-right app-widget-advert .swiper-button-prev:hover, #content .container-right app-widget-advert .swiper-button-prev:active {
  background: #dedede !important;
}
#content .container-right app-widget-advert .swiper-button-next:focus, #content .container-right app-widget-advert .swiper-button-prev:focus {
  outline: none;
}
#content .container-right app-widget-advert img {
  margin-bottom: 0 !important;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

/** Files **/
.menu.files .head div:nth-of-type(1), .menu.files a div:nth-of-type(1) {
  width: 40px;
}
.menu.files .head div:nth-of-type(1) i, .menu.files a div:nth-of-type(1) i {
  font-size: 17px;
}

/** Overviews **/
#container.overviews section#content .overview-text-container, #container.cico footer.overviews section#content .overview-text-container {
  text-align: center;
  position: fixed;
  top: 220px;
  width: calc(100vw - (2 * 260px));
}
#container.overviews section#content .overview-text-container.start, #container.cico footer.overviews section#content .overview-text-container.start {
  top: unset;
  bottom: 150px;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  #container.overviews section#content .overview-text-container, #container.cico footer.overviews section#content .overview-text-container {
    width: calc(100vw - 260px);
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container.overviews section#content .overview-text-container, #container.cico footer.overviews section#content .overview-text-container {
    position: relative;
    top: 0;
    width: 100vw;
    margin: 80px 0 0 0;
  }
}
#container.overviews section#content .overview-text-container em, #container.cico footer.overviews section#content .overview-text-container em {
  color: white;
  padding: 1px 8px;
  line-height: 156%;
  font-style: normal;
  display: inline !important;
}
#container.overviews section#content .overview-text-container em span, #container.cico footer.overviews section#content .overview-text-container em span {
  font-size: 85%;
}
#container.overviews section#content .overview-text-container em.none, #container.cico footer.overviews section#content .overview-text-container em.none {
  visibility: hidden;
}
#container.overviews section#content .overview-text-container em + .overview-button, #container.cico footer.overviews section#content .overview-text-container em + .overview-button {
  margin-top: 60px;
}
#container.overviews section#content .overview-text-container .overview-button, #container.cico footer.overviews section#content .overview-text-container .overview-button {
  padding: 14px 26px;
  display: block;
  text-align: center;
  border: none;
  width: 200px;
  margin: 0 auto;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container.overviews section#content .overview-text-container .overview-button:not(.internet), #container.cico footer.overviews section#content .overview-text-container .overview-button:not(.internet) {
    display: none;
  }
}
/** News **/
#news-widget {
  position: fixed;
  width: 800px;
  bottom: 0;
  left: 50%;
  margin-left: -400px;
  height: 58px;
  text-align: center;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  #news-widget {
    width: 500px;
    margin-left: -120px;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #news-widget {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
#news-widget div.news-container {
  -moz-box-shadow: 1px -1px 13px 0 rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 1px -1px 13px 0 rgba(0, 0, 0, 0.41);
  box-shadow: 1px -1px 13px 0 rgba(0, 0, 0, 0.41);
  position: relative;
  display: inline-block;
  padding-left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 58px;
  line-height: 58px;
}
#news-widget div.news-container .swiper-slide .text {
  padding: 0 145px 0 58px;
}
#news-widget div.news-container .button-hollow-small {
  opacity: 1;
  color: white;
  position: absolute;
  top: 14px;
  right: 58px;
  bottom: 0;
  margin: 0 18px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #news-widget div.news-container .button-hollow-small {
    right: 50px;
  }
}
#news-widget div.news-container.no-controls .text {
  padding: 0 87px 0 14px;
}
#news-widget div.news-container.no-controls a.button-hollow-small {
  right: 8px;
  margin: 0 6px;
}
#news-widget div.news-container.no-controls .swiper-button-next, #news-widget div.news-container.no-controls .swiper-button-prev {
  display: none;
}
#news-widget div.news-container .swiper-button-next, #news-widget div.news-container .swiper-button-prev {
  width: 58px;
  padding-top: 9px;
  font-size: 2.4em !important;
}
#news-widget div.news-container .swiper-button-next:hover, #news-widget div.news-container .swiper-button-next:active, #news-widget div.news-container .swiper-button-prev:hover, #news-widget div.news-container .swiper-button-prev:active {
  background: rgba(0, 0, 0, 0.2) !important;
}
#news-widget div.news-container .swiper-button-next:focus, #news-widget div.news-container .swiper-button-prev:focus {
  outline: none;
}

/** Element Navigation **/
nav.element-navigation {
  width: 100%;
  height: 35px;
  padding: 18px 0 10px;
  border-top: 1px solid #f2f2f2;
  text-align: center;
  margin: 44px auto 0;
  float: left;
}
@media only screen and (max-width: 600px) {
  nav.element-navigation {
    margin: 15px 0 auto;
  }
}
nav.element-navigation .navigation-container {
  margin: auto;
}
nav.element-navigation .navigation-container .nav-btn {
  width: 33%;
  float: left;
  text-align: center;
}
nav.element-navigation .navigation-container .nav-btn:first-child {
  text-align: left;
}
nav.element-navigation .navigation-container .nav-btn:first-child a::before {
  content: "«";
  margin-right: 5px;
}
nav.element-navigation .navigation-container .nav-btn:last-child {
  text-align: right;
}
nav.element-navigation .navigation-container .nav-btn:last-child a::after {
  content: "»";
  margin-left: 5px;
}
nav.element-navigation .navigation-container .nav-btn a {
  cursor: pointer;
  color: #444 !important;
  padding: 18px 0;
}
@media only screen and (max-width: 600px) {
  nav.element-navigation .navigation-container .nav-btn a {
    font-size: 13px !important;
  }
}
nav.element-navigation .navigation-container.back .nav-btn {
  width: 100%;
  text-align: center;
}
nav.element-navigation .navigation-container.back .nav-btn:first-child {
  text-align: center;
}
nav.element-navigation .navigation-container.back .nav-btn:last-child a::after {
  content: " ";
  margin-left: 0;
}

.icon-menu ul.icon-nav {
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;
}
.icon-menu ul.icon-nav li {
  display: table-cell;
  text-align: center;
  border: none;
  border-right: 1px solid;
  border-bottom: 1px solid;
  vertical-align: middle;
  line-height: 30px;
  height: 30px;
}
.icon-menu ul.icon-nav li:last-of-type {
  border-right: none;
}
.icon-menu ul.icon-nav li:hover {
  background: #f4f4f4;
}
.icon-menu ul.icon-nav li.active {
  border-bottom: none;
  background: #f4f4f4;
}
.icon-menu div.menu {
  display: none;
  width: 100%;
}
.icon-menu div.menu.active {
  display: table;
}
.icon-menu div.menu a div:first-of-type {
  text-align: left;
}
.icon-menu div.menu a:first-child {
  border-top: none;
}
.icon-menu div.menu a:last-child {
  border-bottom: none;
}

/** iFrame **/
.iframe_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 49px);
  -webkit-overflow-scrolling: touch !important;
  overflow-y: scroll !important;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .iframe_container {
    height: calc(100vh - 64px - 64px);
  }
}
.iframe_container object, .iframe_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 100%;
  border: 0;
}

@media only screen and (min-width: 600px ) and (max-width: 890px) {
  app-link.iOS .iframe_container {
    height: calc(100vh - 64px - 64px - 64px) !important;
  }
}

#container.frame nav.element-navigation, #container.cico footer.frame nav.element-navigation {
  margin: auto 0;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #container.frame nav.element-navigation, #container.cico footer.frame nav.element-navigation {
    display: none;
  }
}

.signature canvas {
  width: 100%;
  height: 300px;
  border-bottom: 2px solid #888;
  position: relative;
  z-index: 10;
}

.add-btn {
  cursor: pointer;
  display: inline-block;
  margin: 16px 0;
}
.add-btn div {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  text-align: center;
  float: left;
}
.add-btn div i {
  padding-top: 22px;
}
.add-btn span {
  display: inline-block;
  padding: 19px 16px;
}

.remove-btn {
  display: inline-block;
  padding-top: 19px;
  float: right;
}

.select-frame {
  position: relative;
  margin: 14px 0 16px;
}
.select-frame i {
  position: absolute;
  line-height: 56px;
  font-size: 24px;
  color: #555;
  right: 20px;
}

select {
  width: 100%;
  margin: 0;
}

textarea {
  height: 80px;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

/** Searchbox **/
div.searchbox {
  border-radius: 0;
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
div.searchbox .header {
  width: 46%;
  position: absolute;
  top: 52px;
  right: 0;
}
div.searchbox .search-icon {
  color: white;
  height: 100%;
  width: 82px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 17px;
}
div.searchbox input[type=search], div.searchbox [type=search].adyen-input {
  width: 100%;
  min-width: 100%;
  height: 50px;
  padding-left: 94px;
  margin: 0 auto;
}

/** Dots **/
.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  border: 1px solid white;
  margin-bottom: 2px;
}
.dot.confirmed, .dot.created, .dot.pre_checked_in {
  background: #2E8BCC;
}
.dot.checked_in {
  background: #4AB58E;
}
.dot.checked_out, .dot.no_show, .dot.canceled {
  background: #F1416C;
}

/** Stars **/
.stars i {
  font-size: 50px;
  margin-top: 8px;
  padding-right: 3%;
  vertical-align: top;
}
@media only screen and (max-width: 600px) {
  .stars i {
    width: 50px;
  }
}

#capture video, #capture canvas {
  width: 100%;
  height: auto;
  margin: auto;
}
#capture .button {
  margin-top: 16px;
  display: inline-block;
}

/*===================
* Popup
====================*/
section.popup-ui {
  -webkit-animation: fadeIn 0.2s linear;
  -moz-animation: fadeIn 0.2s linear;
  -ms-animation: fadeIn 0.2s linear;
  -o-animation: fadeIn 0.2s linear;
  animation: fadeIn 0.2s linear;
  display: none;
  z-index: 10000;
  width: 700px;
  max-width: 94%;
  height: auto;
  top: 0;
  margin: 50px auto 50px;
  position: fixed;
  max-height: calc(100vh - 100px);
  left: 260px;
  right: 260px;
}
section.popup-ui.active {
  display: block;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  section.popup-ui {
    width: 560px;
    left: 0;
    right: 0;
  }
}
@media only screen and (min-height: 1800px) {
  section.popup-ui {
    width: 700px;
    left: 0;
    right: 0;
  }
}
section.popup-ui div.button-grid {
  margin-top: 0 !important;
}
section.popup-ui div.button-grid a {
  margin-bottom: 0;
}
section.popup-ui div.page-headline {
  position: relative;
  margin-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
  padding: 0 10px 10px 1px !important;
}
section.popup-ui div.page-headline h2 {
  color: #444;
  line-height: 1.4em;
  margin: 7px 0;
  padding-right: 35px;
}
section.popup-ui app-news-item .inner {
  max-height: calc(100vh - 280px);
}
section.popup-ui app-news-item #version-update {
  display: none;
}
section.popup-ui div.content {
  z-index: 9000;
  position: relative;
  padding: 22px;
  background: white;
  -moz-box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.45);
  box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  max-height: calc(100vh - 100px) !important;
}
@media only screen and (max-width: 600px) {
  section.popup-ui div.content {
    padding: 10px 18px 18px 18px;
  }
}
section.popup-ui div.content > .inner, section.popup-ui div.content .popup {
  position: relative;
  overflow: auto;
  padding: 0 3px;
  min-height: 70px;
  max-height: calc(100vh - 232px);
  scrollbar-width: thin;
}
section.popup-ui div.content > .inner.button-attached, section.popup-ui div.content .popup.button-attached {
  max-height: calc(100vh - 282px);
}
section.popup-ui div.content > .inner::-webkit-scrollbar, section.popup-ui div.content .popup::-webkit-scrollbar {
  display: block;
  width: 3px;
}
section.popup-ui div.content .popup-text .half {
  width: calc(49% - 20px);
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
}
@media only screen and (max-width: 600px) {
  section.popup-ui div.content .popup-text .half {
    width: 100%;
    display: block;
    padding: 5px 0;
  }
}
section.popup-ui div.content app-widget-social {
  display: block;
  margin: 22px 0;
}
section.popup-ui div.content h4, section.popup-ui div.content .mylike-widget-container h3, .mylike-widget-container section.popup-ui div.content h3 {
  margin-bottom: 10px;
}
section.popup-ui div.content p {
  margin-bottom: 15px;
}
section.popup-ui div.content a {
  color: #555;
  font-weight: 600 !important;
  text-decoration: underline;
  cursor: pointer;
}
section.popup-ui div.content em {
  margin: 40px 0 30px 0;
  color: #777;
  text-align: center;
  display: block;
}
section.popup-ui div.content img:not(.imgSlide):not(.demo):not([width]) {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}
section.popup-ui div.content table {
  width: 100%;
  margin-top: 15px;
}
section.popup-ui div.content table td.text-center {
  text-align: center;
}
section.popup-ui div.content hr {
  margin: 16px 0;
  border-color: #f2f2f2;
}
section.popup-ui div.content .contact h4, section.popup-ui div.content .contact .mylike-widget-container h3, .mylike-widget-container section.popup-ui div.content .contact h3 {
  margin-top: 15px;
}
section.popup-ui div.content .contact p {
  margin: 2px 0;
}
section.popup-ui div.content app-attachments,
section.popup-ui div.content app-widget-social {
  margin-top: 14px;
  display: block;
}
section.popup-ui div.content.small {
  width: 500px !important;
  max-width: 94%;
}
section.popup-ui div.close-icon {
  position: absolute;
  top: 23px;
  right: 23px;
  color: #c9c9c9;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  z-index: 10000;
  background: #f9f9f9;
}
@media only screen and (max-width: 600px) {
  section.popup-ui div.close-icon {
    top: 10px;
  }
}
section.popup-ui div.close-icon i {
  font-size: 22px;
  padding: 8px 5px;
}
section.popup-ui #social {
  margin-top: 15px;
}
section.popup-ui #social a i {
  color: inherit;
}
section.popup-ui form {
  margin: auto;
}

/** Advert **/
i.ad-icon {
  padding-right: 7px;
  font-style: normal;
}
i.ad-icon::after {
  font-family: "Font Awesome 6 Free";
  content: "\f641";
  font-weight: 900;
}

/** IACBox **/
#iacbox {
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

/** Door Drag-Object **/
.door.cdk-drag.cdk-drag-preview > div {
  background: #f9f9f9;
  padding: 14px 0 14px 16px;
}
.door.cdk-drag.cdk-drag-preview > div:nth-of-type(1) {
  display: none;
}
.door.cdk-drag.cdk-drag-preview > div:nth-of-type(2) small {
  display: block;
}
.door.cdk-drag.cdk-drag-preview > div:nth-of-type(3) {
  display: none;
}

/*==================
* Swipe Info
===================*/
html:not(.can_touchevents) .swipe_info {
  display: none;
  left: -50000;
}
html.can_touchevents .swipe_info {
  display: none;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  top: 300px;
  left: 50%;
  width: 300px;
  height: 145px;
  margin-left: -150px;
  background: rgba(255, 255, 255, 0.65);
  border-radius: 10px;
  z-index: 600;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  html.can_touchevents .swipe_info {
    margin-left: 0;
  }
}
html.can_touchevents .swipe_info div {
  width: 49%;
  display: inline-block;
}
html.can_touchevents .swipe_info div img {
  display: block;
  margin: 0 auto;
}
html.can_touchevents .swipe_info div:nth-child(1) img {
  -webkit-animation: swipe_left 1s 2;
  -moz-animation: swipe_left 1s 2;
  -o-animation: swipe_left 1s 2;
  animation: swipe_left 1s 2;
}
html.can_touchevents .swipe_info div:nth-child(2) img {
  -webkit-animation: swipe_right 1s 2;
  -moz-animation: swipe_right 1s 2;
  -o-animation: swipe_right 1s 2;
  animation: swipe_right 1s 2;
}

.swipe_fade {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 3s, opacity 3s linear;
}

/*==================
* Info box
===================*/
.info_box {
  border: 1px solid;
  margin: 0 auto 15px;
  padding: 8px 12px;
  font-weight: bold;
  border-radius: 10px;
  display: inline-block;
  word-break: break-word;
}
.info_box.info {
  background-color: #f2f2f2;
  border-color: #d2d2d2;
}
.info_box.normal {
  border-radius: 0;
}
.info_box i {
  float: left;
  font-size: 28px;
}
.info_box > p, .info_box > b {
  display: block;
  padding: 3px 0 0 40px;
  margin: 0 !important;
  min-height: 25px;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  .info_box i {
    padding-top: 3px;
  }
  .info_box > p, .info_box > b {
    padding: 0 0 0 40px;
  }
}

/** No sidebar **/
body.no-sidebar app-sidebar {
  display: none;
}
body.no-sidebar section#content {
  margin-right: 0 !important;
}
body.no-sidebar section#content .overview-text-container {
  width: calc(100vw - 260px) !important;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  body.no-sidebar section#content .overview-text-container {
    width: 100vw !important;
  }
}
@media only screen and (min-width: 3000px), only screen and (min-width: 1321px) {
  body.no-sidebar section#search {
    margin-left: calc(-250px + (260px / 2));
  }
}
@media only screen and (min-width: 3000px), only screen and (min-width: 1321px) {
  body.no-sidebar #news-widget {
    margin-left: calc(-400px + (260px / 2));
  }
}

@media screen and (max-width: 767px) {
  .install-prompt {
    top: 50px;
  }
}
/* Brand Icons Fix */
i.fa.fab {
  font-weight: 400 !important;
}

/* IE Fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    overflow: hidden !important;
  }

  #container:not(.overviews) section#navigation, #container.cico footer:not(.overviews) section#navigation {
    overflow: auto;
    max-height: 100vh;
  }

  div.searchbox {
    height: auto;
    width: calc(100% - 94px);
  }
  div.searchbox input[type=search], div.searchbox [type=search].adyen-input {
    width: calc(100% - 94px);
    min-width: calc(100% - 94px);
    height: auto;
  }
}
/*==================
* QR-button
===================*/
.qr-button {
  display: inline-block;
  width: 210px;
  height: 40px;
  border-radius: 8px;
  border: solid 2px #e5e5e5;
}
.qr-button a {
  position: relative;
  top: 20%;
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 1.5;
  color: black !important;
  text-decoration: none !important;
}
.qr-button a i {
  padding: 0 5px 0 5px;
}

.credentials {
  text-align: center;
}

/*==================
* App-form-legal
===================*/
app-form-legal {
  text-align: center;
}

/*==================
* Link-box
===================*/
.link-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 2px #e5e5e5;
  text-decoration: none !important;
  text-align: center;
  padding: 8px 16px;
  margin: 8px;
}
.link-box i {
  color: #a9acaf !important;
}
.link-box.help {
  position: fixed;
  left: 3%;
  bottom: 3%;
}

.language-list-zindex {
  z-index: 4;
}

* {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  /* Firefox */
  -ms-tap-highlight-color: transparent;
  /* Microsoft Edge (legacy) */
  tap-highlight-color: transparent;
  /* Standard */
}

.instructions {
  color: #1A1A1A;
  font-style: normal;
  font-weight: 500;
  text-shadow: none;
  line-height: normal;
  padding-bottom: 20px;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .instructions {
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .instructions {
    padding: 5px 0 40px;
  }
}
.instructions.required-text {
  color: #5A5A5A;
}
.instructions.required-text:before {
  content: "*";
  color: #F1416C;
  display: inline-block;
  padding-right: 4px;
}
.instructions.not-first-instruction {
  padding: 30px 0 30px;
}
@media only screen and (min-width: 1321px) {
  .instructions.not-first-instruction {
    padding: 60px 0 60px;
  }
}

app-check-in a:not(.redesign),
.wizard section#content a:not(.redesign) {
  font-size: inherit !important;
}

body {
  background-color: #FBFBFB;
}

.step-container {
  background-color: white;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px), only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .step-container {
    padding: 24px;
  }
}

.thin-scroll {
  scrollbar-width: thin;
}
.thin-scroll::-webkit-scrollbar {
  display: block;
  width: 3px;
}

.two-col-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px), only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .two-col-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 30px;
  }
}

app-check-in, app-check-out {
  padding: 0 !important;
}
app-check-in .container, app-check-out .container {
  min-height: 100vh;
}

#container.overlay form, #container.cico footer.overlay form, #container.overlay #steps-container, #container.cico footer.overlay #steps-container, #container.overlay .page-info, #container.cico footer.overlay .page-info, #container.overlay app-pms-confirm .content, #container.cico footer.overlay app-pms-confirm .content {
  display: none;
}
#container.overlay .grid-container, #container.cico footer.overlay .grid-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#container.overlay app-check-in > app-loader, #container.cico footer.overlay app-check-in > app-loader, #container.overlay app-check-out > app-loader, #container.cico footer.overlay app-check-out > app-loader {
  display: none;
}
@media only screen and (min-width: 990px) and (max-width: 1800px) {
  #container app-country-box-group > .boxes-container, #container.cico footer app-country-box-group > .boxes-container, #container.cico footer.cico footer app-country-box-group > .boxes-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
#container.wizard .grid-container, #container.cico footer.wizard .grid-container {
  padding: 30px 43px 130px 43px;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  #container.wizard .grid-container, #container.cico footer.wizard .grid-container {
    padding: 40px 50px 170px 50px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  #container.wizard .grid-container, #container.cico footer.wizard .grid-container {
    padding: 60px 321px 178px 321px;
  }
}
@media only screen and (min-height: 1800px) {
  #container.wizard .grid-container, #container.cico footer.wizard .grid-container {
    padding: 60px 100px 178px 100px;
  }
}
#container.wizard .component-overlay, #container.cico footer.wizard .component-overlay {
  max-height: calc(100vh - 64px - 140px);
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px), only screen and (min-height: 1800px) {
  #container.wizard .component-overlay, #container.cico footer.wizard .component-overlay {
    width: 70vw;
  }
}
@media only screen and (min-width: 1321px) {
  #container.wizard .component-overlay, #container.cico footer.wizard .component-overlay {
    width: 50vw;
  }
}
#container.wizard.frame .iframe_container, #container.cico footer.wizard.frame .iframe_container {
  height: calc(100vh - 180px) !important;
}
#container.wizard footer, #container.cico footer.wizard footer {
  left: 0 !important;
  bottom: 0 !important;
  width: 100vw !important;
  padding: 20px 0 !important;
}
@media only screen and (min-width: 1320px) and (max-width: 1700px) {
  #container.wizard app-country-box-group > .boxes-container, #container.cico footer.wizard app-country-box-group > .boxes-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
#container:not(.wizard) .grid-container, #container.cico footer:not(.wizard) .grid-container {
  padding: 25px 43px 58px 43px;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  #container:not(.wizard) .grid-container, #container.cico footer:not(.wizard) .grid-container {
    padding: 0 0 58px 0;
  }
}
@media only screen and (max-width: 600px) {
  #container:not(.wizard) .grid-container, #container.cico footer:not(.wizard) .grid-container {
    padding: 25px 0;
  }
}
@media only screen and (max-width: 370px) {
  #container:not(.wizard) .grid-container, #container.cico footer:not(.wizard) .grid-container {
    padding: 25px 5px;
  }
}
#container:not(.wizard) .component-overlay, #container.cico footer:not(.wizard) .component-overlay {
  max-height: calc(100vh - 64px - 80px);
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px), only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  #container:not(.wizard) .component-overlay, #container.cico footer:not(.wizard) .component-overlay {
    max-height: calc(100vh - 64px - 140px);
  }
}
@media only screen and (min-width: 990px) and (max-width: 1800px) {
  #container:not(.wizard) app-country-box-group > .boxes-container, #container.cico footer:not(.wizard) app-country-box-group > .boxes-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
#container:not(.wizard) .background, #container.cico footer:not(.wizard) .background {
  width: calc(100vw + 260px) !important;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (min-width: 1321px) {
  #container:not(.wizard) .background, #container.cico footer:not(.wizard) .background {
    width: 100vw !important;
  }
}
#container:not(.wizard) .loader-container, #container.cico footer:not(.wizard) .loader-container {
  height: auto;
  width: 100%;
  position: relative;
  padding: 0 0 43px 0;
  background: none;
}
@media only screen and (max-width: 600px) {
  #container:not(.wizard) app-pms-ci-general .modal-box, #container.cico footer:not(.wizard) app-pms-ci-general .modal-box, #container:not(.wizard) app-pms-co-general .modal-box, #container.cico footer:not(.wizard) app-pms-co-general .modal-box {
    transform: translate(-50%, 50%) !important;
  }
}
#container app-conflict, #container.cico footer app-conflict {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 5;
}
#container app-pms-cico-overlay #logo {
  text-align: center;
}
#container app-pms-cico-overlay .close-icon {
  top: 100px;
}
#container.cico.frame .iframe_container, #container.cico footer.cico.frame .iframe_container {
  height: calc(100vh - 100px) !important;
}
#container.cico.frame app-steps-indicator, #container.cico footer.cico.frame app-steps-indicator, #container.cico.frame .page-info, #container.cico footer.cico.frame .page-info {
  display: none;
}
@media only screen and (max-width: 600px) {
  #container.cico.frame .iframe_container, #container.cico footer.cico.frame .iframe_container {
    height: calc(100vh - 64px) !important;
  }
  #container.cico.frame .grid-container, #container.cico footer.cico.frame .grid-container {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  #container.cico section#content:not(.is-safari) {
    min-height: calc(100vh - 64px - 80px);
    padding: 64px 20px 80px 20px;
  }
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  #container.cico section#content:not(.is-safari) {
    min-height: calc(100vh - 80px);
  }
}
#container.cico footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  text-align: center;
  background-color: white;
  padding: 20px 0;
  box-sizing: border-box;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  z-index: 2;
}
@media only screen and (min-width: 890px) and (max-width: 1320px) {
  #container.cico footer {
    left: 260px;
    width: calc(100vw - 260px);
    height: auto;
  }
}
@media only screen and (min-width: 1321px) {
  #container.cico footer {
    left: 260px;
    right: 260px;
    width: calc(100vw - 520px);
    height: auto;
  }
}
#container.cico footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13.5px;
  border: 1px solid transparent;
  border-radius: 2px;
  width: 274px;
  height: 40px;
  margin: 0 7px;
  font-size: 13px;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  #container.cico footer button {
    width: 620px;
    height: 50px;
    margin: 0 15px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1321px) {
  #container.cico footer button {
    margin: 0 20px;
    width: 303px;
    height: 60px;
    font-size: 20px;
  }
}
@media only screen and (min-height: 1800px) {
  #container.cico footer button {
    margin: 0 20px;
    width: 620px;
    height: 60px;
    font-size: 22px;
  }
}
#container.cico footer button.small-width.btn-outline {
  width: 120px;
}
#container.cico footer button.small-width.btn-filled {
  width: 140px;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  #container.cico footer button.small-width.btn-outline, #container.cico footer button.small-width.btn-filled {
    width: 295px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  #container.cico footer button.small-width.btn-outline, #container.cico footer button.small-width.btn-filled {
    width: 274px;
  }
}
#container.cico footer button:hover {
  cursor: pointer;
}
#container.cico footer button.btn-outline {
  background-color: transparent;
}
#container.cico footer button:disabled {
  color: white;
  border-color: #B1B1B1;
  background-color: #B1B1B1;
  cursor: initial;
}
#container.cico app-form-legal {
  display: block;
}
#container.cico app-form-legal a {
  text-decoration: underline !important;
}
@media only screen and (max-width: 600px) {
  #container.cico #activate {
    display: none;
  }
}

@media only screen and (min-width: 1321px) {
  body.no-sidebar #container.cico footer, #container.cico body.no-sidebar footer.cico footer {
    right: 0;
    width: calc(100vw - 260px);
  }
}
@media only screen and (min-width: 1321px) {
  body.no-sidebar .generic-overlay.with_back, body.no-sidebar .component-overlay.with_back {
    left: calc(50% + 130px);
  }
}

#alert-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  color: #272b36;
  padding: 32px 24px;
  margin-bottom: 40px;
  border-radius: 2px;
}
@media only screen and (max-width: 600px) {
  #alert-box {
    margin: 20px 0 0 0;
    padding: 24px 16px;
  }
}
#alert-box.info {
  background: #ecf5ff;
}
#alert-box.success {
  background: #e3f1df;
}
#alert-box.notice {
  background: #fff4ec;
}
#alert-box.error {
  background: #fbeae5;
}
#alert-box h3 {
  margin-top: 0;
  margin-bottom: 4px;
}
#alert-box p {
  margin: 0 !important;
  font-size: inherit;
}

/*==================
* Modal-box
===================*/
.modal-box {
  max-width: 620px;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 40px;
  text-align: center;
  overflow-y: auto;
  max-height: 74vh;
  margin: 50px auto 7px auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media only screen and (max-width: 600px) {
  .modal-box {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    min-width: 90vw;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .modal-box {
    margin-top: 40px;
    max-height: 85vh;
  }
}
.modal-box > i {
  font-size: 65px;
  margin-bottom: 16px;
}
.modal-box h1, .modal-box h1 a {
  font-size: 22px !important;
  font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
  .modal-box h1, .modal-box h1 a {
    font-size: 20px !important;
  }
}
.modal-box h2 {
  font-size: 20px;
}
@media only screen and (max-width: 600px) {
  .modal-box h2 {
    font-size: 18px;
  }
}
.modal-box .desc em {
  font-size: 18px;
  line-height: 1.33;
  color: #272b36;
  text-align: justify !important;
  margin: 0 20px !important;
}
@media only screen and (max-width: 600px) {
  .modal-box .desc em {
    margin: 0 !important;
  }
}
.modal-box .desc em + em {
  padding-top: 8px;
}

.page-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 15px;
  background-color: white;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px), only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .page-info {
    padding: 24px;
  }
}

app-pms-cico-overlay button.close {
  margin-top: 0;
  margin-bottom: 16px;
}

.input-container.disabled .info-wrapper {
  display: none !important;
}

.camera-button {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: max-content;
  height: 42px;
  padding: 0 20px;
  border: none;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .camera-button {
    margin-top: 6px;
    height: 52px;
    column-gap: 15px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .camera-button {
    height: 62px;
    column-gap: 15px;
    font-size: 18px;
  }
}
.camera-button:disabled {
  background-color: #B1B1B1;
  color: white;
  cursor: default;
}
.camera-button:disabled i {
  color: white;
}
.camera-button i {
  font-size: 18px;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .camera-button i {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  .camera-button i {
    font-size: 22px;
  }
}

app-success-screen .text a {
  text-decoration: underline !important;
  font-weight: 700 !important;
}

@media only screen and (min-height: 1800px) {
  .toast-top-center {
    top: 80px;
    font-size: 18px;
  }
}

form fieldset, .detract fieldset {
  border: none;
  margin: 0 auto;
  max-width: 800px;
}
@media only screen and (min-width: 890px) and (max-width: 1060px), only screen and (min-width: 600px ) and (max-width: 890px) {
  form fieldset, .detract fieldset {
    max-width: 600px;
  }
}
form fieldset:disabled, .detract fieldset:disabled {
  opacity: 0.7;
}
@media only screen and (max-width: 600px) {
  form, .detract {
    margin: auto;
  }
}
@media only screen and (min-width: 3000px) {
  form, .detract {
    max-width: 800px;
    margin: 0 auto;
  }
}
form.ng-invalid.ng-touched .form-box input.ng-invalid.ng-touched, form.ng-invalid.ng-touched .form-box .ng-invalid.ng-touched.adyen-input, form.ng-invalid.ng-touched #content .container-left .map-preview .box input.ng-invalid.ng-touched, form.ng-invalid.ng-touched #content .container-left .map-preview .box .ng-invalid.ng-touched.adyen-input, #content .container-left .map-preview form.ng-invalid.ng-touched .box input.ng-invalid.ng-touched, #content .container-left .map-preview form.ng-invalid.ng-touched .box .ng-invalid.ng-touched.adyen-input,
form.ng-invalid.ng-touched .form-box ng-select.ng-invalid.ng-touched,
form.ng-invalid.ng-touched #content .container-left .map-preview .box ng-select.ng-invalid.ng-touched,
#content .container-left .map-preview form.ng-invalid.ng-touched .box ng-select.ng-invalid.ng-touched,
form.ng-invalid.ng-touched .form-box textarea.ng-invalid.ng-touched,
form.ng-invalid.ng-touched #content .container-left .map-preview .box textarea.ng-invalid.ng-touched,
#content .container-left .map-preview form.ng-invalid.ng-touched .box textarea.ng-invalid.ng-touched,
form.ng-invalid.ng-touched .form-box signature-pad canvas,
form.ng-invalid.ng-touched #content .container-left .map-preview .box signature-pad canvas,
#content .container-left .map-preview form.ng-invalid.ng-touched .box signature-pad canvas,
form.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched,
form.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched,
#content .container-left .map-preview form.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched,
form.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched input,
form.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched .adyen-input,
form.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched input,
form.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched .adyen-input,
#content .container-left .map-preview form.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched input,
#content .container-left .map-preview form.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched .adyen-input, .detract.ng-invalid.ng-touched .form-box input.ng-invalid.ng-touched, .detract.ng-invalid.ng-touched .form-box .ng-invalid.ng-touched.adyen-input, .detract.ng-invalid.ng-touched #content .container-left .map-preview .box input.ng-invalid.ng-touched, .detract.ng-invalid.ng-touched #content .container-left .map-preview .box .ng-invalid.ng-touched.adyen-input, #content .container-left .map-preview .detract.ng-invalid.ng-touched .box input.ng-invalid.ng-touched, #content .container-left .map-preview .detract.ng-invalid.ng-touched .box .ng-invalid.ng-touched.adyen-input,
.detract.ng-invalid.ng-touched .form-box ng-select.ng-invalid.ng-touched,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box ng-select.ng-invalid.ng-touched,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box ng-select.ng-invalid.ng-touched,
.detract.ng-invalid.ng-touched .form-box textarea.ng-invalid.ng-touched,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box textarea.ng-invalid.ng-touched,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box textarea.ng-invalid.ng-touched,
.detract.ng-invalid.ng-touched .form-box signature-pad canvas,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box signature-pad canvas,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box signature-pad canvas,
.detract.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched,
.detract.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched input,
.detract.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched .adyen-input,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched input,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched .adyen-input,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched input,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched .adyen-input {
  border-color: #F1416C;
}
form.ng-invalid.ng-touched .form-box input.ng-invalid.ng-touched ~ label.error, form.ng-invalid.ng-touched .form-box .ng-invalid.ng-touched.adyen-input ~ label.error, form.ng-invalid.ng-touched #content .container-left .map-preview .box input.ng-invalid.ng-touched ~ label.error, form.ng-invalid.ng-touched #content .container-left .map-preview .box .ng-invalid.ng-touched.adyen-input ~ label.error, #content .container-left .map-preview form.ng-invalid.ng-touched .box input.ng-invalid.ng-touched ~ label.error, #content .container-left .map-preview form.ng-invalid.ng-touched .box .ng-invalid.ng-touched.adyen-input ~ label.error,
form.ng-invalid.ng-touched .form-box ng-select.ng-invalid.ng-touched ~ label.error,
form.ng-invalid.ng-touched #content .container-left .map-preview .box ng-select.ng-invalid.ng-touched ~ label.error,
#content .container-left .map-preview form.ng-invalid.ng-touched .box ng-select.ng-invalid.ng-touched ~ label.error,
form.ng-invalid.ng-touched .form-box textarea.ng-invalid.ng-touched ~ label.error,
form.ng-invalid.ng-touched #content .container-left .map-preview .box textarea.ng-invalid.ng-touched ~ label.error,
#content .container-left .map-preview form.ng-invalid.ng-touched .box textarea.ng-invalid.ng-touched ~ label.error,
form.ng-invalid.ng-touched .form-box signature-pad canvas ~ label.error,
form.ng-invalid.ng-touched #content .container-left .map-preview .box signature-pad canvas ~ label.error,
#content .container-left .map-preview form.ng-invalid.ng-touched .box signature-pad canvas ~ label.error,
form.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched ~ label.error,
form.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched ~ label.error,
#content .container-left .map-preview form.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched ~ label.error,
form.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched input ~ label.error,
form.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched .adyen-input ~ label.error,
form.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched input ~ label.error,
form.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched .adyen-input ~ label.error,
#content .container-left .map-preview form.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched input ~ label.error,
#content .container-left .map-preview form.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched .adyen-input ~ label.error, .detract.ng-invalid.ng-touched .form-box input.ng-invalid.ng-touched ~ label.error, .detract.ng-invalid.ng-touched .form-box .ng-invalid.ng-touched.adyen-input ~ label.error, .detract.ng-invalid.ng-touched #content .container-left .map-preview .box input.ng-invalid.ng-touched ~ label.error, .detract.ng-invalid.ng-touched #content .container-left .map-preview .box .ng-invalid.ng-touched.adyen-input ~ label.error, #content .container-left .map-preview .detract.ng-invalid.ng-touched .box input.ng-invalid.ng-touched ~ label.error, #content .container-left .map-preview .detract.ng-invalid.ng-touched .box .ng-invalid.ng-touched.adyen-input ~ label.error,
.detract.ng-invalid.ng-touched .form-box ng-select.ng-invalid.ng-touched ~ label.error,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box ng-select.ng-invalid.ng-touched ~ label.error,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box ng-select.ng-invalid.ng-touched ~ label.error,
.detract.ng-invalid.ng-touched .form-box textarea.ng-invalid.ng-touched ~ label.error,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box textarea.ng-invalid.ng-touched ~ label.error,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box textarea.ng-invalid.ng-touched ~ label.error,
.detract.ng-invalid.ng-touched .form-box signature-pad canvas ~ label.error,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box signature-pad canvas ~ label.error,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box signature-pad canvas ~ label.error,
.detract.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched ~ label.error,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched ~ label.error,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched ~ label.error,
.detract.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched input ~ label.error,
.detract.ng-invalid.ng-touched .form-box international-phone-number.ng-invalid.ng-touched .adyen-input ~ label.error,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched input ~ label.error,
.detract.ng-invalid.ng-touched #content .container-left .map-preview .box international-phone-number.ng-invalid.ng-touched .adyen-input ~ label.error,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched input ~ label.error,
#content .container-left .map-preview .detract.ng-invalid.ng-touched .box international-phone-number.ng-invalid.ng-touched .adyen-input ~ label.error {
  display: block;
}

international-phone-number .flag {
  background-image: url("../images/international_phone_number/flags.jpg") !important;
}

app-pms-fellows {
  display: block;
}

/*==================
* form-box
===================*/
.form-box, #content .container-left .map-preview .box {
  padding: 24px;
  text-align: left;
  margin: 16px auto;
  background: white;
}
.form-box.no-shadow, .form-box.button.form, .ng-select.ng-select-single .form-box.ng-select-container:hover, #content .container-left .map-preview .no-shadow.box, #content .container-left .map-preview .box.button.form, #content .container-left .map-preview .ng-select.ng-select-single .box.ng-select-container:hover, .ng-select.ng-select-single #content .container-left .map-preview .box.ng-select-container:hover, section#content .top-btn-container .form-box.button, section#content .container-left .map-preview .top-btn-container .box.button, section#content .top-btn-container .container-left .map-preview .box.button, .form-box .form-box, #content .container-left .map-preview .box .form-box, .form-box #content .container-left .map-preview .box, #content .container-left .map-preview .form-box .box, #content .container-left .map-preview .box .box {
  box-shadow: none;
}
.form-box.no-shadow:not(.with-pad), .form-box.button.form:not(.with-pad), .ng-select.ng-select-single .form-box.ng-select-container:not(.with-pad):hover, #content .container-left .map-preview .no-shadow.box:not(.with-pad), #content .container-left .map-preview .box.button.form:not(.with-pad), #content .container-left .map-preview .ng-select.ng-select-single .box.ng-select-container:not(.with-pad):hover, .ng-select.ng-select-single #content .container-left .map-preview .box.ng-select-container:not(.with-pad):hover, section#content .top-btn-container .form-box.button:not(.with-pad), section#content .container-left .map-preview .top-btn-container .box.button:not(.with-pad), section#content .top-btn-container .container-left .map-preview .box.button:not(.with-pad), .form-box .form-box:not(.with-pad), #content .container-left .map-preview .box .form-box:not(.with-pad), .form-box #content .container-left .map-preview .box:not(.with-pad), #content .container-left .map-preview .form-box .box:not(.with-pad), #content .container-left .map-preview .box .box:not(.with-pad) {
  padding: 0;
}
.form-box .counter, #content .container-left .map-preview .box .counter {
  position: absolute;
  right: 24px;
  top: 53%;
}
.form-box .counter i, #content .container-left .map-preview .box .counter i {
  color: #888;
  padding: 0 0 0 3.6px;
}
.form-box form, #content .container-left .map-preview .box form {
  max-width: none !important;
}
.form-box div.menu, #content .container-left .map-preview .box div.menu {
  width: 100%;
}
.form-box .divider, #content .container-left .map-preview .box .divider {
  margin: 16px 0;
  border-top: 1px solid #e5e5e5;
}
.form-box label:not(.redesign), #content .container-left .map-preview .box label:not(.redesign) {
  color: #555;
  display: block;
  position: relative;
  margin: 0;
  padding-bottom: 8px;
  line-height: 1.33;
  padding-right: 16px;
}
.form-box label:not(.redesign).error, #content .container-left .map-preview .box label:not(.redesign).error {
  display: none;
  width: 100%;
  margin: 8px auto 0;
  padding-bottom: 0;
  color: #F1416C !important;
}
.form-box label:not(.redesign):after, #content .container-left .map-preview .box label:not(.redesign):after {
  float: right;
  height: 24px;
  font-size: 12px;
  line-height: 2;
  text-align: right;
}
.form-box label:not(.redesign).required:after, #content .container-left .map-preview .box label:not(.redesign).required:after {
  content: attr(data-after);
  color: #F1416C;
}
.form-box label:not(.redesign).required:before, #content .container-left .map-preview .box label:not(.redesign).required:before {
  position: absolute;
  top: 0;
  right: 0;
  margin: 7px 0 0 4px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: #F1416C;
  content: "";
}
.form-box label:not(.redesign).valid:after, #content .container-left .map-preview .box label:not(.redesign).valid:after, .form-box label:not(.redesign).disabled:after, #content .container-left .map-preview .box label:not(.redesign).disabled:after {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  top: 0;
  right: 0;
}
.form-box label:not(.redesign).valid:after, #content .container-left .map-preview .box label:not(.redesign).valid:after {
  content: "\f00c";
  color: #4AB58E;
}
.form-box label:not(.redesign).disabled:after, #content .container-left .map-preview .box label:not(.redesign).disabled:after {
  content: "\f023";
  color: #555;
}
.form-box label:not(.redesign) + small, #content .container-left .map-preview .box label:not(.redesign) + small {
  line-height: 1.5;
  padding: 8px 0;
  width: 100%;
}
.form-box.overlay, #content .container-left .map-preview .overlay.box {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%, -50%);
  -moz-transform: translateX(-50%, -50%);
  -ms-transform: translateX(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 450px;
  width: 100%;
}
.form-box.overlay button, #content .container-left .map-preview .overlay.box button {
  margin: 0 auto;
}
.form-box textarea, #content .container-left .map-preview .box textarea,
.form-box input:not([type=checkbox]),
.form-box .adyen-input:not([type=checkbox]),
#content .container-left .map-preview .box input:not([type=checkbox]),
#content .container-left .map-preview .box .adyen-input:not([type=checkbox]) {
  border: none;
  border-bottom: solid 2px #888;
  height: 46px;
  padding: 0;
  width: 100%;
  resize: vertical;
}
.form-box textarea[type=time]::-webkit-date-and-time-value, #content .container-left .map-preview .box textarea[type=time]::-webkit-date-and-time-value,
.form-box input:not([type=checkbox])[type=time]::-webkit-date-and-time-value,
.form-box .adyen-input:not([type=checkbox])[type=time]::-webkit-date-and-time-value,
#content .container-left .map-preview .box input:not([type=checkbox])[type=time]::-webkit-date-and-time-value,
#content .container-left .map-preview .box .adyen-input:not([type=checkbox])[type=time]::-webkit-date-and-time-value {
  text-align: left;
  color: black;
}
.form-box textarea:disabled, #content .container-left .map-preview .box textarea:disabled,
.form-box input:not([type=checkbox]):disabled,
.form-box .adyen-input:not([type=checkbox]):disabled,
#content .container-left .map-preview .box input:not([type=checkbox]):disabled,
#content .container-left .map-preview .box .adyen-input:not([type=checkbox]):disabled {
  color: #555;
  cursor: not-allowed;
  -webkit-text-fill-color: #555;
  opacity: 1;
}
.form-box textarea, #content .container-left .map-preview .box textarea {
  min-height: 90px;
}
.form-box.no-input-height textarea, #content .container-left .map-preview .no-input-height.box textarea,
.form-box.no-input-height input,
.form-box.no-input-height .adyen-input,
#content .container-left .map-preview .no-input-height.box input,
#content .container-left .map-preview .no-input-height.box .adyen-input {
  height: auto;
}
.form-box .open-dropdown, #content .container-left .map-preview .box .open-dropdown {
  padding-top: 8px;
}
.form-box .open-dropdown .radio-container, #content .container-left .map-preview .box .open-dropdown .radio-container {
  width: 100%;
  min-height: 56px;
  text-align: left;
  position: relative;
  cursor: pointer;
  margin: 8px 0;
}
.form-box .open-dropdown .radio-container input, .form-box .open-dropdown .radio-container .adyen-input, #content .container-left .map-preview .box .open-dropdown .radio-container input, #content .container-left .map-preview .box .open-dropdown .radio-container .adyen-input {
  position: absolute;
  border: none;
  cursor: pointer;
}
.form-box .open-dropdown .radio-container input.custom-radio, .form-box .open-dropdown .radio-container .custom-radio.adyen-input, #content .container-left .map-preview .box .open-dropdown .radio-container input.custom-radio, #content .container-left .map-preview .box .open-dropdown .radio-container .custom-radio.adyen-input {
  height: 100%;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: none;
  padding: 0;
}
.form-box .open-dropdown .radio-container input.custom-radio:disabled, .form-box .open-dropdown .radio-container .custom-radio.adyen-input:disabled, #content .container-left .map-preview .box .open-dropdown .radio-container input.custom-radio:disabled, #content .container-left .map-preview .box .open-dropdown .radio-container .custom-radio.adyen-input:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.form-box .open-dropdown .radio-container label:not(.error), #content .container-left .map-preview .box .open-dropdown .radio-container label:not(.error) {
  padding: 16px;
  border-radius: 4px;
  vertical-align: middle;
  cursor: pointer;
}
.form-box .open-dropdown .radio-container label.error, #content .container-left .map-preview .box .open-dropdown .radio-container label.error {
  background: white;
  width: auto;
  padding: 8px 0 0 0 !important;
}
.form-box .date-button.selected, #content .container-left .map-preview .box .date-button.selected {
  font-weight: bold;
}

.modal-box .form-box, .modal-box #content .container-left .map-preview .box, #content .container-left .map-preview .modal-box .box {
  box-shadow: none;
  margin: 0 auto;
}

@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 890px) and (max-width: 1060px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #container.wizard .form-box, #container.cico footer.wizard .form-box, #container.wizard #content .container-left .map-preview .box, #content .container-left .map-preview #container.wizard .box, #container.cico footer.wizard #content .container-left .map-preview .box, #content .container-left .map-preview #container.cico footer.wizard .box {
    padding: 16px;
    margin: 8px 0 8px 0 !important;
    width: 100%;
  }
  #container.wizard input:not(.custom-radio, .redesign), #container.wizard .adyen-input:not(.custom-radio, .redesign), #container.cico footer.wizard input:not(.custom-radio, .redesign), #container.cico footer.wizard .adyen-input:not(.custom-radio, .redesign) {
    height: 30px;
  }
  #container.wizard .radio-container, #container.cico footer.wizard .radio-container {
    min-height: 35px;
  }
  #container.wizard .radio-container label, #container.cico footer.wizard .radio-container label {
    padding: 12px;
  }
  #container.wizard international-phone-number button, #container.cico footer.wizard international-phone-number button {
    height: 30px;
  }
}

div.checkbox + div.checkbox {
  margin-top: 16px;
}

input.checkbox, .checkbox.adyen-input {
  position: absolute;
  width: 24px;
  height: 24px !important;
  padding: 0;
  margin: 0;
  border: solid 2px;
  border-radius: 1px;
  transition: all 0.15s ease-out 0s;
  display: inline-block;
  *overflow: hidden;
}
input.checkbox:checked::before, .checkbox.adyen-input:checked::before {
  position: absolute;
  content: "✓";
  top: 0;
  left: 0;
  display: inline-block;
  line-height: 21px;
  font-size: 20px;
  padding-left: 3px;
}
input.checkbox:checked::after, .checkbox.adyen-input:checked::after {
  content: "";
  display: block;
  position: relative;
  z-index: 0;
}
input.checkbox + label, .checkbox.adyen-input + label {
  display: block;
  padding-left: 40px;
  cursor: pointer;
}

.half > div:first-child {
  margin-right: 8px;
}
.half > div:last-child {
  margin-left: 8px;
}
.half > div:only-child {
  margin-left: 0;
  margin-right: 0;
}

app-form-send-cancel.kiosk {
  display: none;
}
@media only screen and (max-width: 600px) {
  app-form-send-cancel.kiosk {
    display: block;
  }
}

.caption-text, .form-box label:not(.redesign) + small, #content .container-left .map-preview .box label:not(.redesign) + small {
  color: #777;
  margin-top: -13px;
  margin-bottom: 13px;
  display: block;
  font-size: 13px;
}

input, .adyen-input,
textarea {
  font-family: "Open Sans", sans-serif !important;
  margin: 0;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  outline: none !important;
}
input.preview_input, .preview_input.adyen-input,
textarea.preview_input {
  display: inline-block;
  width: calc(94% - 80px);
  min-width: calc(94% - 80px);
  float: right;
  opacity: 0.6;
  cursor: not-allowed;
}
input:-webkit-autofill, .adyen-input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset;
}
input ~ label.error, .adyen-input ~ label.error,
textarea ~ label.error {
  display: none;
}
input:focus, .adyen-input:focus,
textarea:focus {
  outline: none !important;
}

::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
  color: #888;
  opacity: 1;
}

.ng-select.ng-select-single .ng-select-container {
  font-family: "Open Sans", sans-serif !important;
  border: none;
  border-bottom: solid 2px #888 !important;
  background-color: transparent;
  height: 48px;
  color: black;
  cursor: pointer;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  padding-left: 0;
  margin-right: 5px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  max-width: calc(100vw - 47px - (2 * 24px) - 32px);
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder {
  color: #888 !important;
}
.ng-select.ng-select-single .ng-option {
  border-bottom: 1px solid rgba(124, 124, 124, 0.21);
}
.ng-select.ng-select-single .ng-option.ng-option-child {
  padding-left: 26px;
}
.ng-select.ng-select-single .ng-option:last-child {
  border-bottom: none;
}
.ng-select.ng-select-single .ng-option-disabled.ng-optgroup {
  border-bottom: 1px solid rgba(124, 124, 124, 0.1);
}
.ng-select.ng-select-single .form-input {
  margin: 0;
}
.ng-select.ng-select-single .ng-input {
  padding-left: 0 !important;
  top: 0 !important;
}
.ng-select.ng-select-single.ng-select-focused.ng-select-opened .ng-value {
  color: #e5e5e5;
}
.ng-select.ng-select-single .ng-clear {
  vertical-align: bottom;
}
.ng-select.ng-select-single + label.error {
  display: none;
}
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #F1416C !important;
}
@media only screen and (max-height: 300px) {
  .ng-select .ng-dropdown-panel {
    z-index: 5001 !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
  text-overflow: clip !important;
  overflow: visible !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  font-style: italic;
  background-color: #d2d2d2;
}

app-form-send-cancel.kiosk {
  display: none;
}
@media only screen and (max-width: 600px) {
  app-form-send-cancel.kiosk {
    display: block;
  }
}

.uploader label {
  padding: 16px;
  text-align: center;
  border-radius: 2px;
  border-bottom: 2px solid #888;
  transition: all 0.2s ease;
  user-select: none;
}
.uploader label span {
  display: block;
  padding: 40px;
  cursor: pointer;
}
.uploader #file-image {
  display: inline;
  margin: 0 auto;
  width: auto;
  height: auto;
  max-height: 300px;
}
.uploader input[type=file], .uploader [type=file].adyen-input {
  display: none;
}
.uploader input.ng-invalid.ng-touched + input + label, .uploader .ng-invalid.ng-touched.adyen-input + input + label, .uploader input.ng-invalid.ng-touched + .adyen-input + label, .uploader .ng-invalid.ng-touched.adyen-input + .adyen-input + label, .uploader input.ng-invalid.ng-touched + label, .uploader .ng-invalid.ng-touched.adyen-input + label {
  border-bottom-color: #F1416C;
}
.uploader .bottom {
  margin-top: 16px;
  text-align: right;
}
.uploader .bottom a {
  text-decoration: none !important;
}
.uploader .bottom a.first-child {
  color: #272b36 !important;
  float: left !important;
}
.uploader .bottom a:only-child, .uploader .bottom a:last-child {
  color: #bdbdbd !important;
  float: right !important;
}
.uploader .bottom div {
  display: inline-block;
}
.uploader .bottom div:not(:last-child), .uploader .bottom div:not(:only-child) {
  margin-right: 10px;
}

international-phone-number .dropdown {
  width: 58px;
}
international-phone-number .dropbtn {
  background: none !important;
  border: none !important;
  border-bottom: solid 2px #888 !important;
  border-radius: 1px;
  height: 46px;
  width: 100%;
}
international-phone-number .dropbtn button {
  vertical-align: bottom;
  margin-right: 0 !important;
}
international-phone-number .dropbtn button .defaultCountry {
  width: 16px;
}
international-phone-number + label.error {
  display: none;
}
international-phone-number .flag {
  margin-right: 4px;
}
international-phone-number input, international-phone-number .adyen-input {
  width: calc(100% - 58px) !important;
  vertical-align: bottom;
}
international-phone-number .dropdown-content {
  border: 1px solid #d2d2d2;
  border-top: none;
  z-index: 1;
}
international-phone-number .list-group-item {
  border-bottom: 1px solid rgba(124, 124, 124, 0.21);
  padding: 7px 16px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}
international-phone-number.ng-touched.ng-invalid .dropbtn {
  border-bottom-color: #F1416C !important;
}

/*===================
* Buttons
====================*/
button {
  font-family: inherit;
}

.button {
  background: #444;
  color: white;
  padding: 16px 26px;
  display: block;
  position: relative;
  text-align: center;
  border: none;
}
.button.disabled, .button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
.button.near {
  margin-top: 0;
}
.button.i-block {
  display: inline-block;
}
.button.inline-d {
  display: inline;
}
.button.wide {
  width: 100%;
}
.button.form {
  margin: 0;
  text-align: left;
  border: 1px solid #d2d2d2;
  font-weight: 400 !important;
  text-decoration: none !important;
}
.button.green {
  background: #4AB58E;
  color: white !important;
}
.button.green:active, .button.green:hover {
  background: #2BA579;
}
.button.red {
  background: #F1416C;
  color: white !important;
}
.button.red:active, .button.red:hover {
  background: #D9214E;
}
.button.light {
  background: #ebebec;
  color: #666 !important;
}
.button.light:active, .button.light:hover {
  background: #f9f9f9;
  color: black !important;
}
.button.grey {
  background: #f9f9f9;
  color: black !important;
}
.button.ghost {
  background: white;
  color: #888 !important;
  box-shadow: none;
}
.button.ghost:active, .button.ghost:hover {
  background: #f9f9f9;
}
.button.action {
  text-align: left;
  background: white;
  color: #555;
  box-shadow: none;
  padding: 16px 10px;
  width: 100%;
}
.button.action:active, .button.action:hover {
  background: #f9f9f9;
}
.button.action i {
  margin-right: 16px;
}
.button.top-btn {
  text-decoration: none;
}

.button-hollow-small {
  height: 18px;
  line-height: 18px;
  padding: 5px 12px;
  border: 1px solid;
  box-sizing: content-box;
  font-weight: normal !important;
  text-decoration: none !important;
}
.button-hollow-small:hover, .button-hollow-small:active {
  background: rgba(0, 0, 0, 0.14);
}

div.button-grid {
  padding: 14px 0;
  cursor: auto;
  text-align: center;
}
div.button-grid:focus {
  outline: none;
}
div.button-grid .button {
  width: 47%;
  float: left;
  margin: 0 auto;
  padding: 16px 0;
  text-align: center;
  display: inline-block;
}
div.button-grid .button:first-child {
  margin-right: 6%;
}
div.button-grid .button:only-child {
  float: none;
  margin: 0 auto;
}
div.button-grid.stacked .button {
  display: block;
  float: none;
  width: 90%;
  margin: 8px auto;
}

.button-shadow, .button {
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.06);
}

.button-group.dropdown {
  margin: auto;
}
.button-group.dropdown .button {
  width: calc(100% - 110px);
}
.button-group.dropdown .button.dropdown {
  margin-left: -4px;
  border-left: none;
  width: 55px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.button-group .button {
  display: inline-block;
}

.dropdown-menu {
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}
.dropdown-menu.show {
  display: table !important;
}
.dropdown-menu a:first-of-type {
  border-top: none;
}

.adyen-input {
  padding: 0 16px;
  background: #f9f9f9 !important;
  height: 59px;
}

.adyen-checkout__payment-methods-list {
  padding-left: 0 !important;
}

app-datepicker {
  position: relative;
}
app-datepicker span :not(.redesign) {
  padding: 0 8px 2px 8px;
  font-size: 14px;
  color: #555;
}
app-datepicker span :not(.redesign):first-of-type {
  padding-left: 0;
}
app-datepicker input#date_datepicker, app-datepicker #date_datepicker.adyen-input {
  cursor: pointer;
}
app-datepicker input#date_datepicker:disabled, app-datepicker #date_datepicker.adyen-input:disabled, app-datepicker input#date_datepicker:read-only, app-datepicker #date_datepicker.adyen-input:read-only, app-datepicker input#date_datepicker.disabled, app-datepicker #date_datepicker.disabled.adyen-input, app-datepicker input#date_datepicker.read-only, app-datepicker #date_datepicker.read-only.adyen-input {
  cursor: not-allowed;
  -webkit-text-fill-color: #555;
  opacity: 1;
}
app-datepicker input#date_datepicker.ng-invalid.ng-touched, app-datepicker #date_datepicker.ng-invalid.ng-touched.adyen-input {
  border-color: #F1416C;
}
app-datepicker input#text_datepicker, app-datepicker #text_datepicker.adyen-input {
  cursor: text;
}
app-datepicker input#text_datepicker:disabled, app-datepicker #text_datepicker.adyen-input:disabled, app-datepicker input#text_datepicker:read-only, app-datepicker #text_datepicker.adyen-input:read-only, app-datepicker input#text_datepicker.disabled, app-datepicker #text_datepicker.disabled.adyen-input, app-datepicker input#text_datepicker.read-only, app-datepicker #text_datepicker.read-only.adyen-input {
  cursor: not-allowed;
  -webkit-text-fill-color: #555;
  opacity: 1;
}
app-datepicker input#text_datepicker.ng-invalid.ng-touched, app-datepicker #text_datepicker.ng-invalid.ng-touched.adyen-input {
  border-color: #F1416C;
}
app-datepicker input#mobile_datepicker::-webkit-date-and-time-value, app-datepicker #mobile_datepicker.adyen-input::-webkit-date-and-time-value {
  text-align: left;
}
app-datepicker input[type=text], app-datepicker [type=text].adyen-input {
  color: #555 !important;
}
app-datepicker input[type=date]::before, app-datepicker [type=date].adyen-input::before {
  color: #555 !important;
  content: attr(placeholder);
}
app-datepicker input[type=date], app-datepicker [type=date].adyen-input {
  color: #ffffff;
}
app-datepicker input[type=date]:focus, app-datepicker [type=date].adyen-input:focus, app-datepicker input[type=date]:valid, app-datepicker [type=date].adyen-input:valid {
  color: #555;
}
app-datepicker input[type=date]:focus::before, app-datepicker [type=date].adyen-input:focus::before, app-datepicker input[type=date]:valid::before, app-datepicker [type=date].adyen-input:valid::before {
  content: "" !important;
}
app-datepicker .mat-input-element:disabled {
  -webkit-text-fill-color: #555;
  opacity: 1;
  cursor: not-allowed;
}
app-datepicker .mat-datepicker-toggle {
  position: absolute;
  top: -9px;
  right: 8px;
  width: 100%;
}
app-datepicker .mat-form-field-infix {
  padding: 0;
  border: 0;
}
app-datepicker .mat-form-field-wrapper {
  padding: 0;
}
app-datepicker .mat-form-field-infix {
  width: 100%;
  position: absolute;
  padding-top: 0.3em;
}
app-datepicker .mat-form-field-suffix {
  width: 100%;
}
app-datepicker .mat-button-base {
  position: absolute;
  right: -16px;
}
app-datepicker .mat-button-base svg {
  width: 18px;
  height: 18px;
  color: black;
}
app-datepicker .mat-button-ripple {
  display: none;
}
app-datepicker .mat-button-focus-overlay {
  display: none;
}
app-datepicker .mat-icon-button {
  background-color: transparent;
  border-radius: 0;
}
app-datepicker .mat-icon-button:disabled {
  cursor: not-allowed;
}

.mat-calendar-body-cell-content {
  border-radius: 5px !important;
}

/* Swiper */
.slider .slider-content, app-swiper .slider-content {
  display: block;
}
.slider .swiper-button-next, .slider .swiper-button-prev, app-swiper .swiper-button-next, app-swiper .swiper-button-prev {
  background-image: none !important;
  position: absolute;
  vertical-align: middle;
  margin: 0 !important;
  padding: 0 !important;
  top: 0 !important;
}
.slider .swiper-button-next:not(.video), .slider .swiper-button-prev:not(.video), app-swiper .swiper-button-next:not(.video), app-swiper .swiper-button-prev:not(.video) {
  height: 100%;
}
.slider .swiper-button-next.video, .slider .swiper-button-prev.video, app-swiper .swiper-button-next.video, app-swiper .swiper-button-prev.video {
  top: 17px !important;
}
.slider .swiper-button-next::before, .slider .swiper-button-prev::before, app-swiper .swiper-button-next::before, app-swiper .swiper-button-prev::before {
  color: white;
  display: block;
  text-align: center;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-top: -4px;
}
.slider .swiper-button-next::after, .slider .swiper-button-prev::after, app-swiper .swiper-button-next::after, app-swiper .swiper-button-prev::after {
  display: none;
}
.slider .swiper-button-next, app-swiper .swiper-button-next {
  right: 0 !important;
}
.slider .swiper-button-next::before, app-swiper .swiper-button-next::before {
  content: "›";
}
.slider .swiper-button-prev, app-swiper .swiper-button-prev {
  left: 0 !important;
}
.slider .swiper-button-prev::before, app-swiper .swiper-button-prev::before {
  content: "‹";
}

app-swiper .swiper-button-next::before, app-swiper .swiper-button-prev::before {
  position: absolute;
  top: 50%;
  height: 52px;
  width: 26px;
  margin: -39px 0 0 0;
  padding-top: 8px;
  font-size: 28px;
  opacity: 0.6;
  -moz-box-shadow: inset 50px 50px 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 50px 50px 0 rgba(0, 0, 0, 0);
  box-shadow: inset 50px 50px 0 rgba(0, 0, 0, 0);
}
app-swiper:hover .swiper-button-next::before, app-swiper:hover .swiper-button-prev::before {
  opacity: 1;
  -moz-box-shadow: inset 50px 50px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 50px 50px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 50px 50px 0 rgba(0, 0, 0, 0.2);
}
app-swiper .swiper-slide {
  height: 100%;
  background-position: center;
  background-size: cover;
}
app-swiper .swiper-slide.center {
  text-align: center;
}
app-swiper img {
  height: 100%;
  width: auto;
}
app-swiper video {
  width: 100%;
  height: auto;
}

#swiperLightbox .column img {
  cursor: pointer;
}
#swiperLightbox .close {
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  z-index: 1;
}
#swiperLightbox .close, #swiperLightbox .prev, #swiperLightbox .next {
  border-radius: 5px;
}

.a2hs-bar {
  position: relative;
  z-index: 5004;
  background: white;
  padding: 24px 42px 18px 42px;
  line-height: 1.1;
  border-radius: 4px;
  border: 1px 0 1px 0 solid #f9f9f9;
  box-sizing: border-box;
}
.a2hs-bar .inner {
  position: relative;
  text-align: center;
}
.a2hs-bar .inner h3 {
  line-height: 1.3;
  margin: 0 0 25px 0;
  font-weight: bold;
  font-size: 20px;
}
.a2hs-bar .inner ul {
  display: inline-block;
  margin: 4px 0 0 0;
  padding-left: 0;
  padding-bottom: 2px;
}
.a2hs-bar .inner ul li {
  text-align: left;
  list-style: none;
  padding: 8px 0;
}
.a2hs-bar .inner ul li:last-child {
  padding-bottom: 0;
}
.a2hs-bar .inner ul li div {
  display: inline-block;
  vertical-align: middle;
}
.a2hs-bar .inner ul li div:first-child {
  width: 30px;
  text-align: center;
}
.a2hs-bar .inner ul li div:last-child {
  max-width: calc(100% - 30px);
}
.a2hs-bar .inner ul li div.opera-overflow {
  max-width: 110px;
  width: 110px;
}
.a2hs-bar .inner ul li div span.fa-stack {
  margin: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.a2hs-bar .inner ul li div span.fa-stack .fa-ellipsis-vertical {
  margin: 8px;
}
.a2hs-bar .inner ul li div span.fa-stack .fa-rotate-45 {
  transform: rotate(45deg);
}
.a2hs-bar .inner ul li div span.fa-stack .fa-mobile-screen {
  font-size: 18px;
}
.a2hs-bar .inner ul li div span.fa-stack .fa-down-long {
  font-size: 15px;
  left: 4px;
  bottom: 2px;
}
.a2hs-bar .inner ul li div span.fa-stack .fa-up-long {
  font-size: 15px;
  left: -2px;
}
.a2hs-bar .inner ul li div span.fa-stack .fa-square {
  font-size: 15px;
}
.a2hs-bar .inner ul li div span.fa-stack .fa-arrow-up-long {
  font-size: 13px;
  bottom: 6px;
}
.a2hs-bar.chrome .inner button, .a2hs-bar.edge .inner button {
  display: block;
  margin: 5px auto 0 auto;
  padding: 12px 60px;
}
.a2hs-bar.safari, .a2hs-bar.opera, .a2hs-bar.firefox, .a2hs-bar.samsung-internet, .a2hs-bar.firefox.android, .a2hs-baropera.android, .a2hs-bar.samsung-internet.android {
  padding: 24px 42px 18px 42px;
}
.a2hs-bar.safari .inner div, .a2hs-bar.opera .inner div, .a2hs-bar.firefox .inner div, .a2hs-bar.samsung-internet .inner div, .a2hs-bar.firefox.android .inner div, .a2hs-baropera.android .inner div, .a2hs-bar.samsung-internet.android .inner div {
  padding-right: 0;
}
.a2hs-bar.safari .inner h3, .a2hs-bar.opera .inner h3, .a2hs-bar.firefox .inner h3, .a2hs-bar.samsung-internet .inner h3, .a2hs-bar.firefox.android .inner h3, .a2hs-baropera.android .inner h3, .a2hs-bar.samsung-internet.android .inner h3 {
  font-size: 18px;
}
.a2hs-bar.safari .inner .button.ghost, .a2hs-bar.opera .inner .button.ghost, .a2hs-bar.firefox .inner .button.ghost, .a2hs-bar.samsung-internet .inner .button.ghost, .a2hs-bar.firefox.android .inner .button.ghost, .a2hs-baropera.android .inner .button.ghost, .a2hs-bar.samsung-internet.android .inner .button.ghost {
  margin: 24px auto auto auto;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .a2hs-bar.widget.safari, .a2hs-bar.widget.opera, .a2hs-bar.widget.samsung-internet, .a2hs-bar.widget.firefox.android, .a2hs-bar.widget.firefox {
    padding: 3px 18px;
  }
}

app-widget-a2hs .a2hs-bar {
  position: relative;
  z-index: 1;
  min-width: auto;
  left: 0;
  bottom: 0;
  background: none;
  box-shadow: none;
  padding: 0;
  border: none;
  border-radius: 5px;
}
@media only screen and (max-width: 600px) {
  app-widget-a2hs .a2hs-bar {
    width: auto;
  }
}
app-widget-a2hs .a2hs-bar.chrome .inner h4, app-widget-a2hs .a2hs-bar.chrome .inner .mylike-widget-container h3, .mylike-widget-container app-widget-a2hs .a2hs-bar.chrome .inner h3, app-widget-a2hs .a2hs-bar.edge .inner h4, app-widget-a2hs .a2hs-bar.edge .inner .mylike-widget-container h3, .mylike-widget-container app-widget-a2hs .a2hs-bar.edge .inner h3 {
  margin: 5px 0 10px 0;
  font-weight: 400;
}
app-widget-a2hs .a2hs-bar.chrome .inner button, app-widget-a2hs .a2hs-bar.edge .inner button {
  padding: 12px 62px;
  margin: 5px auto 5px auto;
  font-weight: 400 !important;
  border-radius: 5px;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  app-widget-a2hs .a2hs-bar.safari .inner h4, app-widget-a2hs .a2hs-bar.safari .inner .mylike-widget-container h3, .mylike-widget-container app-widget-a2hs .a2hs-bar.safari .inner h3, app-widget-a2hs .a2hs-bar.opera .inner h4, app-widget-a2hs .a2hs-bar.opera .inner .mylike-widget-container h3, .mylike-widget-container app-widget-a2hs .a2hs-bar.opera .inner h3, app-widget-a2hs .a2hs-bar.samsung-internet .inner h4, app-widget-a2hs .a2hs-bar.samsung-internet .inner .mylike-widget-container h3, .mylike-widget-container app-widget-a2hs .a2hs-bar.samsung-internet .inner h3, app-widget-a2hs .a2hs-bar.firefox.android .inner h4, app-widget-a2hs .a2hs-bar.firefox .inner h4, app-widget-a2hs .a2hs-bar.firefox .inner .mylike-widget-container h3, .mylike-widget-container app-widget-a2hs .a2hs-bar.firefox .inner h3 {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }
}

.toggleSlider {
  height: 0 !important;
  width: 0 !important;
  visibility: hidden;
}
.toggleSlider:checked + .toggler:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}
.toggleSlider:not(:checked) + .toggler .on {
  display: none;
}
.toggleSlider:checked + .toggler .on {
  display: block;
}
.toggleSlider:checked + .toggler .off {
  display: none;
}
.toggleSlider:disabled + .toggler {
  background: #e5e5e5;
}

.toggler {
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: #d2d2d2;
  display: block;
  border-radius: 40px;
  position: relative;
  margin-bottom: 15px;
  float: right;
}
.toggler.left {
  float: left;
}
.toggler.opener {
  margin-bottom: 35px;
}
.toggler .description {
  margin: 0 0 0 73px;
  display: inline-block;
  width: calc(100vw - 103px);
  max-width: 727px;
}
.toggler .on, .toggler .off {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 14px;
  font-weight: bold;
}
.toggler .on {
  left: 30%;
}
.toggler .off {
  color: #555;
  right: 10%;
}
.toggler + small {
  position: absolute;
  margin: -14px 0 0 73px;
}
.toggler:active:after {
  width: 30px;
}
.toggler:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 30px;
  transition: 0.3s;
}

.overlay-container.curtain {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.14);
}

.toast-container .ngx-toastr {
  width: auto !important;
  min-width: 300px;
  max-width: 500px;
  top: 16px;
}
@media only screen and (max-width: 600px) {
  .toast-container .ngx-toastr {
    top: 64px;
  }
}
.toast-container .toast-error {
  color: #272b36 !important;
  background-color: #fbeae5;
}
.toast-container .toast-success {
  color: #272b36 !important;
  background-color: #e3f1df;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(33,43,53)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E") !important;
}
.toast-container .toastr-button {
  display: table;
  margin-top: 6px;
}

body.no-sidebar .ds-wrapper {
  --base-margin-right: calc(300px - 260px) ;
}
body.no-sidebar #web-messenger-container {
  right: calc(300px - 260px) !important;
}

.ds-wrapper {
  z-index: 5003 !important;
  --base-margin-right: 300px;
}
.ds-wrapper .ds-footer {
  height: 0;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (max-width: 600px), only screen and (max-width: 890px) {
  .ds-wrapper {
    --base-margin-right: calc(300px - 260px);
  }
}
@media only screen and (min-width: 3000px), only screen and (min-width: 1321px), only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 890px) and (max-width: 1060px), only screen and (min-width: 600px ) and (max-width: 890px) {
  .ds-wrapper .ds-chat {
    max-height: calc(100vh - 200px) !important;
  }
}
.ds-wrapper.elevated {
  --base-margin-bottom: 85px;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  .ds-wrapper.elevated-mobile {
    --base-margin-bottom-mobile: 10px;
  }
}
.ds-wrapper .ds-whatsapp-button {
  display: none !important;
}
.ds-wrapper.ds-wrapper--pr::after {
  background: none !important;
}

#web-messenger-container {
  z-index: 1999 !important;
  right: 280px !important;
}
@media only screen and (min-width: 890px) and (max-width: 1320px), only screen and (max-width: 600px), only screen and (max-width: 890px) {
  #web-messenger-container {
    right: calc(280px - 260px) !important;
  }
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  #web-messenger-container {
    max-height: calc(100vh - 64px);
  }
}
#web-messenger-container.elevated {
  bottom: 85px;
}
@media only screen and (max-width: 600px), only screen and (min-width: 600px ) and (max-width: 890px) {
  #web-messenger-container.elevated-mobile {
    bottom: 80px;
  }
}

.mylike-widget-container {
  list-style: none !important;
  padding-left: 0 !important;
}
.mylike-widget-container ul {
  list-style: none !important;
  margin-left: 0 !important;
  padding: 0 !important;
}
.mylike-widget-container .like-info-container {
  padding: 16px;
}
.mylike-widget-container h3 {
  font-family: unset !important;
  margin: 0 !important;
}
.mylike-widget-container a {
  font-size: 15px !important;
}
.mylike-widget-container .mylike-widget-like-detail h3 {
  padding-top: 16px;
}
.mylike-widget-container .mylike-widget-menu .filter-list {
  margin-top: 50px !important;
  max-height: calc(100% - 100px) !important;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .mylike-widget-container {
    margin-top: 21px;
  }
  .mylike-widget-container .mylike-widget-map, .mylike-widget-container .mylike-widget-menu, .mylike-widget-container .mylike-widget-like-detail {
    height: calc(100vh - 300px) !important;
  }
}
@media only screen and (min-width: 890px) and (max-width: 1060px), only screen and (min-width: 890px) and (max-width: 1320px), only screen and (min-width: 1321px), only screen and (min-width: 3000px) {
  .mylike-widget-container .mylike-widget-list, .mylike-widget-container .mylike-widget-map, .mylike-widget-container .mylike-widget-menu, .mylike-widget-container .mylike-widget-like-detail {
    height: calc(100vh - 250px) !important;
  }
}

/*==========================
* Responsive Design
===========================*/
/*===================
* Global Dimensions
====================*/
/*===================
* Menus
====================*/
/*===================
* Colors
====================*/
.button-radius, .alert, section#content .top-btn-container .button, #container.overviews section#content .overview-text-container .overview-button, .add-btn div, section.popup-ui div.close-icon, #container.cico footer.overviews section#content .overview-text-container .overview-button, input,
textarea, .ng-select.ng-select-single .ng-select-container, .button, .button.form, .button-hollow-small, .adyen-input {
  border-radius: 2px;
}

@keyframes fadeIn {
  from {
    transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transition-property: all;
  }
  to {
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes swipe_left {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes swipe_right {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/** flex mixins **/
/** gradient mixins **/
/** helper **/
.disable-styling-input, select, div.searchbox input[type=search], input,
textarea, .ng-select.ng-select-single .ng-select-container, .button.form, div.searchbox [type=search].adyen-input, .adyen-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.no-events, .disabled_state, section#navigation app-menu nav ul li a.disabled, #container.overviews section#content .overview-text-container .overview-button.disabled, nav.element-navigation .navigation-container .nav-btn a.disabled, .select-frame i, #container.cico footer.overviews section#content .overview-text-container .overview-button.disabled {
  pointer-events: none;
}

.no-shadow, section#content .top-btn-container .button, .ng-select.ng-select-single .ng-select-container:hover, .button.form {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.shadow, .form-box, #content .container-left .map-preview .box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 0 4px 0 rgba(0, 0, 0, 0.06);
}

.ellipsis, section#header .icon, section#navigation app-menu nav ul li a, section#navigation app-widget-push a, app-goldkey-parent .magazine .name, app-widget-group-business span, app-widget-advert span, app-widget-group-business span:first-of-type span, app-widget-advert span:first-of-type span, section#sidebar .side-button, section#content .top-btn-container .button, ul.icon-list, div.tiles a.tile, div.menu a small, div.menu a > div, #news-widget div.news-container .swiper-slide .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clearfix:after, #content .container-left .guest-list:after, .uploader .bottom:after, div.button-grid:after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  font-size: 0;
  height: 0;
}

.scrollbar, .modal-box {
  scrollbar-width: thin;
}
.scrollbar::-webkit-scrollbar-track, .modal-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f2f2f2;
}
.scrollbar::-webkit-scrollbar-thumb, .modal-box::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
}
.scrollbar::-webkit-scrollbar, .modal-box::-webkit-scrollbar {
  display: block;
  width: 16px;
  margin: 0 48px;
}

.animation-in, .iframe_container, .page-info {
  -webkit-animation: fade 0.3s ease-in;
  -moz-animation: fade 0.3s ease-in;
  -o-animation: fade 0.3s ease-in;
  animation: fade 0.3s ease-in;
}

.animation-out {
  -webkit-animation: fade 0.3s ease-out;
  -moz-animation: fade 0.3s ease-out;
  -o-animation: fade 0.3s ease-out;
  animation: fade 0.3s ease-out;
}

.font-navigation, section#navigation {
  font-size: 15px;
}

.font-overview-big, #container.overviews section#content .overview-text-container em, #container.cico footer.overviews section#content .overview-text-container em {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -1px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .font-overview-big, #container.overviews section#content .overview-text-container em, #container.cico footer.overviews section#content .overview-text-container em {
    font-size: 22px;
  }
}

.font-button, section#content .top-btn-container .button, #container.overviews section#content .overview-text-container .overview-button, nav.element-navigation .navigation-container .nav-btn a, #container.cico footer.overviews section#content .overview-text-container .overview-button, .button {
  font-size: 17px !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  color: white;
}
@media only screen and (max-width: 600px) {
  .font-button, section#content .top-btn-container .button, #container.overviews section#content .overview-text-container .overview-button, nav.element-navigation .navigation-container .nav-btn a, #container.cico footer.overviews section#content .overview-text-container .overview-button, .button {
    font-size: 14px !important;
  }
}

.font-headerfooter, section#header .icon, section#footer .icon-button {
  font-size: 13px;
  font-weight: 600;
}

.font-widget-text, body#bright section#sidebar app-widget-suggestions div.menu a div:first-of-type, #weatherwidget div.weatherblock p, section#sidebar .title, .icon-menu div.menu a div:first-of-type {
  font-size: 14px;
}

.font-widgetbutton, .button-hollow-small {
  font-size: 13px !important;
  font-weight: 700;
}

.font-widget-temperature, #weatherwidget div.weatherblock h2 {
  font-size: 27px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .font-widget-temperature, #weatherwidget div.weatherblock h2 {
    font-size: 25px;
  }
}

.font-widget-temperature-current {
  font-size: 40px;
}
@media only screen and (min-width: 600px ) and (max-width: 890px), only screen and (max-width: 600px) {
  .font-widget-temperature-current {
    font-size: 25px;
  }
}

.font-subtext {
  font-size: 11px;
}

.font-news-container, #news-widget div.news-container .swiper-slide {
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .font-news-container, #news-widget div.news-container .swiper-slide {
    font-size: 14px;
  }
}

.font-page-headline, section#content .page-headline h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.66em;
}
@media only screen and (max-width: 600px) {
  .font-page-headline, section#content .page-headline h1 {
    font-size: 26px;
  }
}

.font-sub-headline, section#content h4, section#content #map .gm-style-iw h1, div.menu .head, .form-box label:not(.redesign), #content .container-left .map-preview .box label:not(.redesign), section#content .mylike-widget-container h3, .mylike-widget-container section#content h3 {
  font-size: 16px;
  font-weight: 700;
}

.font-article-name, section#content .page-headline h2, section#content h3, section.popup-ui div.page-headline h2 {
  font-size: 22px;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .font-article-name, section#content .page-headline h2, section#content h3, section.popup-ui div.page-headline h2 {
    font-size: 18px;
  }
}

.font-article-price {
  font-size: 32px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .font-article-price {
    font-size: 24px;
  }
}

.font-wizard-modal-button {
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .font-wizard-modal-button {
    font-size: 18px;
  }
}

.font-list, div.menu a > div {
  font-weight: normal;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .font-list, div.menu a > div {
    font-size: 14px;
  }
}

.font-text, body, section#content p, section#content table, section#content a:not(.tile, .redesign), #content .container-right .basket_mini .b-mini-total p, section.popup-ui div.content .popup-text, section.popup-ui div.content p, section.popup-ui div.content a,
.font-input,
input,
textarea,
.ng-select.ng-select-single .ng-select-container,
.button.form,
.adyen-input {
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
}
@media only screen and (max-width: 600px) {
  .font-text, body, section#content p, section#content table, section#content a:not(.tile, .redesign), #content .container-right .basket_mini .b-mini-total p, section.popup-ui div.content .popup-text, section.popup-ui div.content p, section.popup-ui div.content a,
.font-input,
input,
textarea,
.ng-select.ng-select-single .ng-select-container,
.button.form,
.adyen-input {
    font-size: 14px;
  }
}
@media only screen and (min-width: 3000px) {
  .font-text, body, section#content p, section#content table, section#content a:not(.tile, .redesign), #content .container-right .basket_mini .b-mini-total p, section.popup-ui div.content .popup-text, section.popup-ui div.content p, section.popup-ui div.content a,
.font-input,
input,
textarea,
.ng-select.ng-select-single .ng-select-container,
.button.form,
.adyen-input {
    font-size: 17px;
  }
}

.font-error, .form-box label:not(.redesign).error, #content .container-left .map-preview .box label:not(.redesign).error {
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
}
@media only screen and (max-width: 600px) {
  .font-error, .form-box label:not(.redesign).error, #content .container-left .map-preview .box label:not(.redesign).error {
    font-size: 14px;
  }
}

.font-tiles, div.tiles a.tile {
  font-size: 17px;
  font-weight: 400 !important;
  text-decoration: none !important;
  color: white;
}

.font-text-big, #content em, #content em a, #content .container-right .basket_mini .b-mini-total h5, section.popup-ui div.content em {
  font-size: 19px;
}
@media only screen and (max-width: 600px) {
  .font-text-big, #content em, #content em a, #content .container-right .basket_mini .b-mini-total h5, section.popup-ui div.content em {
    font-size: 17px;
  }
}

.font-text-big-bold {
  font-size: 19px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .font-text-big-bold {
    font-size: 17px;
  }
}

.font-copyright, span.copyright, #mobile_partner, #content .container-right app-widget-advert {
  font-size: 11px;
}

/*** Redesign ***/
.normal-font-size, .instructions {
  font-size: 13px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .normal-font-size, .instructions {
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 1321px) {
  .normal-font-size, .instructions {
    font-size: 18px !important;
  }
}
@media only screen and (min-height: 1800px) {
  .normal-font-size, .instructions {
    font-size: 20px !important;
  }
}

.smaller-font-size {
  font-size: 12px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .smaller-font-size {
    font-size: 13px !important;
  }
}
@media only screen and (min-width: 1321px) {
  .smaller-font-size {
    font-size: 16px !important;
  }
}
@media only screen and (min-height: 1800px) {
  .smaller-font-size {
    font-size: 18px !important;
  }
}

.input-font-size {
  font-size: 14px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .input-font-size {
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 1321px) {
  .input-font-size {
    font-size: 18px !important;
  }
}
@media only screen and (min-height: 1800px) {
  .input-font-size {
    font-size: 20px !important;
  }
}

button.btn-filled, button.btn-outline {
  -webkit-transition: background-color 400ms ease-in-out;
  -moz-transition: background-color 400ms ease-in-out;
  -ms-transition: background-color 400ms ease-in-out;
  -o-transition: background-color 400ms ease-in-out;
  transition: background-color 400ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 2px;
  width: 274px;
  height: 40px;
  margin: 0;
  font-size: 13px !important;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  button.btn-filled, button.btn-outline {
    width: 620px;
    height: 50px;
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  button.btn-filled, button.btn-outline {
    width: 303px !important;
    height: 60px;
    font-size: 20px !important;
  }
}
button.btn-filled.small-width.btn-outline, button.btn-outline.small-width.btn-outline {
  width: 120px;
}
button.btn-filled.small-width.btn-filled, button.btn-outline.small-width.btn-filled {
  width: 140px;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  button.btn-filled.small-width.btn-outline, button.btn-filled.small-width.btn-filled, button.btn-outline.small-width.btn-outline, button.btn-outline.small-width.btn-filled {
    width: 295px;
  }
}
@media only screen and (min-width: 1321px), only screen and (min-height: 1800px) {
  button.btn-filled.small-width.btn-outline, button.btn-filled.small-width.btn-filled, button.btn-outline.small-width.btn-outline, button.btn-outline.small-width.btn-filled {
    width: 274px;
  }
}
button.btn-filled.btn-filled, button.btn-outline.btn-filled {
  border: none !important;
}
button.btn-filled:hover, button.btn-outline:hover {
  cursor: pointer;
}
button.btn-filled.btn-outline, button.btn-outline.btn-outline {
  background-color: transparent !important;
  border: 1px solid;
}
button.btn-filled:disabled, button.btn-outline:disabled {
  color: white !important;
  border-color: #B1B1B1 !important;
  background-color: #B1B1B1 !important;
  cursor: default;
}

.btn-grid {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
@media only screen and (min-width: 640px) and (max-width: 1320px) and (max-height: 1799px) {
  .btn-grid.half-fill button {
    width: 50%;
  }
}