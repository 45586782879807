/*===================
* Helper Classes
====================*/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.block {
  display: block;
}

.disabled_state {
  opacity: 0.55;
  cursor: not-allowed !important;
  @extend .no-events
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

span.mark {
  display: inline-block;
  background: yellow;
}

span.copyright {
  margin: 1% 0 0 1%;
  text-align: right;
  display: block;
  @extend .font-copyright;
}

.hidden-field {
  visibility: hidden;
  position: absolute;
  right: 999999999px;
  opacity: 0;
  border: none;
}

.m-32 {
  margin: 32px
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-32 {
  margin-top: 32px;
}

.m-l-8 {
  margin-left: 8px;
}

.p-l-8 {
  padding-left: 8px;
}

.m-t-16- {
  margin-top: -16px !important;
}

/*===================
* Responsive Helper
====================*/

.hidden-mobile {
  display: block;
  @media #{$phablet}, #{$mobile} {
    display: none !important;
  }
}

.hidden-tablet {
  display: block;
  @media #{$tablet} {
    display: none !important;
  }
}

.hidden-desktop {
  display: block;
  @media #{$desktop}, #{$tv} {
    display: none !important;
  }
}

a.ext-link {
  &::after {
    font-weight: 900;
    font-family: 'Font Awesome 6 Free';
    margin-left: 6px;
    max-height: 20px;
    display: inline-block;
    content: '\f35d';
    @media #{$desktop}, #{$tv} {
      display: none;
    }
  }
  &:hover {
    &::after {
      @media #{$desktop}, #{$tv} {
        display: inline-block !important;
      }
    }
  }
}

/*===================
* Graphics and Icons
====================*/

::-webkit-scrollbar {
  display: none;
  width: 0; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

/*===================
* Global
====================*/

* {
  outline: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html,
body {
  width: 100%;
  height: 100%
}

body {
  @extend .font-text;
  font-weight: 400;
  text-align: left;
  color: $tundora-grey;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;

  &.scroll_lock {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}

select,
.close-icon,
.close-icon i,
[class*="button"],
ul.icon-nav li,
div.menu a,
div.emoji.clickable,
.swiper-slide,
span.icon,
.stars i,
#activate,
.add-btn,
.magazine,
.option-input {
  cursor: pointer;
}

table {
  &.table-fullwidth {
    width: 100%;
    max-width: 100%;
    table-layout: fixed
  }
}

/*===================
* Layout
====================*/
.loader-area {
  display: block;
  position: relative;
  height: 100%;
  left: calc(50% - 60px);
}

#container {
  width: 100%;
  padding: 0;
  margin: 0;
  @media #{$phablet}, #{$mobile} {
    section#navigation {
      padding-bottom: $footer-height;

      &:not(.open) {
        top: 100vh;
        display: none;
      }

      &.open {
        top: $header-height;
        display: block;
        @include animation('open_menu 500ms');
      }
    }
    &.task {
      section#footer {
        display: none !important;
      }
      section#content {
        min-height: calc(100vh - #{$header-height});
      }
      #activate.in {
        display: none;
      }

      &:not(.cico) {
        section#content {
          padding: $header-height 16px 16px 16px;
        }
      }

      &.frame {
        section#content {
          padding: $header-height 0 16px 0;
        }
      }
    }
  }

  &.overviews {
    position: relative;

    section#navigation {
      @media #{$phablet}, #{$mobile} {
        top: calc(#{$overviews-mobile-height} + #{$header-height});
        display: block;
        padding-bottom: 0;
        margin-bottom: 5px;
      }
    }

    section#c-background {
      display: none;
    }

    section#footer {
      display: none !important;
    }

    section#content {
      padding: 0;
      @media #{$phablet}, #{$mobile} {
        height: $overviews-mobile-height;
        min-height: $overviews-mobile-height;
        padding: $header-height 0 0 0;
      }
    }

    section#background {
      @media #{$phablet}, #{$mobile} {
        div {
          top: $header-height;
          height: 300px;

          video, img {
            @media #{$phablet}, #{$mobile} {
              min-height: 300px;
              height: 300px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &.frame:not(.cico) {
    section#content {
      padding: 0;
      @media #{$mobile} {
        padding: $header-height 0 $footer-height 0;
      }
    }

    section#logo {
      &.upright {
        height: 52px;

        img {
          max-height: 52px;
        }
      }
    }
  }

  &.wizard {
    section#content {
      margin: 0;
      padding: 0
    }

    section#header {
      display: block;
    }
  }

  @keyframes open_menu {
    from {
      top: 100vh;
    }
    to {
      top: $header-height;
    }
  }
}

section#header {
  display: none;
  @media #{$phablet}, #{$mobile} {
    display: block;
    position: fixed;
    z-index: 5000;
    top: 0;
    width: 100%;
    height: $header-height;
    @include shadow(0, 3px, 7px, -2px, rgba(0, 0, 0, 0.55));
  }
}

section#footer {
  display: none;
}

section#navigation {
  width: $width-sidebar;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  min-height: 100vh;
  @include shadow(0, 0, 6px,0, rgba(0, 0, 0, 0.3));
  @extend .font-navigation;
  @media #{$phablet}, #{$mobile} {
    min-height: auto;
    width: 100%;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

section#content {
  position: relative;
  margin: 0 $width-sidebar;
  height: 100%;
  padding: 110px 5% 160px 5%;
  z-index: 3;
  @media #{$desktop} {
    padding: 0 5% 160px 5%
  }
  @media #{$tablet} {
    margin: 0 0 0 $width-sidebar;
  }
  @media #{$phablet} {
    margin: 0;
    padding: 64px 5%;
  }
  @media #{$mobile} {
    margin: 0;
    padding: 64px 16px;
  }
}

section#sidebar {
  width: $width-sidebar;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 6;

  &.removed {
    @media #{$tablet}, #{$mobile}, #{$tablet-up}, #{$phablet}  {
      width: 0;
    }
  }

  @media #{$mobile}, #{$phablet} {
    z-index: 5003;
  }
}

section#search {
  z-index: 5005;
  position: fixed;
  top: 0;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  @media #{$tablet} {
    margin-left: -120px;
  }
  @media #{$phablet}, #{$mobile} {
    top: $header-height;
    left: 0;
    width: 100vw;
    margin: 0;
  }
}

#background {
  z-index: -1;

  div {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: center center !important;
    transition: opacity 3s ease-in-out;
    @media #{$tablet} {
      width: calc(100% - #{$width-sidebar});
      left: $width-sidebar;
      text-align: center;
    }

    &.active {
      opacity: 1;
    }

    video, img {
      min-height: 100%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      object-fit: cover;
      object-position: center center;
      width: 100vw;
      height: 100vh;
    }
  }
  &.landing{
    div, img, video {
      top: 0 !important;
      left: 0 !important;
      -webkit-transform: none;
      transform: none;
      height: 100vh !important;
      width: 100vw !important;
    }
  }
}

/** Background **/

section#c-background {
  z-index: 2;
  background: white;
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
}

#curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  z-index: 5004;
  cursor: pointer;
}

#container {
  section#header {
    .icon:nth-child(1) {
      display: none;
    }

    .icon:nth-child(2) {
      display: block;
    }
  }

  &.overviews {
    section#content {
      background: none;
    }

    section#header {
      .icon:nth-child(1) {
        display: block;
      }

      .icon:nth-child(2) {
        display: none;
      }
    }
  }
}

/** Bright Theme **/

body#bright {
  .bright_button {
    background: $concrete-grey;
    color: $emperor-grey;

    &:hover {
      background: #dedede;
    }
  }

  section#header {
    section#logo {
      background: $bright-background-color;
    }

    background: $bright-background-color;

    a.icon, span.icon {
      color: $tundora-grey !important;

      i {
        color: $tundora-grey !important;
      }
    }
  }

  section#footer {
    background: $bright-background-color;
    border-top: none;

    .icon-button {
      border-right: 1px solid rgba(124, 124, 124, 0.21);
    }

    .icon-button {
      color: $emperor-grey !important;

      i {
        color: $emperor-grey !important;
      }
    }
  }

  section#navigation {
    background: $bright-background-color;

    > * {
      border-color: $alabaster-grey;
    }

    &.no_hover {
      nav ul li {
        &:hover {
          border-color: $alabaster-grey !important;

          a {
            background: $bright-background-color !important;
            color: $grey !important;
          }
        }
      }
    }

    nav ul li {
      border-color: $alabaster-grey;
      &.loading {
        &:hover {
          border-color: $alabaster-grey !important;
        }
      }
    }

    a {
      color: $grey;
      cursor: pointer;
    }
  }

  div.weatherblock {
    border-color: $alabaster-grey;
  }

  .menu-label {
    color: $emperor-grey;

    &:hover {
      border-color: $alabaster-grey !important;
    }
  }

  .menu-seperator {
    border-color: $alto-grey !important;
  }

  #activate {
    border-color: $alto-grey;
    border-right-color: $bright-background-color;
    background: $bright-background-color;
  }

  section#sidebar {
    background: $bright-background-color;
    text-align: center;
    border-left: solid 1px $alto-grey;

    .scroll > * {
      border-color: $alabaster-grey;
    }

    h2, h4, a, p, .title {
      color: $emperor-grey;
    }

    .button {
      background: #3f3f3f;
    }

    ul.icon-nav {
      li {
        border-color: $alabaster-grey;
      }
    }

    app-widget-suggestions {
      div.menu {
        a {
          div:first-of-type {
            @extend .font-widget-text;
          }
        }
      }
    }

    app-widget-advert {
      background: $bright-background-color;
    }

    .swiper-button-next::before,
    .swiper-button-prev::before {
      @extend .bright_button;
    }
  }

  app-widget-group-business, app-widget-advert {
    span {
      &:not(.single) {
        background: $concrete-grey;
        color: $emperor-grey;
      }
    }
  }

  app-widget-qr-code div {
    background: $bright-background-color;
  }

  .side-button,
  .social-button,
  span.weathericon {
    @extend .bright_button;
  }

  app-landingpage {
    .fog {
      background-color: rgba(255, 255, 255, 0.5);
    }

    color: black;
  }
}

/** Dark Theme **/

body#dark {
  .dark_button {
    background: rgba(255, 255, 255, 0.07);
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.14);
    }
  }

  .swiper_dark_button {
    background: transparent;
    color: white !important;

    &:hover {
      background: rgba(255, 255, 255, 0.14);
    }
  }

  section#header {
    section#logo {
      background: white;
    }

    background: $cod-grey;

    a.icon, span.icon {
      color: $mercury-grey !important;

      i {
        color: $mercury-grey !important;
      }
    }
  }

  section#footer {
    background: $cod-grey;
    border-top: none;

    .icon-button {
      border-right: 1px solid rgba(124, 124, 124, 0.21);
    }

    .icon-button {
      color: $mercury-grey !important;

      i {
        color: $mercury-grey !important;
      }
    }
  }

  section#navigation {
    background: $cod-grey;

    > * {
      border-color: $min-shaft-grey;
    }

    &.no_hover {
      nav ul li {
        &:hover {
          border-color: $min-shaft-grey !important;

          a {
            background: $cod-grey !important;
            color: $mercury-grey !important;
          }
        }
      }
    }

    nav ul li {
      border-color: $min-shaft-grey;
      &.loading {
        &:hover {
          border-color: $min-shaft-grey !important;;
        }
      }
    }

    a, p {
      color: $mercury-grey;
    }
  }

  &:not(.no-sidebar) {
    section#c-background {
      @media #{$desktop}, #{$tv} {
        right: $width-sidebar;
      }
    }
  }

  div.weatherblock {
    border-color: $min-shaft-grey;
  }

  .menu-label {
    color: #CCC;

    &:hover {
      border-color: $min-shaft-grey !important;
    }
  }

  .menu-seperator {
    border-color: $boulder-grey !important;
  }

  #activate {
    color: $mercury-grey;
    border-color: $min-shaft-grey;
    border-right-color: $cod-grey;
    background: $cod-grey;
  }

  section#sidebar {
    background: $dark-sidebar-background-color;
    text-align: center;
    border-left: solid 1px $min-shaft-grey;

    .scroll > * {
      border-color: $min-shaft-grey;
    }

    h1, h2, h4, a, p, .title {
      color: $mercury-grey;
    }

    .button {
      background: #3f3f3f;
    }

    app-widget-advert {
      color: $mercury-grey;
      background: $cod-grey;
    }

    app-widget-suggestions {
      ul.icon-nav {
        li {
          border-color: $min-shaft-grey;
          background: rgba(255, 255, 255, 0.07);
          color: white;

          &:hover {
            background: rgba(255, 255, 255, 0.14);
          }
        }
      }

      .menu {
        a {
          border-color: $min-shaft-grey;

          &:hover {
            background: rgba(255, 255, 255, 0.14);
          }
        }
      }
    }

    .swiper-button-next::before,
    .swiper-button-prev::before {
      @extend .swiper_dark_button
    }
  }

  app-widget-group-business, app-widget-advert {
    span {
      &:not(.single) {
        background: rgba(255, 255, 255, 0.07);
        color: white;
      }
    }
  }

  section#content {
    app-widget-advert {
      span {
        &:not(.single) {
          background: $concrete-grey;
          color: $emperor-grey;
        }
      }
    }
  }

  app-widget-qr-code div {
    background: rgba(255, 255, 255, 0.07);
  }

  .side-button,
  .social-button,
  span.weathericon {
    @extend .dark_button
  }

  app-landingpage {
    .fog {
      background-color: rgba(0, 0, 0, 0.5);;
    }

    color: white;
  }
}

/*===================
* FX
====================*/
nav ul li:hover a,
nav ul li:hover,
#search-results ul li:hover,
#curtain,
.button,
.widget-button,
.icon-button,
.tile,
.main-bg,
.button-hollow-small,
ul.icon-nav li,
div.menu a:hover,
span.weathericon,
.magazine,
.swiper-button-next,
.swiper-button-prev,
.swiper-button-next::before,
.swiper-button-prev::before,
.overview-button,
.social-button,
.inactivity-detection,
.side-button {
  @include transition(all, 400ms, ease-in-out);
}

.grayscale {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
}

/* Alert */

.alert {
  @extend .button-radius;
  color: #ff0000;
  background-color: #fff0f0;
  position: relative;
  padding: 18px 3%;
  margin: 0 0 36px;
  border: 1px solid #f5c6cb;

  &.alert-success {
    color: #4eaf57;
    background-color: #d7e8d4;
    border-color: #90b68b
  }

  &.alert-info {
    color: #5086a1;
    background-color: #d9edf7;
    border-color: #7daec6;
    a {
      color: #5086a1 !important;
    }
  }

  ul {
    margin: 0;
  }
}

.text-success {
  color: $green;
}

.text-error {
  color: $error-color;
}

.text-grey {
  color: $grey;
}

/*===================
* Header
====================*/

section#header {
  a {
    img.flag-icon {
      margin: 12px auto 3px auto;
      height: 16px;
      border: 0.8px solid $alto-grey;
      display: block;
    }
  }

  .icon {
    position: absolute;
    height: 100%;
    width: 120px;
    @extend .font-headerfooter;
    top: 0;
    margin: 0;
    padding: 0 3px;
    text-align: center;
    @extend .ellipsis;
    display: block;
    text-decoration: none;
    border-right: 1px solid rgba(124, 124, 124, 0.21);
    @media #{$mobile} {
      width: 96px;
      font-size: 13px;
    }
    @media #{$tiny} {
      width: 76px;
      font-size: 13px;
    }

    i {
      font-size: 16px;
      padding: 13px 0 4px;
      display: block;
      color: #eee;
      text-decoration: none;
    }

    &:last-child {
      right: 0;
      border-right: none;
      border-left: 1px solid rgba(124, 124, 124, 0.21);
    }
  }

  section#logo {
    margin: auto;
    height: 52px;
    display: inline;
    padding: 8px 12px;
    top: 10%;
    position: absolute;
    left: 0;
    right: 0;
    @include shadow(0, 3px, 7px, -2px, rgba(0, 0, 0, 0.55));
    width: 200px;
    z-index: 1;

    img {
      outline: none;
      border: none;
    }

    @media #{$mobile} {
      width: 140px;
      padding: 7px 8px;
      a {
        width: 140px !important;
        margin: auto;
      }
    }
    @media #{$tiny} {
      width: 128px !important;
      padding: 7px 8px;
      a {
        width: 128px;
        margin: auto;
      }
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      border: none;

      img {
        max-height: 52px;
        max-width: 75%;
        @media #{$mobile} {
          max-width: 90%;
        }
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

/*===================
* Navigation
====================*/

section#navigation {
  > * {
    display: block;
    border-top: 1px solid;
    padding: 15px 0;

    &:first-child, &:empty {
      border-top: none;
    }
  }

  app-menu {
    padding: 0;

    nav {
      ul {
        padding: 0;
        margin: 0;
      }

      li.menu-label {
        font-size: 14px;
        font-weight: 700;
        @extend .hidden-mobile;
      }

      ul li {
        border-bottom: 1px solid;
        list-style: none;

        &.active {
          .triangle {
            @media #{$phablet}, #{$mobile} {
              display: none;
            }
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-style: solid;
            border-width: 23px 21px 23px 21px;
            height: 0;
            width: 0;
            z-index: 111;
            position: absolute;
            right: -42px;
            margin-top: -12px;
          }
        }

        a {
          display: block;
          padding: $sidebar-padding;
          @media #{$phablet}, #{$mobile} {
            padding: 18px 14px 18px 38px;
          }
          @extend .ellipsis;

          i {
            margin-right: 18px;
            width: 30px;
            font-size: 16px;
            text-align: center;
          }

          img.flag-icon {
            border: 0.8px solid $alto-grey;
            vertical-align: middle;
            height: 18px;
            margin-right: 20px;
            margin-left: 2px;
          }

          &.disabled {
            @extend .disabled_state
          }
        }

        p {
          padding-left: 18px;
        }

        &:last-child {
          border-bottom: none;
        }

        &.loading {
          &:hover {
            background: none !important;
          }

          a {
            background: none !important;
            color: transparent !important;
            text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

            &:hover {
              background: none !important;
            }
          }
        }
      }
    }
  }

  app-widget-a2hs {
    display: none;
    @media #{$phablet}, #{$mobile} {
      display: block;
    }
  }

  app-widget-logo {
    @extend .hidden-mobile;
    position: relative;
    text-align: center;
    height: 136px;
    padding: 0;

    a {
      display: block;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-height: 100px;
        max-width: 210px;
      }
    }
  }

  app-widget-weather {
    display: none;
    @media #{$phablet}, #{$mobile} {
      display: block;
    }

    span.weathericon {
      margin-left: 25px;
      margin-right: -25px;
      float: left;
    }

    h2 {
      display: block;
      margin-top: 0;
    }

    p {
      display: inline-block;
      margin: 0;
    }
  }

  app-widget-advert {
    @extend .hidden-desktop;
  }

  app-widget-group {
    @extend .hidden-mobile;
    position: relative;
    text-align: center;

    img {
      margin: auto;
      max-height: 100px;
      max-width: 230px;
    }
  }

  app-widget-address {
    @extend .hidden-mobile;
    padding: $sidebar-padding;

    a {
      font-weight: bold;
      cursor: pointer;
    }

    p {
      margin: 0;
    }
  }

  app-widget-push {
    text-align: center;

    a {
      @extend .ellipsis;
      display: block;
    }
  }

  app-widget-social {
    @extend .hidden-desktop;
    text-align: center;
  }

  app-widget-group-image, app-widget-group-business {
    display: none;
    @media #{$phablet}, #{$mobile} {
      display: block;
    }
  }

  app-widget-legal, app-widget-push {
    text-align: center;

    .pale {
      opacity: 0.62;
      word-wrap: break-word;
    }

    a {
      text-decoration: underline;
      margin: 0 8px;
      display: block;
    }

    span {
      display: block;
      margin-top: 16px;
      opacity: 0.4;

      &::before {
        content: 'v';
      }
    }
  }
}

/*===================
* Sidebar & Navigation
====================*/

#weatherwidget {
  div.weatherblock {
    padding: 16px 0 12px;
    width: 50%;
    border-right: 1px solid;
    text-align: center;
    @include box-sizing;
    @media #{$tiny} {
      text-align: right;
    }
    @media #{$mobile}, #{$phablet} {
      text-align: center;
    }
    float: left;

    &:last-child {
      width: calc(50% - 1px);
      border-right-width: 0;
    }

    p {
      margin-top: 0;
      @extend .font-widget-text;
    }

    h2 {
      margin-top: 5px;
      margin-bottom: 0;
      @extend .font-widget-temperature;
      @media #{$phablet}, #{$mobile} {
        display: inline-block;
        margin-top: 0;
      }
    }

    span.weathericon {
      display: block;
      width: 46px;
      height: 46px;
      padding: 3px 5px;
      margin: auto;
      border-radius: $round-radius;
      @media #{$phablet}, #{$mobile} {
        display: inline-block;
      }
    }

    img {
      display: block;
      width: 46px;
      height: 46px;
    }
  }
}

app-widget-a2hs {
  @media all and (display-mode: standalone) {
    display: none !important;
  }

  button.main-bg {
    margin: auto;
  }
}

app-widget-group-image {
  a {
    cursor: pointer;

    img {
      display: block;
      margin: 0 auto !important;
      max-width: 230px;
    }
  }
}

app-goldkey-parent {
  .magazine {
    margin: 8px 12px;
    padding: 8px;
    text-align: center;
    display: inline-block;
    border-radius: $round-radius;
    background: $concrete-grey;

    &:hover {
      background: #dedede;
    }

    .name {
      @extend .ellipsis;
      padding-bottom: 6px;
    }

    img {
      text-align: center;
      vertical-align: middle;
      max-width: 100%;
      max-height: 280px;
    }

    @media #{$tv} {
      width: calc(100% / 4 - 34px);
      &:nth-of-type(4n+1) {
        margin-right: 0;
      }
      &:nth-of-type(4n-2) {
        margin-left: 0;
      }
      img {
        max-height: 250px;
      }
    }
    @media #{$desktop}, #{$tablet} {
      width: calc(100% / 3 - 34px);
      &:nth-of-type(3n+2) {
        margin-right: 0;
      }
      &:nth-of-type(3n-2) {
        margin-left: 0;
      }
    }
    @media #{$tablet} {
      img {
        max-height: 250px;
      }
    }
    @media #{$phablet}, #{$mobile} {
      width: calc(100% / 2 - 34px);
      &:nth-of-type(2n+3) {
        margin-right: 0;
      }
      &:nth-of-type(2n-2) {
        margin-left: 0;
      }
    }
  }

  .logo {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    }
  }
}

app-widget-group-business, app-widget-advert {
  .slider {
    width: 230px;
    margin: auto;
    text-align: center;
  }

  span {
    padding-top: 4px;
    width: 230px;
    height: 27px;
    display: block;
    margin: 0 auto !important;
    @extend .ellipsis;

    &:first-of-type {
      margin: 0 auto;

      span {
        padding: 0;
        width: calc(230px - (2 * 31px));
        @extend .ellipsis;
      }
    }
  }

  .name {
    min-height: 1.5em;
    max-height: 2.8em;
    overflow: hidden;
    white-space: pre-wrap;
    padding-bottom: 1px;
    font-size: 15px;
  }

  .swiper-button-next, .swiper-button-prev {
    height: 30px !important;
    font-size: 0.8em !important;
    z-index: 1;
    top: 0;

    &::before {
      color: $emperor-grey !important;
      font-size: 2em !important;
      margin-top: -6px !important;
    }
  }

  .inner {
    margin: 0 auto !important;
    display: block;
    width: 230px;
    height: 180px;
    background-size: cover !important;
    background-position: center center !important;
    cursor: pointer;
  }
}

app-widget-advert {
  .inner {
    height: auto;
    padding-top: 5px;
  }

  .swiper-slide {
    height: auto !important;
    max-height: 230px !important;

    img {
      max-height: 175px;
    }
  }
}

app-wizard-header {
  section {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
  }

  h4 {
    color: $grey;
    float: right;
    margin-right: 32px;
    padding-top: 3px;
  }

  ng-select {
    float: right;
    margin: 16px 16px 0 0;

    img {
      width: 25px;
      margin-top: 8px;
      border: 1px solid $alto-grey;
    }

    .ng-select-container {
      height: 46px !important;

      .ng-input {
        display: none;
      }
    }

    .ng-dropdown-panel {
      width: 150px !important;
      right: 0;
      left: auto !important;

      img {
        vertical-align: middle;
        margin-right: 8px;
        margin-top: 0;
      }

      .ng-dropdown-panel-items {
        max-height: none;
      }
    }
  }
}

/** Mobile Group **/

#mobile_group_logo {
  display: none;
  @media #{$phablet}, #{$mobile}, #{$tv} {
    margin-top: 20px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
    img {
      display: block;
      width: auto;
      max-width: 80%;
      margin: 0 auto !important;
    }
  }
}

/** Mobile Partner **/

#mobile_partner {
  display: none;
  @extend .font-copyright;
  @media #{$phablet}, #{$mobile} {
    margin-top: 20px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
    img {
      display: block;
      width: auto;
      max-width: 80%;
      margin: 0 auto !important;
    }
  }
}

/*===================
* Sidebar
====================*/

#activate {
  display: none;
  @media #{$tablet}, #{$mobile}, #{$tablet-up}, #{$phablet} {
    display: block;
    top: 85px;
    right: $width-sidebar;
    position: fixed;
    border: 1px solid;
    border-radius: 20px 0 0 20px;
    z-index: 5001;
    width: 45px;
    height: 52px;
    text-align: center;
    i {
      line-height: 53px;
      margin-left: 3px;
      font-size: 27px;

      &.fa-caret-right {
        margin-left: 10px;
      }
    }
    i.in {
      display: none;
    }
    i.out {
      display: block;
    }
    &.in {
      right: 0;

      i.in {
        display: block;
      }

      i.out {
        display: none;
      }
    }
  }
}

section#sidebar {
  .scroll {
    overflow: auto;
    max-height: 100vh;

    > * {
      display: block;
      border-top: 1px solid;
      padding: 15px 2px;

      &:first-child, &:empty {
        border-top: none;
      }
    }
    app-widget-a2hs {
      &.safari {
        @media #{$tablet}, #{$phablet}, #{$mobile} {
          > * {
            padding: 0;
          }
        }
      }
    }
}

  i {
    display: block;
  }

  .title {
    @extend .font-widget-text;
    background: none;
    padding: 8px 0;
  }

  app-widget-qr-code {
    div {
      padding: 7px 0;
      margin: 0 14px;
      border-radius: $round-radius;
    }

    qrcode img {
      padding: 3px;
      margin: 5px auto;
      background: white;
    }
  }

  app-widget-advert {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  app-widget-suggestions {
    padding: 15px 0 0 0 !important;
  }

  #group_logo {
    padding: 15px 2px;
    position: relative;
    text-align: center;

    img {
      margin: auto;
      max-height: 100px;
      max-width: 210px;
    }
  }

  app-widget-weather {
    .weatherblock {
      h2 {
        display: block;
      }
    }
  }

  .side-button {
    display: block;
    padding: 15px 23px;
    margin: 0 14px;
    border-radius: $round-radius;
    @extend .ellipsis;

    i {
      font-size: 26px;
      margin-bottom: 3px;
    }
  }
}

/*===================
* Footer
====================*/

section#footer {
  display: none;
  @media #{$phablet}, #{$mobile} {
    display: block;
    @include shadow(0, -2px, 3px, -2px, rgba(0, 0, 0, 0.55));
    position: fixed !important;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    height: $footer-height;
    border-top: 1px solid #242424;
  }
  @media #{$super-tiny}{
    display: none;
  }

  .footer-nav {
    height: 100%;
    z-index: 5000;
  }

  .icon-button {
    position: relative;
    height: 100%;
    width: 33.33%;
    float: left;
    margin: 0;
    padding: 0 3px;
    text-align: center;
    @include box-sizing;
    @extend .font-headerfooter;

    &:last-child {
      border-right: none;
    }

    i {
      display: block;
      padding: 11px 0 8px;
      font-size: 16px;
    }

    &#pullup_menu_btn {
    }

    .open-menu,
    .close-menu {
      width: 100%;
    }
  }

  #pullup_menu_btn .open-menu {
    display: block;
  }

  #pullup_menu_btn .close-menu {
    display: none;
  }
}

/*===================
* Content
====================*/

section#content {
  padding-top: 85px;

  .page-headline {
    color: $grey;
    position: relative;
    line-height: 1.4em;
    margin-bottom: 32px;
    padding: 0px 10px 10px 1px;
    border-bottom: 1px solid $alto-grey;
    @media #{$phablet}, #{$mobile} {
      padding: 10px 10px 2px 2px;
      margin-bottom: 16px;
    }

    &.inner {
      padding-top: 10px;
    }

    h1 {
      @extend .font-page-headline;
      margin-bottom: 6px;

      &.blurry {
        color: transparent;
        text-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
      }
    }

    h2 {
      @extend .font-article-name;
      color: $tundora-grey;
      line-height: 1.4em;

      + small {
        display: block;
        margin-top: -12px;
        margin-bottom: 0.83em;
      }
    }
  }

  h3 {
    @extend .font-article-name;
  }

  h4 {
    @extend .font-sub-headline;
    color: #8a8a8a;
    margin: 50px 0 16px;

    span.edit {
      cursor: pointer;

      i {
        font-size: 20px;
        margin-left: 8px;
      }
    }
  }

  p, table {
    @extend .font-text;
    margin-bottom: 10px;

    &.error {
      color: $error-color;
    }
  }

  a:not(.tile, .redesign) {
    @extend .font-text;
    color: $emperor-grey;
    font-weight: 600 !important;
    text-decoration: none;
    cursor: pointer;
  }
  
  app-info-screen .message.alternative-reservations a{
    color: #288191;
    text-decoration: underline !important;
    font-weight: 300 !important;
  
    &:hover {
      text-decoration: none !important;
    }
  }

  p i {
    margin-right: 6px;
  }

  img {
    &:not(.imgSlide) {
      max-width: 100%;
      height: auto;
    }
  }

  #map {
    width: 98%;
    margin: 30px auto;
    height: 300px;
    @media #{$tiny}, #{$mobile}, #{$phablet}{
      width: 100%;
      margin: 20px 0;
    }

    &.overview {
      height: calc(100vh - 230px);
      margin: 0 auto;
      @media #{$tiny}, #{$mobile}, #{$phablet}{
        height: calc(100vh - 185px - #{$footer-height});
      }
    }

    .gm-style-iw {
      h1 {
        @extend .font-sub-headline;
      }

      img {
        max-width: 200px;
      }
    }
  }

  /* List */
  ul {
    &:not(.dropdown-menu) {
      list-style-type: square;
      padding-left: 20px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  li {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
  }

  li::before {
    color: red; /* or whatever color you prefer */
  }

  ul ul, ol ul {
    list-style-type: square;
    margin-left: 15px;
  }

  ol ol, ul ol {
    list-style-type: decimal;
    margin-left: 15px;
  }

  /** Seperator **/

  .seperator {
    height: 1px;
    width: 100%;
    background: $concrete-grey;
    margin-top: 16px;
    margin-bottom: 30px;
  }

  /** Top Button **/

  .top-btn-container {
    text-align: center;

    .button {
      padding: 12px 50px 12px 50px;
      margin: 15px 10px;
      display: inline-block;
      max-width: 190px;
      @extend .font-button;
      @extend .ellipsis;
      @extend .no-shadow;
      @extend .button-radius;

      i {
        margin-right: 17px;
        font-size: 16px;
      }
    }
  }

  .icon-nav {
    li {
      border-color: $concrete-grey;
    }
  }

  /** Half content **/

  .half {
    > div {
      width: calc(50% - 8px);
      display: inline-block;
      vertical-align: top;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        display: block;
      }
    }

    &.seperated {
      > div {
        width: calc(50% - 10px);
        border-right: 1px solid $mercury-grey;
        &:last-child {
          border-right: none;
        }
      }
      &.stacked {
        > div {
          @media #{$mobile}, #{$tablet-up} {
            border-right: none;
          }
        }
      }
    }

    &.stacked {
      > div {
        @media #{$mobile}, #{$tablet-up} {
          width: 100%;
          display: block;
          margin-left: 0 !important;
          margin-right: 0 !important;
          &:last-child {
            padding-top: 16px;
          }
        }
      }
    }
  }
}

/** Table **/

table {
  width: 100% !important;
  max-width: 100%;
  word-break: break-all;
  vertical-align: top;
  border-collapse: collapse;

  tr, td {
    vertical-align: top;
  }

  tr {
    td {
      border-right: 1px solid $concrete-grey;
      border-bottom: 1px solid $concrete-grey;
      padding: 4px 0 4px 4px;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &.timetable {
    td {
      text-align: center;
      padding: 4px 0;

      &.tableday {
        font-weight: bold;
        padding-left: 4px;
        text-align: left !important;
      }

      .time {
        word-spacing: 12px;
      }
    }
  }
}

/** Icon List **/

ul.icon-list {
  list-style: none !important;
  padding-left: 0 !important;
  margin-bottom: 0;
  @extend .ellipsis;

  li {
    line-height: 22px;

    i {
      padding-right: 6px;
    }
  }
}

/** Tiles **/

div.tiles {
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 40px auto;
  text-align: center;
  @media #{$mobile} {
    margin: 20px auto;
    max-width: 340px;
  }
  @media #{$tiny} {
    max-width: 100%;
  }

  a.tile {
    @extend .font-tiles;
    @extend .ellipsis;
    @include fade_in(100ms);
    width: 31.8%;
    margin-right: 2.3%;
    height: 136px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: 1px solid;
    @include box-sizing;

    i {
      height: 48px;
      line-height: 48px;
      padding: 25px 0 8px 0;
      font-size: 36px;
      display: block;
    }

    @media #{$mobile} {
      height: 86px;
      font-size: 13px;
      i {
        height: 36px;
        font-size: 27px;
        padding: 8px 0 7px 0;
      }
    }

    &:only-child {
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

/** Menus **/

div.menu {
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0 auto;
  width: 90%;
  @media #{$tablet}{
    width: 95%;
  }
  @media #{$phablet}, #{$mobile} {
    width: 100%;
  }

  &.dropdown-menu {
    display: none;
  }

  .head {
    height: $menu-head-height;
    line-height: $menu-head-height;
    display: table-row;
    @extend .font-sub-headline;
    color: $grey;

    > div {
      &:last-of-type {
        width: $menu-caret-width;
      }

      @media #{$mobile} {
        padding: 0 8px;
      }
      padding: 0 10px;
      display: table-cell;
    }
  }

  a {
    height: $menu-item-height;
    line-height: $menu-item-height;
    cursor: pointer;
    display: table-row;
    border-top: 1px solid $concrete-grey;
    text-decoration: none !important;

    &.addions {
      height: $menu-head-height;
      line-height: $menu-head-height;
    }

    &.more {
      div:first-child {
        font-weight: bold;
      }
    }

    &.divider{
      height: 0 !important;
      border-top: 1px solid $concrete-grey !important;
      margin: 16px 0;
    }

    details {
      @extend .hidden;
    }

    small {
      @extend .ellipsis;
    }

    &:not([href]) {
      &:not(.link) {
        cursor: default !important;

        &:hover {
          background: none;
        }
      }
    }

    > div {
      @extend .font-list;
      @extend .ellipsis;
      padding: 0 10px;
      display: table-cell;

      &.two-lines {
        line-height: 28px;
        vertical-align: middle;

        small {
          display: block;
          line-height: 20px;
        }
      }

      &:last-of-type:not(:only-of-type) {
        text-overflow: inherit;
        width: calc(#{$menu-caret-width} + 8px);

        > i {
          font-size: 20px;
          color: $grey;
          line-height: $menu-item-height;
          width: $menu-caret-width;
          text-align: center;
          display: block;

          &.fa-shopping-basket {
            font-size: 17px;
          }
        }
      }
    }

    &:hover,
    &:active {
      background: #f4f4f4;
    }

    &:last-of-type {
      border-bottom: 1px solid $concrete-grey;
    }
  }

  &.condensed {
    a {
      line-height: calc(#{$menu-item-height} - 24px);
      height: calc(#{$menu-item-height} - 24px);

      div {
        vertical-align: middle;
      }

      div:last-of-type i {
        line-height: calc(#{$menu-item-height} - 20px);
      }
    }
  }
}

/** Flags **/

img.flag-icon {
  border: 0.8px solid $alto-grey;
}

/** Article **/

.box-sizing-article {
  width: calc(100% - 300px);
  float: left;
  border-right: 1px solid #ddd;
  padding-right: 40px;
  @include box-sizing;
  @media #{$mobile}, #{$phablet}, #{$tablet}{
    width: 100%;
    border: none;
    padding: 0;
  }
}

#content {
  h3 {
    margin: 24px auto 2px;
    color: $tundora-grey;
  }

  p.faded {
    color: #acacac;
    font-size: 17px;
  }

  em {
    margin: 40px 0 30px 0;
    @extend .font-text-big;
    color: $boulder-grey;
    text-align: left;
    display: block;
    @media #{$phablet},
    #{$mobile} {
      margin: 20px 0 15px 0;
    }

    a {
      @extend .font-text-big;
    }
  }

  h4 {
    margin: 27px 0 6px
  }

  a.phone-link:before {
    font-family: 'Font Awesome 6 Free';
    font-weight: 600;
    content: '\f098';
    width: 20px;
    display: inline-block;
  }

  .container {
    > div:only-child {
      border-right: none;
      width: 100%;
      padding-right: 0;
    }

    @media #{$phablet}, #{$mobile}, #{$tablet}{
      padding: 0;
      margin: auto;
    }
  }

  .article-text {
    @extend .box-sizing-article;
    padding-bottom: 30px;
  }

  .container-left {
    @extend .box-sizing-article;

    em {
      margin: 15px 0 30px 0;
    }

    form:not(.redesign) {
      padding-top: 16px;
      @media #{$mobile} {
        padding-top: 4px;
      }
    }

    hr {
      border: none;
      height: 1px;
      color: $alto-grey;
      background-color: $alto-grey;
    }

    .searchbox{
      input {
        border: 1px solid $mercury-grey;
      }
    }

    > div {
      iframe[allowfullscreen='allowfullscreen'] {
        max-width: 100%;
        @media #{$mobile}, #{$phablet} {
          min-width: 100%;
          width: 100%;
          height: 360px;
          border: 0
        }
      }
    }

    app-attachments {
      margin-top: 16px;
      @media #{$mobile}, #{$phablet} {
        margin-bottom: 64px;
      }
      display: block;
    }

    app-widget-social {
      display: block;
      margin: 22px 0;
    }

    google-map .map-container {
      width: 100% !important;

      .gm-style-mtc-bbw {
        display: none;
      }
    }

    google-map:not(.overview) .map-container {
      height: 300px !important;
    }

    google-map.overview .map-container {
      height: calc(100vh - 230px) !important;;
      margin: 0 auto;
      @media #{$tiny}, #{$mobile}, #{$phablet}{
        height: calc(100vh - 205px - #{$footer-height}) !important;;
      }

      .agm-info-window-content {
        padding-right: 12px;
        padding-bottom: 12px;
        max-width: 400px;
        @media #{$tiny}, #{$mobile} {
          max-width: 75vw;
        }

        h4 {
          font-size: 16px;
          margin: 6px 0;
        }

        small {
          font-size: 12px;
        }

        p {
          font-size: 14px !important;
          max-width: 200px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        a {
          font-size: 14px;
        }

        .group {
          img {
            max-width: 50% !important;
            max-height: 105px !important;
            display: inline-block;
          }

          div {
            display: inline-block;
            vertical-align: top;
            padding: 0 10px;

            p {
              margin-top: 0;
            }

            a {
              float: none;
              margin-top: 10px;
              display: block;
            }
          }
        }
      }

    }

    .map-preview {
      height: 300px;
      width: 100%;
      position: relative;
      cursor: pointer;

      &.overview {
        height: calc(100vh - 230px);
      }

      img {
        width: 100%;
        height: 100%;
      }

      .box {
        position: absolute !important;
        text-align: center !important;
        left: 10%;
        top: 10%;
        width: 80%;
        @extend .form-box;
      }
    }

    &.all {
      em {
        margin: 30px 0 0 0;
      }

      .menu {
        margin-top: 10px;
      }
    }

    .guest-list {
      @extend .clearfix;
      margin-bottom: 30px;

      .guest {
        padding-top: 16px;

        &.last-guest {
          border-bottom: 1px solid $alto-grey;
        }
      }
    }
  }

  .container-right {
    width: 260px;
    text-align: left;
    float: right;
    @media #{$mobile}, #{$phablet}, #{$tablet}{
      width: 100%
    }

    app-swiper, app-widget-advert, .opening_hours, .basket_mini, .article-address, .article-price {
      display: block;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    app-swiper#wish {
      margin-top: 100px;
    }

    app-widget-advert {
      .slider {
        width: 260px;
      }

      span {
        width: 260px;

        &:first-of-type {
          margin: 0 auto;

          span {
            width: calc(260px - (2 * 31px));
          }
        }
      }

      .inner {
        width: 260px;
      }
    }

    h4 {
      margin-top: 0;
    }

    img {
      &:not(.imgSlide) {
        text-align: center;
        @media #{$tiny}, #{$mobile}, #{$phablet}{
          width: 100%;
          margin: 0 0 15px 0;
        }
      }
    }

    .article-price {
      @include box-sizing;
      text-align: right;
      background: #f4f4f4;
      padding: 16px;

      h3, h4 {
        margin: 0 0 7px;
        line-height: 1em;
        font-size: 30px;
        color: $tundora-grey;

        &.range {
          font-size: 26px;
        }
      }

      h4 {
        font-size: 22px;
      }

      p {
        margin: 0;
      }
    }

    @media #{$tablet}, #{$phablet} {
      .article-address {
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        @include box-sizing;
        &:first-of-type {
          padding-left: 0;
        }
      }
      .article-address:first-child:nth-last-child(1),
      .article-address:first-child:nth-last-child(2),
      .article-address:first-child:nth-last-child(2) ~ .article-address {
        width: 100%;
      }
      .article-address:first-child:nth-last-child(3),
      .article-address:first-child:nth-last-child(3) ~ .article-address {
        width: 50%;
      }
      .article-address:first-child:nth-last-child(4),
      .article-address:first-child:nth-last-child(4) ~ .article-address {
        width: 33.3333%;
      }
    }

    .basket_mini {
      display: block;
      @media #{$tablet}, #{$phablet}, #{$mobile} {
        display: none;
      }
      background: #f4f4f4;
      color: #4c4c4c;
      width: 100%;
      text-align: left;
      padding: 16px 0;
      text-decoration: none;

      h4 {
        color: #808080;
        margin: 0 0 0 14px !important;
      }

      ul.b-mini-list {
        padding: 12px 14px 16px;
        margin: 0;
        @include box-sizing;

        li {
          line-height: 1.6em;
          list-style: none;
        }
      }

      .b-mini-total {
        margin: 0 14px;
        border-top: 1px solid #ccc;
        padding: 9px 2px 0;
        text-align: right;

        h5 {
          @extend .font-text-big;
          color: $tundora-grey;
          font-size: 19px;
          margin: 0 !important;
        }

        p {
          @extend .font-text;
          font-size: 14px;
          margin: 0;
        }
      }
    }

    .opening_hours {
      padding-bottom: 15px;
      @media #{$desktop}, #{$tv}{
        padding-bottom: 0;
      }

      h4 {
        margin-top: 0;
      }

      table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        border: none;
        @media #{$phablet}, #{$tablet} {
          width: 70%;
          margin: auto;
        }

        tr {
          vertical-align: top;
          border-bottom: 1px solid $concrete-grey;

          td {
            text-align: center;

            span {
              width: 60px;
              display: inline-block;
              text-align: center;
            }
          }
        }
      }
    }

    app-widget-advert {
      @extend .hidden-mobile;
      @extend .hidden-tablet;
      @extend .font-copyright;
      margin-top: 27px;
      text-align: center;
      padding-bottom: 8px;

      .slider {
        font-size: 1.5em;
      }

      .swiper-button-next, .swiper-button-prev {
        &:hover,
        &:active {
          background: rgba(222, 222, 222, 1) !important;
        }

        &:focus {
          outline: none;
        }
      }

      img {
        margin-bottom: 0 !important;
        margin-left: auto;
        margin-right: auto;
        width: auto
      }
    }
  }
}

/** Files **/
.menu.files {
  .head, a {
    div:nth-of-type(1) {
      width: 40px;

      i {
        font-size: 17px
      }
    }
  }
}

/** Overviews **/

#container.overviews section#content {
  .overview-text-container {
    text-align: center;
    position: fixed;
    top: 220px;

    &.start {
      top: unset;
      bottom: 150px;
    }

    width: calc(100vw - (2 * #{$width-sidebar}));
    @media #{$tablet} {
      width: calc(100vw - #{$width-sidebar});
    }
    @media #{$phablet}, #{$mobile} {
      position: relative;
      top: 0;
      width: 100vw;
      margin: 80px 0 0 0;
    }

    em {
      color: white;
      padding: 1px 8px;
      line-height: 156%;
      font-style: normal;
      @extend .font-overview-big;
      display: inline !important;

      span {
        font-size: 85%
      }

      &.none {
        visibility: hidden;
      }

      + .overview-button {
        margin-top: 60px;
      }
    }

    .overview-button {
      padding: 14px 26px;
      display: block;
      text-align: center;
      border: none;
      width: 200px;
      margin: 0 auto;
      @extend .font-button;
      @extend .button-radius;

      &:not(.internet) {
        @media #{$phablet}, #{$mobile} {
          display: none;
        }
      }

      &.disabled {
        @extend .disabled_state
      }
    }
  }
}

/** News **/

#news-widget {
  position: fixed;
  width: 800px;
  bottom: 0;
  left: 50%;
  margin-left: -400px;
  height: 58px;
  text-align: center;
  @media #{$tablet} {
    width: 500px;
    margin-left: -120px;
  }
  @media #{$phablet}, #{$mobile} {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0
  }

  div.news-container {
    @include shadow(1px, -1px, 13px, 0, rgba(0, 0, 0, 0.41));
    position: relative;
    display: inline-block;
    padding-left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 58px;
    line-height: 58px;

    .swiper-slide {
      @extend .font-news-container;

      .text {
        padding: 0 145px 0 58px;
        @extend .ellipsis;
      }
    }

    .button-hollow-small {
      opacity: 1;
      color: white;
      position: absolute;
      top: 14px;
      right: 58px;
      @media #{$phablet}, #{$mobile} {
        right: 50px;
      }
      bottom: 0;
      margin: 0 18px;
    }

    &.no-controls {
      .text {
        padding: 0 87px 0 14px;
      }

      a.button-hollow-small {
        right: 8px;
        margin: 0 6px;
      }

      .swiper-button-next, .swiper-button-prev {
        display: none;
      }
    }

    .swiper-button-next, .swiper-button-prev {
      width: 58px;
      padding-top: 9px;
      font-size: 2.4em !important;

      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

/** Element Navigation **/
nav.element-navigation {
  width: 100%;
  height: 35px;
  padding: 18px 0 10px;
  border-top: 1px solid $concrete-grey;
  text-align: center;
  margin: 44px auto 0;
  float: left;
  @media #{$mobile} {
    margin: 15px 0 auto;
  }

  .navigation-container {
    margin: auto;

    .nav-btn {
      width: 33%;
      float: left;
      text-align: center;

      &:first-child {
        text-align: left;

        a::before {
          content: '\00ab ';
          margin-right: 5px;
        }
      }

      &:last-child {
        text-align: right;

        a::after {
          content: '\00bb ';
          margin-left: 5px;
        }
      }

      a {
        @extend .font-button;
        @media #{$mobile} {
          font-size: 13px !important;
        }
        cursor: pointer;
        color: $tundora-grey !important;
        padding: 18px 0;

        &.disabled {
          @extend .disabled_state
        }
      }
    }

    &.back {
      .nav-btn {
        width: 100%;
        text-align: center;

        &:first-child {
          text-align: center;
        }

        &:last-child {
          a::after {
            content: ' ';
            margin-left: 0;
          }
        }
      }
    }
  }
}

.icon-menu {
  ul.icon-nav {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    margin: 0;

    li {
      display: table-cell;
      text-align: center;
      border: none;
      border-right: 1px solid;
      border-bottom: 1px solid;
      vertical-align: middle;
      line-height: 30px;
      height: 30px;

      &:last-of-type {
        border-right: none;
      }

      &:hover {
        background: #f4f4f4;
      }

      &.active {
        border-bottom: none;
        background: #f4f4f4;
      }
    }
  }

  div.menu {
    display: none;
    width: 100%;

    &.active {
      display: table;
    }

    a {
      div:first-of-type {
        text-align: left;
        @extend .font-widget-text;
      }

      &:first-child {
        border-top: none
      }

      &:last-child {
        border-bottom: none
      }
    }
  }
}

/** iFrame **/
.iframe_container {
  @extend .animation-in;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 49px);
  -webkit-overflow-scrolling: touch !important;
  overflow-y: scroll !important;
  @media #{$phablet}, #{$mobile} {
    height: calc(100vh - #{$header-height} - #{$footer-height});
  }

  object, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    min-width: 100%;
    *width: 100%;
    height: 100%;
    border: 0
  }
}

app-link.iOS {
  .iframe_container {
    @media #{$phablet} {
      height: calc(100vh - #{$header-height} - #{$footer-height} - #{$footer-height}) !important;
    }
  }
}

#container.frame {
  nav.element-navigation {
    margin: auto 0;
    @media #{$phablet}, #{$mobile} {
      display: none;
    }
  }
}

.signature {
  canvas {
    width: 100%;
    height: 300px;
    border-bottom: 2px solid $grey;
    position: relative;
    z-index: 10;
  }
}

.add-btn {
  cursor: pointer;
  display: inline-block;
  margin: 16px 0;

  div {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    text-align: center;
    float: left;
    @extend .button-radius;

    i {
      padding-top: 22px;
    }
  }

  span {
    display: inline-block;
    padding: 19px 16px;
  }
}

.remove-btn {
  display: inline-block;
  padding-top: 19px;
  float: right;
}

.select-frame {
  position: relative;
  margin: 14px 0 16px;

  i {
    position: absolute;
    line-height: 56px;
    font-size: 24px;
    color: $emperor-grey;
    right: 20px;
    @extend .no-events
  }
}

select {
  @extend .disable-styling-input;
  width: 100%;
  margin: 0;
}

textarea {
  height: 80px;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

/** Searchbox **/

div.searchbox {
  border-radius: 0;
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
  @include box-sizing;
  position: relative;

  .header {
    width: 46%;
    position: absolute;
    top: 52px;
    right: 0;
  }

  .search-icon {
    color: white;
    height: 100%;
    width: 82px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 17px;
  }

  input[type="search"] {
    width: 100%;
    min-width: 100%;
    @extend .disable-styling-input;
    height: 50px;
    padding-left: 94px;
    margin: 0 auto;
  }
}

/** Dots **/

.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  border: 1px solid white;
  margin-bottom: 2px;

  &.confirmed, &.created, &.pre_checked_in {
    background: $blue;
  }
  &.checked_in {
    background: $green;
  }
  &.checked_out, &.no_show, &.canceled {
    background: $red;
  }
}

/** Stars **/

.stars i {
  font-size: 50px;
  margin-top: 8px;
  padding-right: 3%;
  vertical-align: top;

  @media #{$mobile} {
    width: 50px;
  }
}

#capture {
  video, canvas {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .button {
    margin-top: 16px;
    display: inline-block;
  }
}

/*===================
* Popup
====================*/

section.popup-ui {
  @include fade_in();
  display: none;

  &.active {
    display: block;
  }

  z-index: 10000;
  width: 700px;
  max-width: 94%;
  height: auto;
  top: 0;
  margin: 50px auto 50px;
  position: fixed;
  max-height: calc(100vh - 100px);
  left: $width-sidebar;
  right: $width-sidebar;
  @media #{$tablet}, #{$phablet}, #{$mobile} {
    width: 560px;
    left: 0;
    right: 0;
  }

  @media #{$kiosk} {
    width: 700px;
    left: 0;
    right: 0;
  }

  div.button-grid {
    margin-top: 0 !important;

    a {
      margin-bottom: 0;
    }
  }

  div.page-headline {
    position: relative;
    margin-bottom: 10px;
    border-bottom: 1px solid $alto-grey;
    padding: 0 10px 10px 1px !important;

    h2 {
      @extend .font-article-name;
      color: $tundora-grey;
      line-height: 1.4em;
      margin: 7px 0;
      padding-right: 35px;
    }
  }

  app-news-item {
    .inner {
      max-height: calc(100vh - 280px);
    }
    #version-update {
      display: none;
    }
  }

  div.content {
    z-index: 9000;
    position: relative;
    padding: 22px;
    @media #{$mobile} {
      padding: 10px 18px 18px 18px;
    }
    background: white;
    @include shadow(2px, 2px, 25px, 2px, rgba(0, 0, 0, 0.45));
    box-sizing: border-box;
    max-height: calc(100vh - 100px) !important;

    > .inner, .popup {
      position: relative;
      overflow: auto;
      padding: 0 3px;
      min-height: 70px;
      max-height: calc(100vh - 232px);
      &.button-attached {
        max-height: calc(100vh - 282px);
      }
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        display: block;
        width: 3px;
      }
    }

    .popup-text {
      @extend .font-text;

      .half {
        width: calc(49% - 20px);
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        @media #{$mobile} {
          width: 100%;
          display: block;
          padding: 5px 0;
        }
      }
    }

    app-widget-social {
      display: block;
      margin: 22px 0;
    }

    h4 {
      margin-bottom: 10px;
    }

    p {
      @extend .font-text;
      margin-bottom: 15px;
    }

    a {
      @extend .font-text;
      color: $emperor-grey;
      font-weight: 600 !important;
      text-decoration: underline;
      cursor: pointer;
    }

    em {
      margin: 40px 0 30px 0;
      @extend .font-text-big;
      color: $boulder-grey;
      text-align: center;
      display: block;
    }

    img {
      &:not(.imgSlide):not(.demo):not([width]) {
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
      }
    }

    table {
      width: 100%;
      margin-top: 15px;

      td.text-center {
        text-align: center;
      }
    }

    hr {
      margin: 16px 0;
      border-color: $concrete-grey;
    }

    .contact {
      h4 {
        margin-top: 15px;
      }

      p {
        margin: 2px 0;
      }
    }

    app-attachments,
    app-widget-social {
      margin-top: 14px;
      display: block;
    }

    &.small {
      width: 500px !important;
      max-width: 94%;
    }
  }

  div.close-icon {
    position: absolute;
    top: 23px;
    right: 23px;
    color: #c9c9c9;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    z-index: 10000;
    background: $alabaster-grey;
    @media #{$mobile} {
      top: 10px;
    }
    @extend .button-radius;

    i {
      font-size: 22px;
      padding: 8px 5px;
    }
  }

  #social {
    margin-top: 15px;
  }

  #social a i {
    color: inherit
  }

  form {
    margin: auto;
  }
}

/** Advert **/
i.ad-icon {
  padding-right: 7px;
  font-style: normal;
  &::after {
    font-family: 'Font Awesome 6 Free';
    content: "\f641";
    font-weight: 900;
  }
}

/** IACBox **/
#iacbox {
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

/** Door Drag-Object **/

.door.cdk-drag.cdk-drag-preview {
  > div {
    background: $alabaster-grey;
    padding: 14px 0 14px 16px;
    &:nth-of-type(1) {
      display: none
    }
    &:nth-of-type(2) {
      small {
        display: block;
      }
    }
    &:nth-of-type(3) {
      display: none
    }
  }
}

/*==================
* Swipe Info
===================*/

html {
  &:not(.can_touchevents) {
    .swipe_info {
      display: none;
      left: -50000;
    }
  }

  &.can_touchevents {
    .swipe_info {
      display: none;
      text-align: center;
      position: absolute;
      margin: 0 auto;
      top: 300px;
      left: 50%;
      width: 300px;
      height: 145px;
      margin-left: -150px;
      background: rgba(255, 255, 255, 0.65);
      border-radius: 10px;
      z-index: 600;
      @media #{$tablet} {
        margin-left: 0;
      }

      div {
        width: 49%;
        display: inline-block;

        img {
          display: block;
          margin: 0 auto;
        }

        &:nth-child(1) {
          img {
            @include animation('swipe_left 1s 2');
          }
        }

        &:nth-child(2) {
          img {
            @include animation('swipe_right 1s 2');
          }
        }
      }
    }
  }
}

.swipe_fade{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 3s, opacity 3s linear;
}

/*==================
* Info box
===================*/

.info_box {
  border: 1px solid;
  margin: 0 auto 15px;
  padding: 8px 12px;
  font-weight: bold;
  border-radius: 10px;
  display: inline-block;
  word-break: break-word;

  &.info {
    background-color: $concrete-grey;
    border-color: $alto-grey;
  }

  &.normal {
    border-radius: 0;
  }

  i {
    float: left;
    font-size: 28px;
  }

  > p, > b {
    display: block;
    padding: 3px 0 0 40px;
    margin: 0 !important;
    min-height: 25px;
  }

  @media #{$tablet}, #{$desktop}, #{$tv} {
    i {
      padding-top: 3px;
    }
    > p, > b {
      padding: 0 0 0 40px;
    }
  }
}

/** No sidebar **/

body.no-sidebar {
  app-sidebar {
    display: none;
  }

  section#content {
    margin-right: 0 !important;

    .overview-text-container {
      width: calc(100vw - #{$width-sidebar}) !important;
      @media #{$phablet}, #{$mobile} {
        width: 100vw !important;
      }
    }
  }

  section#search {
    @media #{$tv}, #{$desktop} {
      margin-left: calc(-250px + (#{$width-sidebar} / 2));
    }
  }

  #news-widget {
    @media #{$tv}, #{$desktop} {
      margin-left: calc(-400px + (#{$width-sidebar} / 2));
    }
  }
}

@media screen and (max-width: 767px) {
  .install-prompt {
    top: 50px;
  }
}

/* Brand Icons Fix */
i.fa.fab {
  font-weight: 400 !important;
}

/* IE Fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html {
    overflow: hidden !important;
  }
  #container:not(.overviews) {
    section#navigation {
      overflow: auto;
      max-height: 100vh;
    }
  }
  div.searchbox {
    height: auto;
    width: calc(100% - 94px);

    input[type="search"] {
      width: calc(100% - 94px);
      min-width: calc(100% - 94px);
      height: auto;
    }
  }
}

/*==================
* QR-button
===================*/
.qr-button {
  display: inline-block;
  width: 210px;
  height: 40px;
  border-radius: 8px;
  border: solid 2px $mercury-grey;

  a {
    position: relative;
    top: 20%;
    font-size: 16px !important;
    font-weight: normal !important;
    line-height: 1.5;
    color: black !important;
    text-decoration: none !important;

    i {
      padding: 0 5px 0 5px;
    }
  }
}

.credentials {
  text-align: center;
}

/*==================
* App-form-legal
===================*/

app-form-legal {
  text-align: center;
}

/*==================
* Link-box
===================*/

.link-box {
  @include box-sizing;
  border-radius: 8px;
  border: solid 2px $mercury-grey;
  text-decoration: none !important;
  text-align: center;
  padding: 8px 16px;
  margin: 8px;

  i {
    color: #a9acaf !important;
  }

  &.help {
    position: fixed;
    left: 3%;
    bottom: 3%;
  }
}

.language-list-zindex{
  z-index: 4;
}

*{
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent; /* Firefox */
  -ms-tap-highlight-color: transparent; /* Microsoft Edge (legacy) */
  tap-highlight-color: transparent; /* Standard */
}

.instructions {
  @extend .normal-font-size;
  color: $text-color;
  font-style: normal;
  font-weight: 500;
  text-shadow: none;
  line-height: normal;
  padding-bottom: 20px;

  @media #{$all-tablet} {
    padding-bottom: 30px;
  }

  @media #{$desktop}, #{$kiosk} {
    padding: 5px 0 40px;
  }

  &.required-text {
    color: $dark-grey;

    &:before {
      content: '*';
      color: $error-color;
      display: inline-block;
      padding-right: 4px;
    }
  }

  &.not-first-instruction {
    padding: 30px 0 30px;

    @media #{$desktop} {
      padding: 60px 0 60px;
    }
  }
}

app-check-in a:not(.redesign),
.wizard section#content a:not(.redesign) {
  font-size: inherit !important;
}

body {
  background-color: #FBFBFB;
}

.step-container{
  background-color: white;
  @media #{$all-tablet}, #{$desktop}, #{$kiosk} {
    padding: 24px;
  }
}

.thin-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    display: block;
    width: 3px;
  }
}

.two-col-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media #{$all-tablet}, #{$desktop}, #{$kiosk} {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 30px;
  }
}
